import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import i18n from "../../i18n";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../redux/actions";
import Loader from "../../components/Loader";
import style from "../profile/style.module.scss";
import { Icon } from "@iconify/react";

function Settings() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { loading, user } = useSelector((state) => state.users);
  const [selectedLng, setSelectedLng] = useState(
    localStorage.getItem("i18nextLng") === "en" ||
      localStorage.getItem("i18nextLng") === "en-GB" ||
      localStorage.getItem("i18nextLng") === ""
      ? "English"
      : "Francais"
  );

  useEffect(() => {
    const lang = localStorage.getItem("language");
    if (lang) {
      setSelectedLng(lang === "en-GB" ? "English" : lang);
    }
  }, []);

  useEffect(() => {
    if (!user) dispatch(userAction.getLoginUser());
  }, [dispatch, user]);

  const changeNewsHandler = (e) => {
    const payload = {
      profile: {
        isNewsLetterEnabled: e.target.checked,
      },
    };
    dispatch(userAction.updateAccount(user?.objectId, payload));
  };

  const changeLanguage = (lang, shortLang) => {
    setSelectedLng(lang);
    localStorage.setItem("language", lang);
    i18n.changeLanguage(shortLang);
    setOpen(false);
  };

  return (
    <Loader loading={loading}>
      <div className="settings card">
        <div className="settingHeading">
          <p>{t("App")}</p>
        </div>
        <div className="AppBars">
          <div className="settingBar">
            <span>{t("Language")}</span>
            <div className="LangDropdown flexCenter">
              <p onClick={() => setOpen(true)}>
                {selectedLng}{" "}
                <Icon
                  fontSize={24}
                  icon="material-symbols:keyboard-arrow-down"
                />
              </p>
              <div
                style={{ display: open ? "block" : "none" }}
                data-style="bg-hide"
                onClick={() => setOpen(false)}
              />
              <ul style={{ display: open ? "block" : "none" }}>
                <li
                  onClick={() => changeLanguage("Francais", "fr")}
                  data-select={selectedLng === "Francais" ? "true" : "false"}
                >
                  Francais
                </li>
                <li
                  onClick={() => changeLanguage("English", "en")}
                  data-select={selectedLng !== "Francais" ? "true" : "false"}
                >
                  English
                </li>
              </ul>
            </div>
          </div>
          <div className="settingBar">
            <p>{t("Version")}</p>
            <p style={{ paddingRight: "10px" }}>v32.6.06</p>
          </div>
        </div>
        <div className="settingHeading">
          <p>{t("Legal")}</p>
        </div>
        <div className="AppBars">
          <div
            className="settingBar logout"
            onClick={() => window.open("https://sweesher.com/cgu")}
          >
            <p>{t("Terms of Service")}</p>
          </div>
          <div
            className="settingBar logout"
            onClick={() => window.open("https://sweesher.com/cgu")}
          >
            <p>{t("Privacy Policy")}</p>
          </div>
        </div>

        <div className="settingHeading"></div>
        <div className="AppBars">
          <div
            onClick={() => {
              document.getElementById("newsletter-switcher").click();
            }}
            className="settingBar logout"
          >
            <p>Newsletter</p>
            <div className={style.switch} style={{ pointerEvents: "none" }}>
              <input
                id="newsletter-switcher"
                style={{ pointerEvents: "none" }}
                checked={user?.profile?.isNewsLetterEnabled}
                onChange={changeNewsHandler}
                type="checkbox"
              />
              <span></span>
            </div>
          </div>
          <div
            onClick={() => {
              localStorage.removeItem("SWEESHER-SESSION");
              localStorage.removeItem("SWEESHER-USER");
              localStorage.removeItem("SWEESHER-SESSION-TIME");
              localStorage.removeItem("persist:root");
              window.location.reload();
            }}
            className="settingBar logout"
          >
            <p>{t("Logout")}</p>
          </div>
        </div>
        <div className="settingHeading"></div>
      </div>
    </Loader>
  );
}

export default Settings;
