import { Modal } from "@mui/material";
import style from "./style.module.scss";

// icons and svg
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import {
  Adress,
  LastStepPno,
  MobileAdressPno,
  PropertyTypePno,
} from "./Components";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../redux/actions";
import Loader from "../../../components/Loader";
import { useMediaQuery } from "react-responsive";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { notifyWarn } from "../../../components/Toast";

const validationSchema = z.object({
  // logement
  propertyType: z.enum(["House", "Apartment"]).default("Apartment"),
  // --------------

  // details
  ownerAddress: z.string().min(1),
  ownerPostcode: z.string().min(1),
  ownerCity: z.string().min(1),
  ownerCountry: z.string().min(1).default("France"),
  room: z.string().min(1),
  size: z.string().min(1),
  floor: z.enum(["top", "middle", "ground"]),
  // ------
});

function AddPno({ open, handleClose }) {
  const {
    reset,
    setValue,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    reset({
      propertyType: "Apartment",
      ownerCountry: "France",
    });
  }, [reset]);

  const { pnoDataLoading } = useSelector((state) => state.home);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const isMobile = useMediaQuery({ query: "(max-width: 790px)" });
  const goNext = () => {
    setStep((prev) => prev + 1);
  };
  const goBack = () => {
    setStep((prev) => prev - 1);
  };

  const propertyTypeHandler = (value) => {
    setValue("propertyType", value);
  };

  useEffect(() => {
    if (pnoDataLoading && open) {
      setLoading(false);
      handleClose();
    }
  }, [pnoDataLoading, handleClose, open]);

  useEffect(() => {
    const length = Object.keys(errors).length;
    if (length) notifyWarn("veillez remplir tout le formulaire svp !");
  }, [errors]);

  const onSubmitHandler = async (value) => {
    const data = {
      ...value,
      active: true,
      floor: value.propertyType !== "House" ? value.floor : null,
    };
    dispatch(userAction.updatePnoUser(data));
    setLoading(true);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={style.index}>
        <Loader loading={loading}>
          {isMobile ? (
            <MobileForm
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              onSubmitHandler={onSubmitHandler}
              propertyTypeHandler={propertyTypeHandler}
              register={register}
              setValue={setValue}
            />
          ) : (
            <>
              <Icon
                onClick={handleClose}
                data-style="exit"
                icon="basil:cross-outline"
              />
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <PropertyTypePno
                  goBack={goBack}
                  goNext={goNext}
                  step={step}
                  page={1}
                  register={register}
                  propertyTypeHandler={propertyTypeHandler}
                />
                <Adress
                  goBack={goBack}
                  goNext={goNext}
                  step={step}
                  page={2}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                />
                <LastStepPno
                  goBack={goBack}
                  setStep={setStep}
                  register={register}
                  watch={watch}
                  step={step}
                  page={3}
                />
              </form>
            </>
          )}
        </Loader>
      </div>
    </Modal>
  );
}

const MobileForm = ({
  handleSubmit,
  onSubmitHandler,
  handleClose,
  propertyTypeHandler,
  register,
  setValue,
}) => {
  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className={style.mobile_form}
      style={{ margin: 0 }}
    >
      <h3>
        Vous êtes :
        <Icon fontSize={26} onClick={handleClose} icon="basil:cross-outline" />
      </h3>

      <div data-style="2/2">
        <div
          onClick={() => propertyTypeHandler("House")}
          role="button"
          data-style="checkbox"
        >
          <label>Maison</label>
          <input type="radio" value="House" {...register("propertyType")} />
          <div></div>
        </div>

        <div
          onClick={() => propertyTypeHandler("Apartment")}
          role="button"
          data-style="checkbox"
        >
          <label>Appartement</label>
          <input type="radio" value="Apartment" {...register("propertyType")} />
          <div></div>
        </div>
      </div>

      <MobileAdressPno register={register} setValue={setValue} />
      <div data-style="form">
        <div data-style="1-1/4">
          <input
            type="number"
            placeholder="Nombre de pièces"
            {...register("room")}
          />
          <input type="number" placeholder="m²" {...register("size")} />
        </div>

        <select {...register("floor")}>
          <option value="top">Dernière étage</option>
          <option value="middle">Étage intermédiaire</option>
          <option value="ground">Rez de chaussée</option>
        </select>
      </div>
      <button type="submit">Valider</button>
    </form>
  );
};

export default AddPno;
