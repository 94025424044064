import houseIcon from "../../../assets/images/svg/profile/house-logo 1.svg";
import carIcon from "../../../assets/images/svg/profile/car insurance 1.svg";
import petIcon from "../../../assets/images/svg/profile/pet insurance 1.svg";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { notifyWarn } from "../../../components/Toast";
import { useDispatch } from "react-redux";
import { homeAction } from "../../../redux/actions";
import style from "./style.module.scss";
import { Spin } from "antd";
import { Tab } from "@headlessui/react";
import AnimalSimulatorForm from './animalSimulatorForm';

const validationSchema = z.object({
  address: z.string().min(1, `l'adresse est obligatoire !`),
  postalCode: z.string().min(1, `toutes les informations sont obligatoires !`),
  city: z.string().min(1, `toutes les informations sont obligatoires !`),
  country: z.string().min(1, `toutes les informations sont obligatoires !`),
  room: z.string().min(1, "le nombre de piece est obligatoire"),
  size: z.string().min(1, "toutes les informations sont obligatoire !"),
  floor: z.enum(["top", "middle", "ground", ""], {
    description: "this error is for floor",
  }),
  capitalFurniture: z
    .string()
    .min(1, "toutes les informations sont obligatoires !"),
  occupantType: z.enum(["tenant", "owner"], {
    description: "this error is for occupent type",
  }),
  propertyType: z.enum(["House", "Apartment"], {
    description: "this error is for proprety type",
  }),
});

export default function Form({ loading }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };

  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (properties) => {
    setValue("address", properties.label);
    setValue("postalCode", properties.postcode);
    setValue("city", properties.city);
    setValue("country", "France");
    setOptions([]);
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      console.log(errors);
      notifyWarn(errors[Object.keys(errors)[0]].message);
    }
  }, [errors]);

  useEffect(() => {
    reset({
      occupantType: "tenant",
      propertyType: "Apartment",
    });
  }, [reset]);

  const propertyTypeHandler = (value) => {
    setValue("propertyType", value);
  };

  const occupantTypeHandler = (value) => {
    setValue("occupantType", value);
  };

  const get_offers = (data) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone_number: data.phoneNumber,
      address: data.address,
      postCode: data.postalCode,
      city: data.city,
      country: data.country,
      propertyType: data.propertyType,
      size: data.size,
      room: data.room,
      floor: data.floor,
      mainHome: true,
      alreadyInsured: data.alreadyInsured,
      capitalFurniture: data.capitalFurniture,
      occupantType: data.occupantType,
      dependenciesSize: null,
      hasVeranda: false,
      hasSwimmingPool: false,
      hasTennisCourt: false,
      alreadyInhabited: false,
      hasChimney: false,
      hasFireAlarm: false,
      hasTheftAlarm: false,
      hasSolarPanels: false,
    };
    localStorage.setItem("SET-MRH-PAYLOAD", JSON.stringify(payload));

    dispatch(homeAction.getLukoMRH(payload));
  };

  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    {
      insurancePanel: (
        <>
          <form onSubmit={handleSubmit(get_offers)}>
            <Spin size="large" className={style.spiner} spinning={loading} />
            <div aria-label="check-boxes">
              <p className={style.simulation_title}>Votre logement</p>
              <div aria-label="grid-2" style={{ marginBottom: "15px" }}>
                <div
                  onClick={() => propertyTypeHandler("Apartment")}
                  role="button"
                  data-style="checkbox"
                >
                  <label
                    role="button"
                    data-selected={watch("propertyType") === "Apartment" ? "true" : ""}
                  >
                    Appartement
                  </label>
                  <input
                    {...register("propertyType")}
                    type="radio"
                    value="Apartment"
                  />
                </div>

                <div
                  onClick={() => propertyTypeHandler("House")}
                  role="button"
                  data-style="checkbox"
                >
                  <label
                    role="button"
                    data-selected={watch("propertyType") === "House" ? "true" : ""}
                  >
                    Maison
                  </label>
                  <input {...register("propertyType")} type="radio" value="House" />
                </div>
              </div>

              <p className={style.simulation_title}>Vous êtes</p>
              <div aria-label="grid-2">
                <div
                  onClick={() => occupantTypeHandler("tenant")}
                  role="button"
                  data-style="checkbox"
                >
                  <label
                    role="button"
                    data-selected={watch("occupantType") === "tenant" ? "true" : ""}
                  >
                    Locataire
                  </label>
                  <input
                    {...register("occupantType")}
                    type="radio"
                    value="tenant"
                  />
                </div>

                <div
                  onClick={() => occupantTypeHandler("owner")}
                  role="button"
                  data-style="checkbox"
                >
                  <label
                    role="button"
                    data-selected={watch("occupantType") === "owner" ? "true" : ""}
                  >
                    Propriétaire
                  </label>
                  <input {...register("occupantType")} type="radio" value="owner" />
                </div>
              </div>
            </div>

            <div data-style="single-input">
              {/* <Icon icon="carbon:location" /> */}
              <input
                {...register("address", {
                  onChange: (e) => adressHandler(e),
                })}
                type="text"
                autoComplete="off"
                placeholder="Votre adresse :"
              />
              {options.length ? (
                <ul data-style="list">
                  {options.map(({ properties }) => (
                    <li
                      key={properties.id}
                      onClick={() => onClickHandler(properties)}
                    >
                      {properties.label}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
            </div>

            <div aria-label="3-2-5box">
              <input {...register("room")} type="number" placeholder="Pièce" />
              <input
                {...register("size")}
                step={0.01}
                type="number"
                placeholder="m²"
              />
            </div>

            <div aria-label="2box">
              {watch("propertyType") !== "House" && (
                <select {...register("floor")}>
                  <option value="top">Dernière étage</option>
                  <option value="middle">Étage intermédiaire</option>
                  <option value="ground">Rez de chaussée</option>
                </select>
              )}

              <input
                {...register("capitalFurniture")}
                type="number"
                placeholder="Capital mobilier"
              />
            </div>
            <button className={style.form_button} type="submit">Voir les offres</button>
          </form>
        </>
      ),
      navTab : (
        <>
          <div>
            <img src={houseIcon} alt="house" />
          </div>
          <p>Assurance habitation</p>
        </>
      )
    },
    {
      insurancePanel: (
        <></>
      ),
      navTab : (
        <>
          <div data-disable="true">
            <img src={carIcon} alt="car" />
          </div>
          <p>Assurance auto</p>
        </>
      )
    },
    {
      insurancePanel: (
        <AnimalSimulatorForm loading={loading} />
      ),
      navTab : (
        <>
          <div >
            <img src={petIcon} alt="pet" />
          </div>
          <p>Assurance animaux</p>
        </>
      )
    },
    
  ];
  return (
    <>
      <div
        data-style="background"
        style={{ display: options.length ? "block" : "none" }}
        onClick={() => {
          setValue("address", "");
          setOptions([]);
        }}
      ></div>
      <Tab.Group
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <Tab.List>
            <ul aria-label="icons-content" >
              {tabs.map((tab, index) => (
                <Tab
                  as="li"
                  aria-label={selectedIndex === index ? "selected" : ((index===2 || !index) ? 'activated' : '')}
                  disabled={index===1}
                  key={index}
                >
                  {tab.navTab}
                </Tab>
              ))}
            </ul>
          </Tab.List>
          <Tab.Panels>
              {tabs.map((tab, idx) => (
                <Tab.Panel as="div" className={style.content} key={idx}>{tab.insurancePanel}</Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
    </>
  );
}

const SideBar = () => {
  return (
    <ul aria-label="icons-content">
      <li aria-label="selected">
        <div>
          <img src={houseIcon} alt="icon" />
        </div>
        <p>Assurance habitation</p>
      </li>
      <li>
        <div>
          <img src={petIcon} alt="icon" />
        </div>
        <p>Assurance animaux</p>
      </li>
      <li>
        <div>
          <img src={carIcon} alt="icon" />
        </div>
        <p>Assurance auto</p>
      </li>

    </ul>
  );
};
