import keys from "../../../assets/images/svg/keys.svg";
import documents from "../../../assets/images/svg/document.svg";
import left from "../../../assets/images/svg/left_arrow.svg";
import right from "../../../assets/images/svg/right_arrow.svg";
import { useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { notifyWarn } from "../../../components/Toast";

export const Adress = ({
  goBack,
  goNext,
  step,
  page,
  register,
  setValue,
  watch,
}) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };

  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (properties) => {
    setValue("ownerAddress", properties.label);
    setValue("ownerPostcode", properties.postcode);
    setValue("ownerCity", properties.city);
    setOptions([]);
  };

  if (page !== step) return <></>;

  return (
    <>
      <div
        data-style="background"
        style={{ display: options.length ? "block" : "none" }}
        onClick={() => {
          setValue("ownerAddress", "");
          setOptions([]);
        }}
      ></div>
      <h3>Votre adresse :</h3>
      <div data-style="single-input">
        <Icon icon="carbon:location" />
        <input
          name="adress"
          {...register("ownerAddress", {
            onChange: (e) => adressHandler(e),
          })}
          type="text"
          autoComplete="off"
        />
        {options.length ? (
          <ul data-style="list">
            {options.map(({ properties }) => (
              <li
                key={properties.id}
                onClick={() => onClickHandler(properties)}
              >
                {properties.label}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
      <div data-style="form" style={{ display: "none" }}>
        <div data-style="groupe-form">
          <label>Code postal</label>
          <input
            style={{ pointerEvents: "none" }}
            {...register("ownerPostcode")}
            type="text"
          />
        </div>

        <div data-style="groupe-form">
          <label>Cité</label>
          <input
            style={{ pointerEvents: "none" }}
            {...register("ownerCity")}
            type="text"
          />
        </div>

        <div data-style="groupe-form">
          <label>Pays</label>
          <input {...register("ownerCountry")} type="text" />
        </div>
      </div>
      <div data-style="footer">
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          type="button"
          onClick={() => {
            if (!watch("ownerAddress").length)
              return notifyWarn("L'adresse est obligatoire !");
            goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </>
  );
};

export const AdressMrh = ({
  goBack,
  goNext,
  step,
  page,
  register,
  setValue,
  watch,
}) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };

  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (properties) => {
    setValue("address", properties.label);
    setValue("postCode", properties.postcode);
    setValue("city", properties.city);
    setOptions([]);
  };

  if (page !== step) return <></>;

  return (
    <>
      <div
        data-style="background"
        style={{ display: options.length ? "block" : "none" }}
        onClick={() => {
          setValue("address", "");
          setOptions([]);
        }}
      ></div>
      <h3>Votre adresse :</h3>
      <div data-style="single-input">
        <Icon icon="carbon:location" />
        <input
          name="adress"
          {...register("address", {
            onChange: (e) => adressHandler(e),
          })}
          type="text"
          autoComplete="off"
        />
        {options.length ? (
          <ul data-style="list">
            {options.map(({ properties }) => (
              <li
                key={properties.id}
                onClick={() => onClickHandler(properties)}
              >
                {properties.label}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
      <div data-style="form" style={{ display: "none" }}>
        <div data-style="groupe-form">
          <label>Code postal</label>
          <input
            style={{ pointerEvents: "none" }}
            {...register("postCode")}
            type="text"
          />
        </div>

        <div data-style="groupe-form">
          <label>Cité</label>
          <input
            style={{ pointerEvents: "none" }}
            {...register("city")}
            type="text"
          />
        </div>

        <div data-style="groupe-form">
          <label>Pays</label>
          <input {...register("country")} type="text" />
        </div>
      </div>
      <div data-style="footer">
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          type="button"
          onClick={() => {
            if (!watch("address").length)
              return notifyWarn("L'adresse est obligatoire !");
            goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </>
  );
};

export const PropertyType = ({
  goNext,
  goBack,
  step,
  page,
  propertyTypeHandler,
  register,
}) => {
  if (step !== page) return <></>;
  return (
    <>
      <h3>Type de logement :</h3>

      <div data-style="choice">
        <input type="radio" {...register("propertyType")} value="House" />
        <div
          role="button"
          onClick={() => propertyTypeHandler("House")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="clarity:house-solid" />
          <span>Maison</span>
        </div>
        <span>Ou</span>
        <input type="radio" {...register("propertyType")} value="Apartment" />
        <div
          role="button"
          onClick={() => propertyTypeHandler("Apartment")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="prime:building" />
          <span>Appartement</span>
        </div>
      </div>

      <div data-style="footer">
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button type="button" onClick={goNext}>
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </>
  );
};

export const PropertyTypePno = ({
  goNext,
  step,
  page,
  register,
  propertyTypeHandler,
}) => {
  if (page !== step) return <></>;

  return (
    <>
      <h3>Type de logement :</h3>

      <div data-style="choice">
        <input type="radio" value="House" {...register("propertyType")} />
        <div
          role="button"
          onClick={() => propertyTypeHandler("House")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="clarity:house-solid" />
          <span>Maison</span>
        </div>
        <span>Ou</span>
        <input type="radio" value="Apartment" {...register("propertyType")} />
        <div
          role="button"
          onClick={() => propertyTypeHandler("Apartment")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="prime:building" />
          <span>Appartement</span>
        </div>
      </div>

      <div data-style="footer">
        <i />
        <button type="button" onClick={goNext}>
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </>
  );
};

export const OccupantType = ({
  goNext,
  step,
  page,
  register,
  occupantTypeHandler,
}) => {
  if (step !== page) return <></>;
  return (
    <>
      <h3>Vous êtes :</h3>
      <div data-style="choice">
        <input type="radio" value="owner" {...register("occupantType")} />
        <div
          role="button"
          onClick={() => occupantTypeHandler("owner")}
          data-style="choice-content"
        >
          <img src={keys} alt="keys-svg" />
          <span>Propriétaire</span>
        </div>
        <span>Ou</span>
        <input type="radio" value="tenant" {...register("occupantType")} />
        <div
          role="button"
          onClick={() => occupantTypeHandler("tenant")}
          data-style="choice-content"
        >
          <img src={documents} alt="docs-svg" />
          <span>Locataire</span>
        </div>
      </div>

      <div data-style="footer">
        <i></i>
        <button type="button" onClick={goNext}>
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </>
  );
};

export const LastStep = ({ goBack, step, page, register, watch }) => {
  if (step !== page) return <></>;
  return (
    <>
      <div data-style="form">
        <div data-style="groupe-form">
          <label>nombre de piéce</label>
          <input
            {...register("room", {
              required: true,
            })}
            type="number"
          />
        </div>

        <div data-style="groupe-form">
          <label style={{ textTransform: "lowercase" }}>m²</label>
          <input
            {...register("size", {
              required: true,
            })}
            type="number"
          />
        </div>

        <div data-style="groupe-form">
          <label>capital mobilier</label>
          <input
            {...register("capitalFurniture", {
              required: true,
            })}
            type="number"
          />
        </div>

        <div
          data-style="groupe-form"
          style={{
            display: watch("propertyType") === "Apartment" ? "block" : "none",
          }}
        >
          <label>Étage</label>
          <select {...register("floor")}>
            <option value="top">Dernière étage</option>
            <option value="middle">Étage intermédiaire</option>
            <option value="ground">Rez de chaussée</option>
          </select>
        </div>
      </div>
      <div data-style="footer">
        <button onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          onClick={() => {
            if (
              !watch("room").length ||
              !watch("size").length ||
              !watch("capitalFurniture").length
            )
              return notifyWarn("tout les champs sont obligatoires");
          }}
          type="submit"
        >
          Voir les offres
        </button>
      </div>
    </>
  );
};

export const LastStepPno = ({ goBack, page, step, register, watch }) => {
  if (step !== page) return <></>;

  return (
    <>
      <div data-style="form">
        <div data-style="groupe-form">
          <label>nombre de piéce</label>
          <input
            {...register("room", {
              required: true,
            })}
            type="number"
          />
        </div>

        <div data-style="groupe-form">
          <label style={{ textTransform: "lowercase" }}>m²</label>
          <input
            {...register("size", {
              required: true,
            })}
            type="number"
          />
        </div>

        <div
          data-style="groupe-form"
          style={{
            display: watch("propertyType") === "Apartment" ? "block" : "none",
          }}
        >
          <label>etage</label>
          <select {...register("floor")}>
            <option value="top">Dernière étage</option>
            <option value="middle">Étage intermédiaire</option>
            <option value="ground">Rez de chaussée</option>
          </select>
        </div>
      </div>
      <div data-style="footer">
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button type="submit">Voir les offres</button>
      </div>
    </>
  );
};

// MOBILE COMPONENTS
export const MobileAdressPno = ({ setValue, register }) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };
  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (val) => {
    const { label, postcode, city } = val;
    setValue("ownerAddress", label);
    setValue("ownerPostcode", postcode);
    setValue("ownerCity", city);
    setOptions([]);
  };

  return (
    <>
      <div
        data-style="background"
        style={{ display: options.length ? "block" : "none" }}
        onClick={() => {
          setValue("ownerAddress", "");
          setOptions([]);
        }}
      ></div>
      <div data-style="form">
        <div data-style="list-input">
          <Icon icon="carbon:location" />
          <input
            {...register("ownerAddress", {
              onChange: (e) => adressHandler(e),
            })}
            autoComplete="off"
            type="text"
            placeholder="Votre adresse"
          />
          {options?.length ? (
            <ul>
              {options.map((opt) => (
                <li
                  onClick={() => onClickHandler(opt.properties)}
                  key={`opt-${opt.properties.id}`}
                >
                  {opt.properties.label}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>

        <div data-style="flex" style={{ display: "none" }}>
          <input
            style={{ pointerEvents: "none" }}
            type="text"
            placeholder="Ville"
            {...register("ownerCity")}
          />
          <input
            style={{ pointerEvents: "none" }}
            type="text"
            placeholder="Code postal"
            {...register("ownerPostcode")}
          />
          <input type="text" placeholder="Pays" {...register("ownerCountry")} />
        </div>
      </div>
    </>
  );
};

export const MobileAdressMrh = ({ setValue, register }) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };
  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (val) => {
    const { label, postcode, city } = val;
    setValue("address", label);
    setValue("postCode", postcode);
    setValue("city", city);
    setOptions([]);
  };

  return (
    <div data-style="form">
      <div
        data-style="background"
        style={{ display: options.length ? "block" : "none" }}
        onClick={() => {
          setValue("ownerAddress", "");
          setOptions([]);
        }}
      ></div>
      <div data-style="list-input">
        <Icon icon="carbon:location" />
        <input
          {...register("address", {
            onChange: (e) => adressHandler(e),
          })}
          autoComplete="off"
          type="text"
          placeholder="Votre adresse"
        />
        {options?.length ? (
          <ul>
            {options.map((opt) => (
              <li
                onClick={() => onClickHandler(opt.properties)}
                key={`opt-${opt.properties.id}`}
              >
                {opt.properties.label}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>

      <div data-style="flex" style={{ display: "none" }}>
        <input
          style={{ pointerEvents: "none" }}
          type="text"
          placeholder="Ville"
          {...register("postCode")}
        />
        <input
          style={{ pointerEvents: "none" }}
          type="text"
          placeholder="Code postal"
          {...register("city")}
        />
        <input type="text" placeholder="Pays" {...register("country")} />
      </div>
    </div>
  );
};
