import style from "./style.module.scss";
import backIcon from "../../../assets/images/svg/backArraw.svg";
import connectIcon from "../../../assets/images/svg/connectArraw.svg";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, userAction } from "../../../redux/actions";
import { BASE_URL } from "../../../constant";
import { Icon } from "@iconify/react";
import nextButton from "../../../assets/images/svg/nextButton.svg";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { notifyWarn } from "../../../components/Toast";
import { useEffect } from "react";
import { Spin } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Tooltip } from '@mui/material';
import WarningIcon from "../../../components/Icons/Warning";
import { useScreenSize } from "../../../hooks/use-screen";
import { ClickAwayListener } from '@mui/material';



export default function ResultConnection({ providers, ref }) {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [slide, setSlide] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const loginData = useSelector((state) => state.users.loginData);
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const isTablette = useMediaQuery({ query: `(max-width: 750px)` });
  const [[mins, secs], setCountdown] = useState([2, 0]);
  const [otp, setOtp] = useState("");
  const [page, setPage] = useState(0);
  const resetHandler = () => {
    dispatch(homeAction.resetAll());
    if (ref) ref.current.style.width = "685px";
  };
  const handleChange = (otp) => setOtp(otp);
  const geNextHandler = () => {
    setPage(1);
    if (isMobile)
      document.getElementById("simulation-container").style.width = "340px";
    else document.getElementById("simulation-container").style.width = "500px";
  };

  const backPage = () => {
    if (slide) return setSlide(false);
    if (isMobile)
      document.getElementById("simulation-container").style.width = "350px";
    else if (isTablette)
      document.getElementById("simulation-container").style.width = `550px`;
    else document.getElementById("simulation-container").style.width = `654px`;
    setPage(0);
  };

  const onResend = () => {
    if (!loginData.email) return notifyWarn("kindly go back and input email!");
    setCountdown([2, 0]);
    dispatch(userAction.sendOtpMail(loginData.email));
  };

  const goToLogin = () => {
    router("/login");
  };

  useEffect(() => {
    if (slide) {
      let timerInterval = setInterval(() => {
        if (mins === 0 && secs === 0) {
          clearInterval(timerInterval);
        } else if (secs === 0) {
          setCountdown([mins - 1, 60]);
        } else {
          setCountdown([mins, secs - 1]);
        }
      }, 1000);
      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [mins, secs, slide]);

  useEffect(() => {
    if (loginData.email) {
      setEmail(loginData.email);
    }
  }, [loginData.email]);

  const onSubmt = () => {
    setisLoading(true);
    const onCallAction = {
      onSuccess: () => {
        setisLoading(false);
        setSlide(true);
      },
      onError: () => setisLoading(false),
    };
    dispatch(userAction.sendOtpMail(email, onCallAction));
  };

  const FinalStep = () => {
    if (!loginData.optEmailId) return;
    dispatch(userAction.verifyOTP(loginData.optEmailId, otp));
    setisLoading(true);
  };

  if (page === 1)
    return (
      <div className={style.connection}>
        <div role="button" onClick={backPage} aria-label="go-back">
          <img src={backIcon} alt="back-icon" />
          Retour
        </div>
        <Spin size="large" className={style.spiner} spinning={isLoading} />
        <h2>Pour poursuivre....</h2>
        <div
          className={style.slider_connection}
          style={{
            transform: slide
              ? `translateX(-${isMobile ? "31" : "32"}%)`
              : `translateX(${isMobile ? "31" : "27"}%)`,
          }}
        >
          <div aria-label="connection-container">
            <h3>Inscrivez-vous</h3>
            <div aria-label="connection">
              <input
                aria-label="simple-input"
                type="text"
                placeholder="Votre e-mail"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <img
                onClick={onSubmt}
                role="button"
                src={nextButton}
                alt="next-icon"
              />
            </div>
            <small>
              Nous enverrons un code de confirmation à cet e-mail pour <br />{" "}
              vérifier votre identité.
            </small>
          </div>

          <div aria-label="connection-container">
            <h3 aria-label="seconde-section">
              Nous avons envoyé votre code de confirmation à votre adresse
              e-mail
            </h3>
            <div aria-label="connection">
              <OtpInput
                value={otp}
                onChange={handleChange}
                isInputNum
                numInputs={6}
                className={style.input_number}
                inputStyle={style.input_style}
                style={{ justifyContent: "spaceBeteen" }}
              />
              <img
                onClick={FinalStep}
                role="button"
                src={nextButton}
                alt="next-icon"
              />
            </div>
            <small>
              Entrez ce code avant qu'il n'expire dans{" "}
              <span>
                {mins} min : {secs < 10 ? `0${secs}` : secs}s
              </span>
              <br />
              Vous n'avez pas reçu de code ?{" "}
              <span
                onClick={onResend}
                style={{ color: "#107eb7", cursor: "pointer" }}
              >
                Renvoyer
              </span>
            </small>
          </div>
        </div>
        <span style={{ visibility: email ? "hidden" : "unset" }}>OU</span>
        <p style={{ visibility: email ? "hidden" : "unset" }}>
          Vous avez deja un compte ?
        </p>
        <button
          style={{ visibility: email ? "hidden" : "unset" }}
          onClick={goToLogin}
        >
          Je me connecte
        </button>
      </div>
    );
  else
    return (
      <div className={style.offers}>
        <button onClick={resetHandler} aria-label="go-back">
          <img src={backIcon} alt="back-icon" />
          Retour
        </button>
        <h2>Mes offres personnalisées</h2>
        <div className={style.cards}>
          {(providers || []).map((doc, i) => {
            return (
              <CardItem
                cheapest={doc?.cheapest ?? doc?.price}
                iconPath={doc?.iconPath ?? doc?.iconUrl}
                colorCode={doc.colorCode || "#00D3C8" }
                doc={doc}
                callback={geNextHandler}
                key={`principalResidence-${doc.name}-${i}`}
              />
            );
          })}

          <button onClick={geNextHandler} aria-label="connect">
            <span>Voir plus d’offres</span>
            <img src={connectIcon} alt="connect-icon" />
          </button>
        </div>
      </div>
    );
}

const CardItem = ({ colorCode, iconPath, cheapest, callback }) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClick = () => {
    setOpen(!open);
  };
  const isMobile = useScreenSize()
  return (
    <div
      className={`${style.card} warning`}
      style={{
        backgroundColor: colorCode,
        maxWidth: "248px",
        maxHeight: "162px",
      }}
    >
      <div className={style.wrapperFlex}>
        <div data-style="badge-img">
          <img src={`${BASE_URL}${iconPath}`.replace("/api/", "/")} alt="img" />
        </div>
        {
          iconPath?.includes("mutuelle") && (
            <>
              {isMobile ? (
                <ClickAwayListener onClickAway={handleTooltipClick}>
                  <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClick}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                    title="Suite aux récentes catastrophes naturelles, vérifiez si votre commune est toujours assurée en parcourant l'intégralité du trajet" placement="top">
                    <div className="flex sm:hidden" onClick={handleTooltipClick}>
                      <WarningIcon />
                    </div>
                  </Tooltip>
                </ClickAwayListener>

              ) :
                (
                  <Tooltip
                    title="Suite aux récentes catastrophes naturelles, vérifiez si votre commune est toujours assurée en parcourant l'intégralité du trajet" placement="top-start">
                    <div className="flex sm:hidden">
                      <WarningIcon />
                    </div>
                  </Tooltip>
                )
              }
            </>
          )
        }
      </div>
      <p data-style="title">A partir de :</p>
      <p data-style="price" style={{ fontSize: "26px" }}>
        {cheapest}€ / mois
      </p>
      <button onClick={callback}>
        Voir les offres{" "}
        <Icon icon="material-symbols:keyboard-double-arrow-right-rounded" />
      </button>
    </div>
  );
};
