import { fakeMRH, fakePNO } from "../../constant/FakeData";
import {
  POST_ANALYTICS_DATA_REQUEST,
  POST_ANALYTICS_DATA_SUCCESS,
  POST_ANALYTICS_DATA_FAILURE,
  LOAD_LOADING,
  GET_NEWS_FEEDS,
  SET_PREFERENCE,
  SET_SELECTED_OFFER,
  LOAD_ERROR,
  GET_OFFERS,
  LOAD_OFFERS_LOADING,
  GET_PET_HOME_OFFERS,
  LOAD_PET_HOME_OFFERS_LOADING,
  GET_OFFERS_LUKO_MRH,
  GET_MRH,
  LOAD_MRH_OFFERS,
  LOAD_PNO_OFFERS,
  LOAD_PET_OFFERS,
  GET_PET_OFFERS,
  GET_PNO,
  SET_TAB,
  FINISH_LOAD,
  RESET_ALL,
  TARGET_UPDATE,
  GET_SIMULATOR_PET_OFFERS,
  LOAD_SIMULATOR_OFFERS_LOADING 
} from './home.actions';

const initialState = {
  tab: null,
  newsFeedsList: [],
  offers: [],
  offersCopy: [],
  preference: false,
  loading: false,
  selectedOffer: 'All',
  offersLoading: false,
  petOffersLoading: false,
  mrhDataLoading: false,
  mrhData: fakeMRH,
  pnoDataLoading: false,
  pnoData: fakePNO,
  petsDataLoading: false,
  petsOffersData: [],
  lukoMRH: [],
  lukoMRHCopy: [],
  petHomeOffers: [],
  error: '',
  finish_load: false,
  target_residence: 'mrh',
};

export default function reduxThunkReducer(state = initialState, action) {
  switch (action.type) {
    case FINISH_LOAD: {
      return {
        ...state,
        finish_load: action.value,
        pnoData: !action.value ? fakePNO : state.pnoData,
        mrhData: !action.value ? fakeMRH : state.mrhData,
      };
    }
    case TARGET_UPDATE: {
      return {
        ...state,
        target_residence: action.data,
      };
    }
    case RESET_ALL: {
      return {
        tab: null,
        newsFeedsList: [],
        offers: [],
        offersCopy: [],
        preference: false,
        loading: false,
        selectedOffer: 'All',
        offersLoading: false,
        mrhDataLoading: false,
        mrhData: fakeMRH,
        pnoDataLoading: false,
        pnoData: fakePNO,
        lukoMRH: [],
        lukoMRHCopy: [],
        petsOffersData: [],
        petDataLoading : false,
        error: '',
        finish_load: false,
      };
    }
    case LOAD_LOADING: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case SET_TAB: {
      return {
        ...state,
        tab: action.tab,
      };
    }
    case LOAD_OFFERS_LOADING: {
      return {
        ...state,
        offersLoading: true,
        error: '',
      };
    }
    case LOAD_SIMULATOR_OFFERS_LOADING: {
      return {
        ...state,
        offersLoading: true,
        error: '',
      };
    }
    case LOAD_PET_HOME_OFFERS_LOADING: {
      return {
        ...state,
        petOffersLoading: true,
        error: '',
      };
    }
    
    case LOAD_MRH_OFFERS: {
      return {
        ...state,
        mrhDataLoading: true,
        error: '',
      };
    }
    case GET_MRH: {
      return {
        ...state,
        mrhData: action.data,
        mrhDataLoading: false,
      };
    }
    case LOAD_PNO_OFFERS: {
      return {
        ...state,
        pnoDataLoading: true,
        error: '',
      };
    }
    case GET_PNO: {
      return {
        ...state,
        pnoData: action.data,
        pnoDataLoading: false,
      };
    }
    case LOAD_PET_OFFERS: {
      return {
        ...state,
        petsDataLoading: true,
        error: '',
      };
    }
    case GET_PET_OFFERS: {
      return {
        ...state,
        petsOffersData: action.data,
        petsDataLoading: false,
      };
    }
    case GET_NEWS_FEEDS: {
      return {
        ...state,
        newsFeedsList: action.data,
        loading: false,
      };
    }
    case SET_PREFERENCE: {
      return {
        ...state,
        preference: true,
        loading: false,
      };
    }
    case SET_SELECTED_OFFER: {
      return {
        ...state,
        offersCopy: action.data.offersCopy,
        selectedOffer: action.data.selectedOffer,
        loading: false,
      };
    }
    case GET_OFFERS: {
      return {
        ...state,
        offers: action.data,
        offersCopy: action.data,
        offersLoading: false,
      };
    }
    case GET_OFFERS_LUKO_MRH: {
      return {
        ...state,
        lukoMRH: action.data,
        lukoMRHCopy: action.data,
        offersLoading: false,
      };
    }
    case GET_PET_HOME_OFFERS: {
      return {
        ...state,
        petHomeOffers: action.data,
        petOffersLoading: false,
      };
    }
    case GET_SIMULATOR_PET_OFFERS: {
      return {
        ...state,
        petHomeOffers: action.data,
        petOffersLoading: false,
      };
    }
    
    case LOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case POST_ANALYTICS_DATA_REQUEST: {
      return {
        ...state,
        postanalyticsDataLoading: true,
        error: '',
      };
    }

    case POST_ANALYTICS_DATA_SUCCESS: {
      return {
        ...state,
        analytics: action.data,
        loading: false,
      };
    }

    case POST_ANALYTICS_DATA_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}
