import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { useEffect } from "react";
import { homeAction } from "../../redux/actions";
import Loader from "../../components/Loader";

function DiscoverSubscriptionCards() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { lukoMRH, offersLoading } = useSelector((state) => state.home);
  const { t } = useTranslation();

  useEffect(() => {
    const fakeOffersData = [
      {
        category: "Insurance",
        products: [
          {
            name: "mrh",
            cheapest: "5.15",
            providers: [
              {
                name: "luko",
                colorCode: "#0042DA",
                iconPath: "/icons/lukoIcon.svg",
                cheapest: "5.15",
                offers: [],
              },
              {
                name: "lovys",
                colorCode: "#00D3C8",
                iconPath: "/icons/lovysIcon.png",
                cheapest: "6.21",
                offers: [],
              },
            ],
          },
        ],
        cheapest: "5.15",
        colorCode: "#167EB8",
        providerList: ["/icons/lukoIcon.svg", "/icons/lovysIcon.png"],
      },
    ];
    dispatch(homeAction.setOnLoadLukoMRH(fakeOffersData));
  }, [dispatch]);

  const onSeeOffer = () => {
    const isUserLoggedIn = localStorage.getItem("SWEESHER-SESSION");

    if (!isUserLoggedIn) {
      Swal.fire({
        icon: "info",
        title: t("Please create your account to see the offers"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
        confirmButtonText: t("Continue"),
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } else {
      navigate("/home");
    }
  };

  return (
    <div className="SubscriptionCards" style={{ padding: "0px" }}>
      <Loader loading={offersLoading}>
        <div className="discoverCompanyCards">
          {lukoMRH?.map((offer, index) => {
            return offer.products.map((elem) => {
              return elem.providers.map((elem) => {
                return (
                  <div
                    className="ComCard"
                    style={{
                      backgroundColor: elem.colorCode,
                      marginRight: "10px",
                    }}
                  >
                    <div className="ComCardHead">
                      <div className="ComLogo">
                        <img
                          src={`${BASE_URL}${elem.iconPath}`.replace(
                            "/api/",
                            "/"
                          )}
                          alt="img"
                        />
                      </div>
                      {/* <span>{t(`${offer.category}`)}</span> */}
                    </div>
                    <div className="ComCardContent">
                      <p>{t("From")}:</p>
                      <div className="ComPrice">
                        <span>
                          {elem.cheapest}€ /{t("month")}
                        </span>
                      </div>
                      <button target="_blank" onClick={onSeeOffer}>
                        {t("See Offers")}
                      </button>
                    </div>
                  </div>
                );
              });
            });
          })}
        </div>
      </Loader>
      {/* <div className="movingRequestTab card" style={{ minHeight: 300 }}>
                    <div className="requestTabHeader">
                        <p>{t('Moving Request')}</p>
                    </div>
                    <div>
                        <Empty
                            style={{ paddingTop: 30 }}
                            image={Truck}
                            description={
                                <>
                                    <span className='flexCenter' style={{ gridGap: 10 }}>
                                        <HiOutlineExclamationCircle />{t('Feature coming soon!')}
                                    </span>
                                    <div>
                                        {t('Sweesher will help you move')}
                                    </div>
                                </>
                            } />
                    </div>
                </div> */}
    </div>
  );
}

export default DiscoverSubscriptionCards;
