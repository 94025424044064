import React, { useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs';
import { Col, InputNumber, Button, Row, Radio, Input, Slider, Modal } from 'antd';
import LocationRequest from './LocationRequest'

function NewRequest(props) {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showComponent, setShowComponent] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
        setShowComponent(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { goBack } = props
    const [inputValue, setInputValue] = useState(1);
    const [showSec, setSecShow] = useState(false)

    const onChange = (newValue) => {
        setInputValue(newValue);
    };
    const onChangeSec = (event) => {
        setSecShow(event.target.value)
    }
    const formatter = (value) => `${value}$`;
    return (
        <>
            {
                showComponent 
                    ?
                    <LocationRequest goBack={() => setShowComponent(false)} />
                    :
                    <div className='NewRequest'>
                        <div className="newReqHeader">
                            <h3 className='NewReqTitle'>New Request</h3>
                            <BsArrowLeft className="back-icon" onClick={goBack} />
                        </div>
                        <div className="RequestForms">
                            <div className="ReqCard card">
                                <p style={{ marginBottom: 30 }}>What type of house do you live?</p>
                                <Radio.Group className='radioGroupAnt' name='houseType'>
                                    <Radio type='radio' value='Bungalow'>Bungalow</Radio>
                                    <Radio type='radio' value='Duplex'>Duplex</Radio>
                                    <Radio type='radio' value='Flat'>Flat</Radio>
                                    <Radio type='radio' value='Dorms'>Dorms</Radio>
                                    <Radio type='radio' value='Farm house'>Farm house</Radio>
                                    <Radio type='radio' value='Cottage'>Cottage</Radio>
                                    <Radio type='radio' value='Others'>Others</Radio>
                                </Radio.Group>
                            </div>
                            <div className="ReqCard card">
                                <p>What’s the size of your flat?</p>
                                <div className="meterInput">
                                    <Input />
                                    m2
                                </div>
                                <div className="reqSelect">
                                    <p>Are you moving all your belongings?</p>
                                    <Radio.Group onChange={onChangeSec} value={showSec} name='houseType'>
                                        <Radio type='radio' value={false}>Yes</Radio>
                                        <Radio type='radio' value={true}>No</Radio>
                                    </Radio.Group>
                                </div>
                                {showSec &&
                                    <>
                                        <Slider
                                            tooltip={{
                                                formatter,
                                            }}
                                            onChange={onChange}
                                            value={typeof inputValue === 'number' ? inputValue : 0}
                                        />
                                        <InputNumber
                                            min={1}
                                            max={20}
                                            style={{
                                                margin: '0 16px',
                                                background: 'white',
                                                color: 'black'
                                            }}
                                            value={inputValue}
                                            onChange={onChange}
                                            disabled
                                        />
                                    </>
                                }
                                <div className="reqSelect">
                                    <p>Do you have a balcony, cafe or garden?</p>
                                    <Radio.Group name='houseType'>
                                        <Radio type='radio' value='Yes'>Yes</Radio>
                                        <Radio type='radio' value='No'>No</Radio>
                                    </Radio.Group>
                                </div>
                                <div className="reqSelect">
                                    <p>Elevator?</p>
                                    <Radio.Group name='houseType'>
                                        <Radio type='radio' value='Yes'>Yes</Radio>
                                        <Radio type='radio' value='No'>No</Radio>
                                    </Radio.Group>
                                </div>
                                <Input className='mb-15' type="text" placeholder='Enter the size of the elevator' />
                                <Input type="text" placeholder='Number of persons' />
                            </div>
                            <div className="ReqCard card">
                                <p>Car park or delivery spot near the building?</p>
                                <div className="NewReqRadio">
                                    <span className='fade'>Pick up:</span>
                                    <Radio.Group name='houseType'>
                                        <Radio type='radio' value='Yes'>Yes</Radio>
                                        <Radio type='radio' value='No'>No</Radio>
                                    </Radio.Group>
                                </div>
                                <div className="NewReqRadio">
                                    <span className='fade'>Drop off:</span>
                                    <Radio.Group name='houseType'>
                                        <Radio type='radio' value='Yes'>Yes</Radio>
                                        <Radio type='radio' value='No'>No</Radio>
                                    </Radio.Group>
                                </div>
                                <p>Floor</p>
                                <div className="floorCount">
                                    <span>1</span>
                                    <span>2</span>
                                    <span>3</span>
                                    <span>4</span>
                                    <span>5</span>
                                    <span>6</span>
                                    <span>7</span>
                                    <span>8</span>
                                    <span>9</span>
                                    <span>10</span>
                                </div>
                                <Row>
                                    <Col span={12}>
                                        <Slider
                                            min={1}
                                            max={10}
                                        />
                                    </Col>
                                </Row>
                                <Input className='mb-15' type="text" placeholder='Enter floor  number, if more than 10' />
                                <div className="newReqInput">
                                    <p>Desired Moving Date </p>
                                    <Input className='mb-15' type="text" placeholder='29-04-2022' />
                                </div>
                                <div className="newReqInput">
                                    <p>Desired Moving Time </p>
                                    <Input className='mb-15' type="text" placeholder='10:00am' />
                                </div>
                            </div>
                        </div>
                        <div className="NewReqContinue">
                            <div className="space"></div>
                            <div className="space"></div>
                            <Button className='MovinContinueBtn' onClick={showModal}>
                                Continue
                            </Button>
                            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                <p>Some contents...</p>
                                <p>Some contents...</p>
                                <p>Some contents...</p>
                            </Modal>
                        </div>

                    </div>
            }
        </>
    )
}

export default NewRequest;