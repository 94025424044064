import {
  LOAD_USERS_ERROR,
  LOAD_USERS_LOADING,
  OTP_MAIL_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_SESSION_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  UPDATE_MRH_LOADING,
  UPDATE_MRH,
  UPDATE_PNO_LOADING,
  GET_PETS_PROFILES_LOADING,
  GET_PETS_PROFILES,
  CREATE_PET_LOADING,
  CREATE_PET,
  UPDATE_PNO,
  DELETE_PET_LOADING,
  DELETE_PET,
  RESET_ALL,
  RESET_PET_PROFILES
} from "./users.actions";

const initialState = {
  data: [],
  loginData: {
    email: "",
    optEmailId: "",
  },
  user: undefined,
  user_session: "",
  pets: null,
  loading: false,
  loadingDelete: false,
  updateMrhLoading: false,
  mrhAfterUpdate: null,
  updatePnoLoading: false,
  pnoAfterUpdate: null,
  petAfterCreation: null,
  complete: false,
  error: "",
};

export default function reduxThunkReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USERS_LOADING: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case DELETE_PET_LOADING: {
      return {
        ...state,
        loadingDelete: true,
        error: "",
      };
    }
    case DELETE_PET: {
      return {
        ...state,
        loadingDelete: false,
        error: action.error,
      };
    }
    
    case UPDATE_MRH_LOADING: {
      return {
        ...state,
        updateMrhLoading: true,
        error: "",
      };
    }
    case UPDATE_MRH: {
      return {
        ...state,
        updateMrhLoading: false,
        mrhAfterUpdate: action.data,
        user: {
          ...state.user,
          offerProfiles: {
            ...state.user.offerProfiles,
            mrh: action.data ? action.data : state.user.offerProfiles.mrh,
          },
        },
        error: action.error,
      };
    }
    case UPDATE_PNO_LOADING: {
      return {
        ...state,
        updatePnoLoading: true,
        error: "",
      };
    }
    case UPDATE_PNO: {
      return {
        ...state,
        updatePnoLoading: false,
        complete: action.data ? action.data.complete : false,
        pnoAfterUpdate: action.data,
        error: action.error,
      };
    }
    case CREATE_PET_LOADING: {
      return {
        ...state,
        createPetLoading: true,
        error: "",
      };
    }
    case CREATE_PET: {
      return {
        ...state,
        createPetLoading: false,
        completeCreation: action.data ? action.data.complete : false,
        petAfterCreation: action.data,
        error: action.error,
      };
    }
    
    case GET_PETS_PROFILES_LOADING: {
      return {
        ...state,
        getPetsLoading: false,
        complete: action.data ? action.data.complete : false,
        petsAfterGet: action.data,
        error: action.error,
      };
    }
    case GET_PETS_PROFILES: {
      return {
        ...state,
        getPetsLoading: false,
        complete: action.data ? action.data.complete : false,
        petsAfterGet: action.data,
        petsProfiles: action.data?.data?.data ,
        error: action.error,
      };
    }
    case OTP_MAIL_SUCCESS: {
      return {
        ...state,
        loginData: action.data,
        loading: false,
      };
    }
    case USER_SESSION_SUCCESS: {
      return {
        ...state,
        user_session: action.data,
        loading: false,
      };
    }
    case RESET_PET_PROFILES: {
      return {
        ...state,
        petsProfiles : []
      };
    }
    case RESET_ALL: {
      return {
        ...state,
        data: [],
        loginData: {
          email: "",
          optEmailId: "",
        },
        user: undefined,
        user_session: "",
        loading: false,
        updateMrhLoading: false,
        mrhAfterUpdate: null,
        updatePnoLoading: false,
        pnoAfterUpdate: null,
        error: "",
      };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.data,
        loading: false,
      };
    }
    case LOAD_USERS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        user: action.data,
        loading: false,
      };
    }
    case UPDATE_ACCOUNT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
}
/*
{
    "serviceId": "user-test-bbd2986b-677d-4168-ae1e-b283ae0ba660",
    "email": "mbouzidi63+test2@gmail.com",
    "role": "user",
    "profile": {
        "isNewsLetterEnabled": true,
        "createdAt": "2023-03-11T07:21:50.265Z",
        "updatedAt": "2023-03-11T07:21:50.265Z",
        "objectId": "dIocCqCmCr",
        "__type": "Object",
        "className": "Profile"
    },
    "createdAt": "2023-03-11T07:21:50.654Z",
    "updatedAt": "2023-03-11T07:21:50.654Z",
    "objectId": "RmIZHBEppg",
    "offerProfiles": {
        "mrh": {
            "userId": {
                "__type": "Pointer",
                "className": "Users",
                "objectId": "RmIZHBEppg"
            },
            "occupantType": "tenant",
            "propertyType": "Appartment",
            "ownerAddress": "Paris 83170 Brignoles",
            "floor": "ground",
            "room": 3,
            "size": 50,
            "yearBuilt": 1970,
            "capitalFurniture": 3000,
            "hasSwimmingPool": false,
            "hasChimney": false,
            "hasFireAlarm": false,
            "hasSolarPanels": false,
            "active": false,
            "createdAt": "2023-03-16T04:56:42.792Z",
            "updatedAt": "2023-03-16T04:56:42.792Z",
            "objectId": "K1Sgk2zI9g"
        },
        "pets": null,
        "smartphone": null,
        "pno": null,
        "car": null
    }
}
*/
