export const documents = [
  {
    name: "document1",
    value: "Extract less than 3 months old",
    document: {},
  },
  {
    name: "document2",
    value: "Proof of civil liabilty insurance",
    document: {},
  },
  {
    name: "document3",
    value: "Transport license (commununity or internal)",
    document: {},
  },
  {
    name: "document4",
    value:
      "Certificate of furnishing of social declearations less than 6 months old",
    document: {},
  },
  {
    name: "document5",
    value: "Identity document of the leader of the legal person",
    document: {},
  },
  {
    name: "document6",
    value:
      "Proof of registration of carriers in the national register of freight carriers",
    document: {},
  },
];

export const filterType = { search: "SEARCH", status: "STATUS" };
export const filterFor = { movingRequest: "MOVING_REQUEST", quote: "QUOTE" };
export const requestStatuss = [
  { name: "All", value: "all" },
  { name: "New", value: "new" },
  { name: "Cancelled", value: "cancelled" },
  { name: "Pending", value: "pending" },
  { name: "Delivered", value: "delivered" },
  { name: "Approved", value: "approved" },
  { name: "Expired", value: "expired" },
];
export const userRoles = ["user", "admin", "partner"];

export const cardsColor = [
  '#845EC2',
  "#D65DB1",
  "#FF6F91",
  "#FF9671",
  "#FFC75F",
  "#F9F871",
  "#2C73D2",
  "#0081CF",
  "#0089BA",
  "#008E9B"
]

export const background = [
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
  "#e4f4ff",
  "#e7ffa6",
  "#ffefac",
  "#ffd4fa",
];
