import React from "react";
import Carousel from "react-multi-carousel-18";
import { useSelector, useDispatch } from "react-redux";
import { Empty, Button } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import Logo from "../../assets/images/sweesherLogo.png";

import Truck from "../../assets/images/truck-remove.png";

import { BASE_URL } from "../../constant";

import "react-multi-carousel-18/lib/styles.css";
import LukoModal from "./Discover/LukoModal";
import DiscoverSubscriptionCards from "./DiscoverSubscriptionCard";
import { useEffect } from "react";
import { homeAction } from "../../redux/actions";
import i18n from '../../i18n';
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "600px",
    transform: "translate(-50%, -50%)",
  },
};
const Discover = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { newsFeedsList } = useSelector((state) => state.home);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2560 },
      items: 5,
    },
    desktopU: {
      breakpoint: { max: 2560, min: 1086 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1085, min: 948 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 947, min: 631 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 630, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if(!newsFeedsList.length) {
        dispatch(homeAction.getNewsFeeds())
    }
    i18n.changeLanguage('fr')
  },[dispatch, newsFeedsList])

  const background=[
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
    "#e4f4ff",
    "#e7ffa6",
    "#ffefac",
    "#ffd4fa",
  ];

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div style={{ marginLeft: 15 }} className="mt-5 ms-5">
        <div className="movingTickets">
          <div className="DiscoverBanner">
            <img src={Logo} alt="img" />
            <h3 style={{ fontWeight: "bolder", marginBottom: 0 }}>
              {t("sweeshers")}
            </h3>
          </div>

          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlay={props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {newsFeedsList.length > 0 &&
              newsFeedsList.map((newsfeed, index) => {
                const displayPeriod = newsfeed["startDate"]
                  ? moment
                      .utc(new Date(newsfeed["startDate"].iso))
                      .locale("fr")
                      .format("ll") +
                    " - " +
                    moment
                      .utc(new Date(newsfeed["endDate"].iso))
                      .locale("fr")
                      .format("ll")
                  : "";
                return (
                  <div
                    className="movingTicket"
                    style={{ backgroundColor: background[index] }}
                    key={newsfeed.objectId}
                    onClick={() => window.open(newsfeed.url, "blank")}
                  >
                    <p className="ticketTitle">{newsfeed.title}</p>
                    {displayPeriod && (
                      <span className="ticketDate">{displayPeriod}</span>
                    )}
                    <div className="fedex-frame">
                      {newsfeed.icon && (
                        <img
                          className="img"
                          src={`${BASE_URL}${newsfeed.icon}`.replace(
                            "/api/",
                            "/"
                          )}
                          alt="loading.."
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
        <div className="TestOfferContainer">
          <Button className="TestOfferBtn" onClick={openModal}>
            {t("test with your address")}
          </Button>
          <h4 style={{padding: "0px 5px", paddingTop:"15px" ,fontSize:"20px"}}>{t("Home Insurance")}</h4>
        </div>
        <div className="subscription">
          <div className="subTabs remove-grid">
            <DiscoverSubscriptionCards />
            {/* <div className="subTab" onClick={() => { }} >
                        <div className="subTabHeader">
                            <span>{t(`${'Home Insurance'}`)}</span>
                            <div className="sponserImgs">
                                <img src={Brand1} alt="loading.." />
                                <img src={Brand2} alt="loading.." />
                            </div>
                        </div>
                        <div className='PartnerModalButton'>
                            <button onClick={openModal}>Luko</button>
                            <button onClick={openModal2}>Lavys</button>
                        </div>
                        
                    </div> */}
          </div>

          <div
            className="movingRequestTab card"
            style={{ minHeight: 300, marginRight: "10px" }}
          >
            <div className="requestTabHeader">
              <p>{t("Moving Request")}</p>
            </div>
            <div>
              <Empty
                style={{ paddingTop: 30 }}
                image={Truck}
                description={
                  <>
                    <span className="flexCenter" style={{ gridGap: 10 }}>
                      <HiOutlineExclamationCircle />
                      {t("Feature coming soon!")}
                    </span>
                    <div>{t("Sweesher will help you move")}</div>
                  </>
                }
              />
            </div>
          </div>
          <LukoModal
            modalIsOpen={modalIsOpen}
            afterOpenModal={afterOpenModal}
            closeModal={closeModal}
            customStyles={customStyles}
            subtitle={subtitle}
          />
        </div>
      </div>
    </>
  );
};

export default Discover;
