import { useSelector } from "react-redux";
import downArrow from "../../../assets/images/svg/down_arrow.svg";
import Form from "./form";
import style from "./style.module.scss";
import { useEffect } from "react";
import ResultConnection from "./ResultConnection";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";

export default function SimulationDiv() {
  const { lukoMRH, offersLoading,petHomeOffers } = useSelector((state) => state.home);
  const isTablette = useMediaQuery({ query: `(max-width: 750px)` });
  const isMobile = useMediaQuery({ query: `(max-width: 600px)` });
  const simulationRef = useRef(null);
  useEffect(() => {
    if (simulationRef && lukoMRH && lukoMRH.length)
      if (isMobile) simulationRef.current.style.width = "350px";
      else if (isTablette) simulationRef.current.style.width = `550px`;
      else simulationRef.current.style.width = `654px`;
    else {
      if (isMobile) simulationRef.current.style.width = "350px";
      else if (isTablette) simulationRef.current.style.width = `550px`;
      else simulationRef.current.style.width = `654px`;
    }
  }, [lukoMRH, isTablette, isMobile]);
  return (
    <div className={style.simulation_box}>
      <div aria-label="heading">
        <img src={downArrow} alt="down arrow" />
        <h1 aria-label="simulation-title">Faites votre simulation</h1>
        <img src={downArrow} alt="down arrow" />
      </div>

      <div
        ref={simulationRef}
        id="simulation-container"
        className={style.simulation_container}
      >
        {lukoMRH?.length || petHomeOffers ? (
          <ResultConnection
            ref={simulationRef}
            providers={petHomeOffers ? [petHomeOffers] : lukoMRH[0].products[0].providers}
          />
        ) : (
          <Form loading={offersLoading} />
        )}
      </div>
    </div>
  );
}

