import CustomNavBar from "./navbar";
import Simulation from "./simulation";
import HowItWorks from "./howItWorks";
import About from "./aboutus";
import FAQ from "./FAQ";
import Partner from "./partner";
import Footer from "./footer";
import style from "./style.module.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { homeAction } from "../../redux/actions";

export default function Index() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(homeAction.resetAll());
  }, [dispatch]);
  return (
    <div className={style.index}>
      <CustomNavBar />
      <Simulation />
      <HowItWorks />
      <About />
      <FAQ />
      <Partner />
      <Footer />
    </div>
  );
}
