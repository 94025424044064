import React, { useState } from "react";
import { Button } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { MdLocationPin } from "react-icons/md";
import Packages from "./Packages";

function LocationRequest(props) {
  const [showComponent, setShowComponent] = useState(false);
  const { goBack } = props;
  return (
    <>
      {showComponent ? (
        <Packages goBack={() => setShowComponent(false)} />
      ) : (
        <div className="location NewRequest">
          <div className="newReqHeader locationHeader flexCenter">
            <BsArrowLeft className="back-icon" onClick={goBack} />
            <span className="NewReqTitle">Location</span>
          </div>
          <div className="LocationCard card">
            <div className="Map">
              <div className="mapouter">
                {/* <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    width="100%"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=France&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div> */}
              </div>
            </div>
            <div className="MapInfo">
              <div className="MapLogo">
                <BiCurrentLocation className="currentLocation" />
                <span>.</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
                <span id="lastSpan">.</span>
                <MdLocationPin className="locationPin" />
              </div>
              <div className="MapContent">
                <p>54, Frejus, France</p>
                <p>D&C, Areme Edunord, France</p>
              </div>
            </div>
            <div className="NewReqContinue LocationContinue">
              <div className="space"></div>
              <div className="space"></div>
              <Button
                className="MovinContinueBtn"
                onClick={() => setShowComponent(true)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LocationRequest;
