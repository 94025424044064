import React from 'react';
import ReactDOM from 'react-dom/client';
import ConnectyCube from 'connectycube';
import { store, persistor } from './redux/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './assets/scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';

const credentials = {
  appId: process.env.REACT_APP_CC_APPLICATION_ID,
  authKey: process.env.REACT_APP_CC_AUTH_KEY,
  authSecret: process.env.REACT_APP_CC_SECRET_KEY,
};

const config = {
  debug: { mode: 1 },
};

ConnectyCube.init(credentials, config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
         <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
);
