import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Radio, Checkbox, AutoComplete } from "antd";
import DatePicker from "react-datepicker";

import { BsArrowLeft } from "react-icons/bs";
import { Country, City } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { homeAction, userAction } from "../../../redux/actions";
import SweesherLogo from "../../../assets/images/sweesherLogo.png";
import Loader from "../../../components/Loader";

import "react-datepicker/dist/react-datepicker.css";

export const CarContract = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading } = useSelector((state) => state.users);
  const homeStates = useSelector((state) => state.home);
  const [options, setOptions] = useState([]);
  const [requiredItem, setRequiredItem] = useState({
    address: false,
    gearBox: false,
    vehicleType: false,
    fuelType: false,
    utilizationLevel: false,
    parkingType: false,
    driverOccupationalCategory: false,
    driverBirthdate: false,
    driverDrivingLicenseDate: false,
    driverAlreadyInsured: false,
    driverCancelledPolicyLast3Years: false,
    driverInsuredPrevious36Months: false,
    driverLicenseTakenLast5Years: false,
    driverMajorClaimFilled: false,
  });

  const { Option } = Select;

  const [formData, setFormData] = useState({
    firstName: user.profile.firstName ? user.profile.firstName : "",
    lastName: user.profile.lastName ? user.profile.lastName : "",
    dob: user.profile.dob ? new Date(user.profile.dob.iso) : "",
    phoneNumber: user.profile ? user.profile.phoneNumber : "",
    address: user.profile ? user.profile.address : "",
    postalCode: user.profile ? user.profile.postCode : "",
    city: user.profile ? user.profile.city : "",
    country: user.profile ? user.profile.country : "",
    plateNumber: user?.offerProfiles?.car?.VIPlateNumber ?? "",
    vehicleModel: user?.offerProfiles?.car?.VIModel ?? "",
    horsepowerClass: user?.offerProfiles?.car?.VIHorsepowerClass ?? "",
    gearBox: user?.offerProfiles?.car?.VIGearbox ?? "",
    firstCirculationDate: user?.offerProfiles?.car?.VIFirstCirculationDate
      ? new Date(user?.offerProfiles?.car?.VIFirstCirculationDate)
      : "",
    vehicleType: user?.offerProfiles?.car?.VIVehicleType ?? "",
    vehicleGroup: user?.offerProfiles?.car?.VIGroup ?? "",
    vehicleClass: user?.offerProfiles?.car?.VIVehicleClass ?? "",
    fuelType: user?.offerProfiles?.car?.VIFuelType ?? "",
    acquisitionDate: user?.offerProfiles?.car?.VIAcquisitionDate
      ? new Date(user?.offerProfiles?.car?.VIAcquisitionDate)
      : "",
    co2Emissions: user?.offerProfiles?.car?.VICo2Emissions ?? "",
    sraCode: user?.offerProfiles?.car?.VISraCode ?? "",
    utilizationLevel: user?.offerProfiles?.car?.VUUtilizationLevel ?? "",
    annualMileageAverage:
      user?.offerProfiles?.car?.VUAnnualMileageAverage ?? "",
    parkingType: user?.offerProfiles?.car?.VUParkingType ?? "",
    circulationZoneZipCode:
      user?.offerProfiles?.car?.VUCirculationZoneZipCode ?? "",
    driverOccupationalCategory:
      user?.offerProfiles?.car?.VDFDOccupationalCategory ?? "",
    driverAddressZipCode: user?.offerProfiles?.car?.VDFDAddressZipCode ?? "",
    driverBirthdate: user?.offerProfiles?.car?.VDFDBirthdate
      ? new Date(user?.offerProfiles?.car?.VDFDBirthdate)
      : "",
    driverDrivingLicenseDate: user?.offerProfiles?.car?.VDFDDrivingLicenseDate
      ? new Date(user?.offerProfiles?.car?.VDFDDrivingLicenseDate)
      : "",
    driverAccompaniedDriving:
      user?.offerProfiles?.car?.VDFDAccompaniedDriving ?? false,
    driverAlreadyInsured:
      user?.offerProfiles?.car?.VDFDIHAlreadyInsured ?? "No",
    driverCancelledPolicyLast3Years:
      user?.offerProfiles?.car?.VDFDIHCancelledPolicyLast3Years ?? "No",
    driverInsuredPrevious36Months:
      user?.offerProfiles?.car?.VDFDIHInsuredPrevious36Months ===
      "YesMoreOrEqualThan36Month"
        ? "Yes"
        : "No",
    driverLicenseTakenLast5Years:
      user?.offerProfiles?.car?.VDFDIHLicenseTakenLast5Years ?? "No",
    driverMajorClaimFilled:
      user?.offerProfiles?.car?.VDFDIHMajorClaimFilled ?? "No",
    driverBonusPenaltyCoefficient:
      user?.offerProfiles?.car?.VDFDIHBonusPenaltyCoefficient ?? "",
    driverBonusPenaltyYears:
      user?.offerProfiles?.car?.VDFDIHBonusPenaltyYears ?? "",
  });

  const mrhAddress = user.profile ? user.profile.address : null

  const { t } = useTranslation();

  const onCountryChange = (value) => {
    setFormData({ ...formData, country: JSON.parse(value).name });
  };

  useEffect(() => {
    dispatch(userAction.getLoginUser());
  }, [homeStates.loading, dispatch]);


  const onChange = (event) => {
    if (event.target.checked && event.target.type !== "radio") {
      setFormData({ ...formData, [event.target.name]: event.target.checked });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const onCityChange = (value) => {
    setFormData({ ...formData, city: JSON.parse(value).name });
  };

  const onSelectChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setRequiredItem({ ...requiredItem, [field]: false });
  };
  const onDateChange = (dateString, field) => {
    if (field) {
      setFormData({ ...formData, [field]: dateString });
    } else {
      setFormData({ ...formData, dob: dateString });
    }
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getIsoCodeOfCity = (value) => {
    const res = Country.getAllCountries().filter(
      (elem) =>
        elem.name ===
        (value === "mrh" ? formData.country : formData.secondaryHouseCountry)
    );
    if (res.length > 0) return res[0].isoCode;
  };

  const onSaveAndContinue = (e) => {
    e.preventDefault();
    let showError = false;
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "address",
      "gearBox",
      "vehicleType",
      "fuelType",
      "utilizationLevel",
      "parkingType",
      "driverOccupationalCategory",
      "driverBirthdate",
      "driverDrivingLicenseDate",
      "driverAlreadyInsured",
      "driverCancelledPolicyLast3Years",
      "driverInsuredPrevious36Months",
      "driverLicenseTakenLast5Years",
      "driverMajorClaimFilled",
    ];
    const errorObj = {};
    requiredFields.forEach((key) => {
      if (typeof formData[key] === "string" && !formData[key].length) {
        errorObj[key] = true;
        showError = true;
      } else errorObj[key] = false;
    });

    if (showError) {
      setRequiredItem({ ...requiredItem, ...errorObj });
      return;
    }

    const carInsuranceProfile = {
      ProductTypeCode: "Auto",
      VIFinancialOption: "Owner",
      VIPlateNumber: formData.plateNumber,
      VIModel: formData.vehicleModel,
      VIHorsepowerClass: Number(formData.horsepowerClass),
      VIGearbox: formData.gearBox,
      VIFirstCirculationDate: new Date(
        formData.firstCirculationDate
      ).toISOString(),
      VIVehicleType: formData.vehicleType,
      VIGroup: Number(formData.vehicleGroup),
      VIVehicleClass: formData.vehicleClass,
      VIFuelType: formData.fuelType,
      VIAcquisitionDate: new Date(formData.acquisitionDate).toISOString(),
      VICo2Emissions: Number(formData.co2Emissions),
      VISraCode: formData.sraCode,
      VUUtilizationLevel: formData.utilizationLevel,
      VUAnnualMileageAverage: Number(formData.annualMileageAverage),
      VUParkingType: formData.parkingType,
      VUCirculationZoneZipCode: formData.circulationZoneZipCode,
      VDFDAddressZipCode: formData.driverAddressZipCode,
      VDFDBirthdate: new Date(formData.driverBirthdate).toISOString(),
      VDFDDrivingLicenseDate: new Date(
        formData.driverDrivingLicenseDate
      ).toISOString(),
      VDFDAccompaniedDriving: formData.driverAccompaniedDriving,
      VDFDOccupationalCategory: formData.driverOccupationalCategory,
      VDFDIHAlreadyInsured: formData.driverAlreadyInsured,
      VDFDIHCancelledPolicyLast3Years: formData.driverCancelledPolicyLast3Years,
      VDFDIHInsuredPrevious36Months:
        formData.driverInsuredPrevious36Months === "Yes"
          ? "YesMoreOrEqualThan36Month"
          : "No",
      VDFDIHLicenseTakenLast5Years: formData.driverLicenseTakenLast5Years,
      VDFDIHMajorClaimFilled: formData.driverMajorClaimFilled,
      VDFDIHBonusPenaltyCoefficient: Number(
        formData.driverBonusPenaltyCoefficient
      ),
      VDFDIHBonusPenaltyYears: Number(formData.driverBonusPenaltyYears),
    };
    // return;

    const formattedObj = {
      userProfile: {
        lastName: formData.lastName,
        firstName: formData.firstName,
        city: formData.city,
        postCode: formData.postalCode,
        country: formData.country,
        address: formData.address,
        dob: formData.dob ? formData.dob : undefined,
        phoneNumber: formData.phoneNumber,
        displayPicture: "",
      },
      car: carInsuranceProfile,
    };
    dispatch(
      homeAction.setPreference(formattedObj, navigate, user.profile.firstName)
    );
  };

  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };

  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onAddressChange = (e) => {
    if (e === "") setFormData({ ...formData, address: e });
  };

  const onSelect = (data) => {
    const seletecData = options.find((el) => el.properties.label === data);
    setFormData({
      ...formData,
      address: seletecData.properties.label,
      city: seletecData.properties.city,
      country: "France",
      postalCode: seletecData.properties.postcode,
    });
  };

  return (
    <Loader loading={loading}>
      <div className="selectType">
        <div className="invoiceModelHeader mb-15">
          <img src={SweesherLogo} alt="img" />
          <h3>Sweesher</h3>
        </div>
        <div className="prefSelectBox">
          {user.profile.firstName && (
            <BsArrowLeft
              className="back-icon"
              onClick={() => navigate("/home", { state: "3" })}
            />
          )}
          <p style={{ textAlign: "center" }}>{t("FormCompusloryText")}</p>
          <form onSubmit={onSaveAndContinue}>
            <div className="prefModel">
              <div className="prefModelInputs">
                <div className="prefInput">
                  <input
                    placeholder={t("First Name")}
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("Last Name")}
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={onChange}
                    required
                  />
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <DatePicker
                    placeholderText={t("Date of birth")}
                    name="dob"
                    selected={formData.dob}
                    dateFormat="yyyy-MM-dd"
                    onChange={(dateString) => onDateChange(dateString, "dob")}
                    style={{ width: 260, borderRadius: 10, height: 35 }}
                  />
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("Phone Number")}
                    value={formData.phoneNumber}
                    type="text"
                    name="phoneNumber"
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* <h4 className="TitleHeader">{t("Principal Residence")}</h4> */}
              <div className="prefModelInputs">
                {/* <div className="prefInput">
                    <Radio.Group onChange={onChange} name='houseType' value={formData.houseType}>
                      <Radio type='radio' value={'Apartment'}>{t('Apartment')}</Radio>
                      <Radio type='radio' value={'House'}>{t('House')}</Radio>
                    </Radio.Group>
                    {requiredItem.houseType ? <p style={{ color: 'red' }}>Please select one</p> : ''}
                  </div> */}
                <div className="prefInput">
                  <AutoComplete
                    defaultValue={mrhAddress}
                    options={
                      options.length > 0
                        ? options.map((el) => ({
                            value: el.properties.label,
                            id: 1,
                          }))
                        : options
                    }
                    style={{ width: 290 }}
                    onChange={onAddressChange}
                    onSelect={onSelect}
                    onSearch={onAddressSearch}
                    placeholder="Address"
                  />
                  {requiredItem.address ? (
                    <p style={{ color: "red" }}>Please fill this field</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="prefInput">
                  <input
                    disabled
                    placeholder={t("Postal Code")}
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={onChange}
                    type="text"
                    required
                  />
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <Select
                    showSearch
                    allowClear
                    disabled
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("Select a Country")}
                    optionFilterProp="children"
                    value={formData.country ? formData.country : undefined}
                    onChange={onCountryChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {Country.getAllCountries().map((el, index) => {
                      return (
                        <Option value={JSON.stringify(el)} id={index}>
                          {el.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>

                <div className="prefInput">
                  <Select
                    showSearch
                    allowClear
                    disabled
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("Select a City")}
                    optionFilterProp="children"
                    value={formData.city ? formData.city : undefined}
                    onChange={onCityChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {City.getCitiesOfCountry(getIsoCodeOfCity("mrh")).map(
                      (el, index) => {
                        return (
                          <Option value={JSON.stringify(el)} id={index}>
                            {el.name}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <input
                    placeholder={t("plateNumber")}
                    name="plateNumber"
                    value={formData.plateNumber}
                    onChange={onChange}
                    type="text"
                    required
                  />
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("vehicleModel")}
                    type="number"
                    value={formData.vehicleModel}
                    name="vehicleModel"
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <div style={{ display: "flex" }}>
                    <input
                      placeholder={t("horsepowerClass")}
                      type="number"
                      name="horsepowerClass"
                      value={formData.horsepowerClass}
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>
                <div className="prefInput">
                  <div className="prefInput">
                    <Select
                      showSearch
                      style={{ width: 260, borderRadius: 10 }}
                      placeholder={t("gearBox")}
                      optionFilterProp="children"
                      value={formData.gearBox}
                      onChange={(value) => onSelectChange("gearBox", value)}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      <Option value={"Automatic"}>
                        {t("gearBoxAutomatic")}
                      </Option>
                      <Option value={"Manual"}>{t("gearBoxManual")}</Option>
                    </Select>
                    {requiredItem.gearBox ? (
                      <p style={{ color: "red" }}>Please select one</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <DatePicker
                    placeholderText={t("firstCirculationDate")}
                    name="firstCirculationDate"
                    selected={formData.firstCirculationDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(dateString) =>
                      onDateChange(dateString, "firstCirculationDate")
                    }
                    style={{ width: 260, borderRadius: 10, height: 35 }}
                  />
                </div>
                <div className="prefInput">
                  <Select
                    showSearch
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("vehicleType")}
                    optionFilterProp="children"
                    value={formData.vehicleType}
                    onChange={(value) => onSelectChange("vehicleType", value)}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value={"Break"}>{t("vehicleTypeBreak")}</Option>
                  </Select>
                  {requiredItem.vehicleType ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <input
                    placeholder={t("vehicleGroup")}
                    name="vehicleGroup"
                    value={formData.vehicleGroup}
                    onChange={onChange}
                    type="number"
                    required
                  />
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("vehicleClass")}
                    type="text"
                    value={formData.vehicleClass}
                    name="vehicleClass"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="prefModelInputs">
                <div className="prefInput">
                  <Select
                    showSearch
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("fuelType")}
                    optionFilterProp="children"
                    value={formData.fuelType}
                    onChange={(value) => onSelectChange("fuelType", value)}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value={"Diesel"}>{t("Diesel")}</Option>
                  </Select>
                  {requiredItem.fuelType ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="prefInput">
                  <DatePicker
                    placeholderText={t("acquisitionDate")}
                    name="acquisitionDate"
                    selected={formData.acquisitionDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(dateString) =>
                      onDateChange(dateString, "acquisitionDate")
                    }
                    style={{ width: 260, borderRadius: 10, height: 35 }}
                  />
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <input
                    placeholder={t("co2Emissions")}
                    name="co2Emissions"
                    value={formData.co2Emissions}
                    onChange={onChange}
                    type="number"
                    required
                  />
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("sraCode")}
                    type="text"
                    value={formData.sraCode}
                    name="sraCode"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="prefModelInputs">
                <div className="prefInput">
                  <Select
                    showSearch
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("utilizationLevel")}
                    optionFilterProp="children"
                    value={formData.utilizationLevel}
                    onChange={(value) =>
                      onSelectChange("utilizationLevel", value)
                    }
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value={"Private"}>{t("Private")}</Option>
                  </Select>
                  {requiredItem.utilizationLevel ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("annualMileageAverage")}
                    type="number"
                    value={formData.annualMileageAverage}
                    name="annualMileageAverage"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="prefModelInputs">
                <div className="prefInput">
                  <Select
                    showSearch
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("parkingType")}
                    optionFilterProp="children"
                    value={formData.parkingType}
                    onChange={(value) => onSelectChange("parkingType", value)}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value={"ClosedCoveredParking"}>
                      {t("ClosedCoveredParking")}
                    </Option>
                  </Select>
                  {requiredItem.parkingType ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("circulationZoneZipCode")}
                    type="number"
                    value={formData.circulationZoneZipCode}
                    name="circulationZoneZipCode"
                    onChange={onChange}
                  />
                </div>
              </div>
              <h4 className="TitleHeader">{t("Driver Information")}</h4>
              <div className="prefModelInputs">
                <div className="prefInput">
                  <Select
                    showSearch
                    style={{ width: 260, borderRadius: 10 }}
                    placeholder={t("driverOccupationalCategory")}
                    optionFilterProp="children"
                    value={formData.driverOccupationalCategory}
                    onChange={(value) =>
                      onSelectChange("driverOccupationalCategory", value)
                    }
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    <Option value={"Employee"}>{t("Employee")}</Option>
                  </Select>
                  {requiredItem.driverOccupationalCategory ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("driverAddressZipCode")}
                    type="number"
                    value={formData.driverAddressZipCode}
                    name="driverAddressZipCode"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="prefModelInputs">
                <div className="prefInput">
                  <DatePicker
                    placeholderText={t("driverBirthdate")}
                    name="driverBirthdate"
                    selected={formData.driverBirthdate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(dateString) =>
                      onDateChange(dateString, "driverBirthdate")
                    }
                    style={{ width: 260, borderRadius: 10, height: 35 }}
                  />
                  {requiredItem.driverBirthdate ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="prefInput">
                  <DatePicker
                    placeholderText={t("driverDrivingLicenseDate")}
                    name="driverDrivingLicenseDate"
                    selected={formData.driverDrivingLicenseDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(dateString) =>
                      onDateChange(dateString, "driverDrivingLicenseDate")
                    }
                    style={{ width: 260, borderRadius: 10, height: 35 }}
                  />
                  {requiredItem.driverDrivingLicenseDate ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <Checkbox
                    name="driverAccompaniedDriving"
                    checked={formData.driverAccompaniedDriving}
                    onChange={onChange}
                  >
                    {t("driverAccompaniedDriving")}
                  </Checkbox>
                </div>
              </div>

              <div className="prefModelInputs">
                <p className="bottomCheckboxTitle">{t("Already Insured")} ?</p>
                <div className="prefInput">
                  <Radio.Group
                    onChange={onChange}
                    name="driverAlreadyInsured"
                    value={formData.driverAlreadyInsured}
                  >
                    <Radio type="radio" value={"Yes"}>
                      {t("Yes")}
                    </Radio>
                    <Radio type="radio" value={"No"}>
                      {t("No")}
                    </Radio>
                  </Radio.Group>
                  {requiredItem.driverAlreadyInsured ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="prefModelInputs">
                <p className="bottomCheckboxTitle">
                  {t("driverCancelledPolicyLast3Years")} ?
                </p>
                <div className="prefInput">
                  <Radio.Group
                    onChange={onChange}
                    name="driverCancelledPolicyLast3Years"
                    value={formData.driverCancelledPolicyLast3Years}
                  >
                    <Radio type="radio" value={"Yes"}>
                      {t("Yes")}
                    </Radio>
                    <Radio type="radio" value={"No"}>
                      {t("No")}
                    </Radio>
                  </Radio.Group>
                  {requiredItem.driverCancelledPolicyLast3Years ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="prefModelInputs">
                <p className="bottomCheckboxTitle">
                  {t("InsuredPrevious36Months")} ?
                </p>
                <div className="prefInput">
                  <Radio.Group
                    onChange={onChange}
                    name="driverInsuredPrevious36Months"
                    value={formData.driverInsuredPrevious36Months}
                  >
                    <Radio type="radio" value={"Yes"}>
                      {t("Yes")}
                    </Radio>
                    <Radio type="radio" value={"No"}>
                      {t("No")}
                    </Radio>
                  </Radio.Group>
                  {requiredItem.driverInsuredPrevious36Months ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="prefModelInputs">
                <p className="bottomCheckboxTitle">
                  {t("LicenseTakenLast5Years")} ?
                </p>
                <div className="prefInput">
                  <Radio.Group
                    onChange={onChange}
                    name="driverLicenseTakenLast5Years"
                    value={formData.driverLicenseTakenLast5Years}
                  >
                    <Radio type="radio" value={"Yes"}>
                      {t("Yes")}
                    </Radio>
                    <Radio type="radio" value={"No"}>
                      {t("No")}
                    </Radio>
                  </Radio.Group>
                  {requiredItem.driverLicenseTakenLast5Years ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="prefModelInputs">
                <p className="bottomCheckboxTitle">{t("MajorClaimFilled")} ?</p>
                <div className="prefInput">
                  <Radio.Group
                    onChange={onChange}
                    name="driverMajorClaimFilled"
                    value={formData.driverMajorClaimFilled}
                  >
                    <Radio type="radio" value={"Yes"}>
                      {t("Yes")}
                    </Radio>
                    <Radio type="radio" value={"No"}>
                      {t("No")}
                    </Radio>
                  </Radio.Group>
                  {requiredItem.driverMajorClaimFilled ? (
                    <p style={{ color: "red" }}>Please select one</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="prefModelInputs">
                <div className="prefInput">
                  <input
                    placeholder={t("BonusPenaltyCoefficient")}
                    name="driverBonusPenaltyCoefficient"
                    value={formData.driverBonusPenaltyCoefficient}
                    onChange={onChange}
                    type="number"
                    required
                  />
                </div>
                <div className="prefInput">
                  <input
                    placeholder={t("BonusPenaltyYears")}
                    type="number"
                    value={formData.driverBonusPenaltyYears}
                    name="driverBonusPenaltyYears"
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="continueBtnPref flexCenter">
                <button type="submit" htmlType="submit" className="primaryBtn">
                  {t("See Offers")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Loader>
  );
};
