import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { useEffect } from "react";
import { Spin } from "antd";
import { t } from "i18next";
import { cardsColor as background } from "../../constant/globals";

function PricePlans() {
  const navigate = useNavigate();
  const location = useLocation();
  const [plans, setPlans] = useState();

  useEffect(() => {
    if (location.state) {
      setPlans(location.state);
    } else navigate("/home");
  }, [location, navigate]);

  return (
    <div className="SubscriptionCards">
      <div className="TabsHeader">
        <BsArrowLeft className="back-icon" onClick={() => navigate(-1)} />
        <h2 style={{ textAlign: "center", marginBottom: 0 }}>
          {plans ? plans.name : ""}
        </h2>
      </div>
      <div className="companyCards PricePlan">
        {plans && plans.offers.length > 0 ? (
          plans.offers.map((elem, index) => {
            return (
              <div
                key={`plans-${elem.name}-${index}`}
                className="ComCard PricePlanCard"
                style={{ borderColor: background[index] }}
              >
                <div className="ComCardHead PriceHead">
                  <div className="ComLogo">
                    {/* <img src={(`${BASE_URL}${elem.iconPath}`).replace('/api', '')} alt="img" /> */}
                    <p style={{ background: background[index] }}>{elem.name}</p>
                  </div>
                  {/* <span>{offer.elem}</span> */}
                </div>
                <div className="ComCardContent PricePLanContent">
                  <p>{t("Monthly")}:</p>
                  <div className="ComPrice">
                    <span>{elem.price}€</span>
                  </div>
                  <button
                    style={{ background: background[index] }}
                    onClick={() => window.open(elem.url, "_blank")}
                  >
                    {t("Apply")}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );
}

export default PricePlans;
