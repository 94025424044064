import { Icon } from "@iconify/react";
import style from "./style.module.scss";
import { Collapse } from "@mui/material";
import { useState } from "react";

const FAQ = [
  {
    title: "Sweesher ? c'est quoi ?",
    content: `Sweesher c'est une application mobile et web simple à utiliser qui vous permet de comparer en permanence les offres les plus adaptées à votre situation.`,
  },
  {
    title: "Sweesher, comment ça marche ?",
    content: `Une fois que vous êtes connectés à Sweesher vous avez la possibilité de remplir votre profil, grâce aux informations que vous aurez saisies Sweesher va trouver des offres sur mesure pour vous. Pour y adhérer, rien de plus simple, choisissez l'offre et poursuivez les démarches sur le site de notre partenaire ! (Ne vous inquiétez pas vous n'aurez pas besoin de ressaisir toutes vos informations, on s'en occupe !)`,
  },
  {
    title: "Est-ce que le service est payant ?",
    content: `Sweesher est complètement gratuit ! `,
  },
  {
    title: "Pourquoi donner mon adresse mail ?",
    content: `Nous utilisons uniquement votre adresse mail pour la création de votre compte et pour communiquer avec vous.`,
  },
  {
    title: "Que fais sweesher de vos données personnelles ?",
    content: `Vos informations sont uniquement nécessaires à la création des devis, Sweesher ne revend en aucun cas vos données.`,
  },
  {
    title: "Peut-on souscrire à une offre via Sweesher ?",
    content: `Bien sûr ! Le principe même de Sweesher est de vous aider à trouver et à souscrire facilement aux offres les plus adaptées à votre situation. Nous travaillons uniquement avec des partenaires qui ont la même vision et technologie que nous afin de vous faciliter le plus possible cette tâche !`,
  },
];

export default function Index() {
  const [open, setOpen] = useState(0);
  return (
    <div className={style.index} id="faq-section">
      <h1>Vous avez des questions ?</h1>
      <p>Jettez un oeil à notre FAQ</p>

      <div aria-label="FAQ-container">
        {FAQ.map(({ content, title }, i) => {
          return (
            <ListItem
              key={`item-${i}`}
              content={content}
              title={title}
              index={i + 1}
              open={open}
              setOpen={setOpen}
            />
          );
        })}
      </div>
    </div>
  );
}

const ListItem = ({ open, index, setOpen, title, content }) => {
  const onclickHandler = () => {
    if (index === open) setOpen(0);
    else setOpen(index);
  };
  return (
    <div className={style.faq_question}>
      <h3 onClick={onclickHandler}>
        {title}
        {open === index ? (
          <Icon icon="material-symbols:keyboard-double-arrow-up-rounded" />
        ) : (
          <Icon icon="material-symbols:keyboard-double-arrow-down-rounded" />
        )}
      </h3>
      <Collapse in={open === index ? true : false} timeout="auto" unmountOnExit>
        <p>{content}</p>
      </Collapse>
    </div>
  );
};
