import React from "react";
import {
  Button,
  Input,
  Select,
  AutoComplete,
  Radio,
  Tooltip,
} from "antd";
import Modal from "react-modal";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Country, City } from "country-state-city";
import { useTranslation } from "react-i18next";
import { HiOutlineInformationCircle } from "react-icons/hi";
import axios from "axios";
import { useDispatch } from "react-redux";
import { homeAction } from "../../../../redux/actions";
const initPayload = {
  mainHome: true,
  room: "",
  size: "",
  dependenciesSize: null,
  occupantType: "owner",
  capitalFurniture: "",
  yearBuilt: "",
  propertyType: "Apartment",
  hasVeranda: false,
  hasSwimmingPool: false,
  hasTennisCourt: false,
  alreadyInsured: true,
  alreadyInhabited: true,
  hasChimney: false,
  hasFireAlarm: false,
  hasTheftAlarm: false,
  hasSolarPanels: false,
  floor: "ground",
  email: "no-reply@sweesher.com",
  firstName: "Sweesher",
  lastName: "test",
  phoneNumber: "00351111222333",
  address: "",
  postalCode: "",
  city: "",
  country: "",
  residentType: "owner",
};

const LukoModal = ({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  customStyles,
  subtitle,
}) => {
  const { Option } = Select;
  const [lukoPayload, setLokuPayload] = useState(initPayload);
  const [requiredItem, setRequiredItem] = useState({
    email: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    address: false,
    postalCode: false,
    city: false,
    country: false,
    propertyType: false,
    requiredItem: false,
    size: false,
    room: false,
    yearBuilt: false,
    capitalFurniture: false,
  });
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onInputChange = (e) => {
    setLokuPayload({ ...lukoPayload, [e.target.name]: e.target.value });

    if (e.target.value) {
      setRequiredItem({ ...requiredItem, [e.target.name]: false });
    }
  };

  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };

  const onCountryChange = (value) => {
    setLokuPayload({ ...lukoPayload, country: JSON.parse(value).name });
  };

  const onAddressChange = (e) => {
    if (e.trim() === "") {
      setLokuPayload({
        ...lukoPayload,
        address: e,
        city: "",
        country: "",
        postalCode: "",
      });
    } else {
      setRequiredItem({ ...requiredItem, address: false });
    }
  };

  const onSelect = (data) => {
    const seletecData = options.find((el) => el.properties.label === data);
    setLokuPayload({
      ...lukoPayload,
      address: seletecData.properties.label,
      city: seletecData.properties.city,
      country: "France",
      postalCode: seletecData.properties.postcode,
    });
  };

  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const getIsoCodeOfCity = (value) => {
    const res = Country.getAllCountries().filter(
      (elem) =>
        elem.name ===
        (value === "mrh"
          ? lukoPayload.country
          : lukoPayload.secondaryHouseCountry)
    );
    if (res.length > 0) return res[0].isoCode;
  };

  const onCityChange = (value) => {
    setLokuPayload({ ...lukoPayload, city: JSON.parse(value).name });
  };
  const onChange = (event) => {
    if (event.target.checked && event.target.type !== "radio") {
      setLokuPayload({
        ...lukoPayload,
        [event.target.name]: event.target.checked,
      });
    } else {
      setLokuPayload({
        ...lukoPayload,
        [event.target.name]: event.target.value,
      });

      if (event.target.value) {
        setRequiredItem({ ...requiredItem, [event.target.name]: false });
      }
    }
  };

  const onFloorChange = (value) => {
    setLokuPayload({ ...lukoPayload, floor: value });
    setRequiredItem({ ...requiredItem, floor: false });
  };

  const onSave = (e) => {
    e.preventDefault();
    let showError = false;
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phoneNumber",
      "address",
      "size",
      "room",
      "room",
      "room",
      "yearBuilt",
      "capitalFurniture",
    ];
    const errorObj = {};
    requiredFields.forEach((key) => {
      if (!lukoPayload[key].length) {
        errorObj[key] = true;
        showError = true;
      } else errorObj[key] = false;
    });

    if (showError) {
      setRequiredItem({ ...requiredItem, ...errorObj });
      return;
    }

    if (!lukoPayload.postalCode || !lukoPayload.city || !lukoPayload.country) return;

    const payload = {
      firstName: lukoPayload.firstName,
      lastName: lukoPayload.lastName,
      email: lukoPayload.email,
      phone_number: lukoPayload.phoneNumber,
      address: lukoPayload.address,
      postCode: lukoPayload.postalCode,
      city: lukoPayload.city,
      country: lukoPayload.country,
      propertyType: lukoPayload.propertyType,
      size: lukoPayload.size,
      room: lukoPayload.room,
      floor: lukoPayload.floor,
      mainHome: true,
      alreadyInsured: lukoPayload.alreadyInsured,
      capitalFurniture: lukoPayload.capitalFurniture,
      yearBuilt: lukoPayload.yearBuilt,
      occupantType: lukoPayload.occupantType,
      dependenciesSize: null,
      hasVeranda: false,
      hasSwimmingPool: false,
      hasTennisCourt: false,
      alreadyInhabited: true,
      hasChimney: false,
      hasFireAlarm: false,
      hasTheftAlarm: false,
      hasSolarPanels: false,
    };
    localStorage.setItem("SET-MRH-PAYLOAD", JSON.stringify(payload));

    setLokuPayload({...initPayload});
    dispatch(homeAction.getLukoMRH(payload));
    closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div
        style={{ justifyContent: "space-between" }}
        className="flexCenter cardModalHead"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{t("Insurance")}</h2>
        <button onClick={closeModal}>
          <CloseOutlined />
        </button>
      </div>
      {/* <div>I am a modal</div> */}
      <div className="cardModalContent">
        <form onSubmit={onSave}>
          {/* <div className="FlexInput">
            <div>
              <Input
                placeholder="First Name"
                name="firstName"
                value={lukoPayload.firstName}
                onChange={onInputChange}
              />
              {requiredItem.firstName && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
            <div>
              <Input
                placeholder="Last Name"
                name="lastName"
                value={lukoPayload.lastName}
                onChange={onInputChange}
              />
              {requiredItem.lastName && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
          </div>
          <div className="FlexInput">
            <div>
              <Input
                placeholder="Email"
                name="email"
                value={lukoPayload.email}
                onChange={onInputChange}
              />
              {requiredItem.email && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
            <div>
              <Input
                placeholder="Phone Number"
                name="phoneNumber"
                value={lukoPayload.phoneNumber}
                onChange={onInputChange}
              />
              {requiredItem.phoneNumber && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
          </div> */}
          <div className="FlexInput">
            <div style={{ height: '50px', width: '100%' }}>
              <AutoComplete
                defaultValue={lukoPayload.address}
                options={
                  options.length > 0
                    ? options.map((el) => ({
                      value: el.properties.label,
                      id: 1,
                    }))
                    : options
                }
                style={{ width: '100%' }}
                onChange={onAddressChange}
                onSelect={onSelect}
                onSearch={onAddressSearch}
                placeholder={t("Address")}
              />
              {requiredItem.address && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
              {
                (!lukoPayload.postalCode ||
                  !lukoPayload.city ||
                  !lukoPayload.country) ? (
                  <p style={{ color: "red" }}>
                    {t("Please select the corract address")}
                  </p>
                ) : ""}
            </div>
            <div className="prefInput" style={{ height: '50px' }}>
              <Select
                showSearch
                allowClear
                disabled
                style={{ width: '100%', borderRadius: 10 }}
                placeholder={t("Select a Country")}
                optionFilterProp="children"
                value={lukoPayload.country ? lukoPayload.country : undefined}
                onChange={onCountryChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {Country.getAllCountries().map((el, index) => {
                  return (
                    <Option value={JSON.stringify(el)} id={index}>
                      {el.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="FlexInput">
            <div className="prefInput">
              <Select
                showSearch
                allowClear
                disabled
                style={{ width: '100%', borderRadius: 10 }}
                placeholder={t("Select a City")}
                optionFilterProp="children"
                value={lukoPayload.city ? lukoPayload.city : undefined}
                onChange={onCityChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {City.getCitiesOfCountry(getIsoCodeOfCity("mrh")).map(
                  (el, index) => {
                    return (
                      <Option value={JSON.stringify(el)} id={index}>
                        {el.name}
                      </Option>
                    );
                  }
                )}
              </Select>
            </div>
            <div className="prefInput">
              <Input
                disabled
                placeholder={t("Postal Code")}
                name="postalCode"
                value={lukoPayload.postalCode}
                onChange={onInputChange}
                type="text"
                required
              />
            </div>
          </div>
          <div className="FlexInput">
            <div className="prefInput">
              <h4 className="TitleHeader">{t("Principal Residence")}</h4>
              <div className="prefInput">
                <Radio.Group
                  onChange={onChange}
                  name="propertyType"
                  value={lukoPayload.propertyType}
                >
                  <Radio type="radio" value={"Apartment"}>
                    {t("Apartment")}
                  </Radio>
                  <Radio type="radio" value={"House"}>
                    {t("House")}
                  </Radio>
                </Radio.Group>
                {requiredItem.propertyType ? (
                  <p style={{ color: "red" }}>Please select one</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="FlexInput">
            <div className="prefInput" >
              <Input
                placeholder={t("Size m²")}
                name="size"
                value={lukoPayload.size}
                onChange={onChange}
                type="number"
                required
              />
              {requiredItem.size && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
            <div style={{ display: "flex", gridGap: 10, alignItems: 'center', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <Input
                  placeholder={t("Room")}
                  type="number"
                  name="room"
                  value={lukoPayload.room}
                  onChange={onChange}
                  required
                />

                {requiredItem.room && (
                  <p style={{ color: "red" }}>{t("Please fill this field")}</p>
                )}
              </div>
              <Tooltip
                placement="top"
                color={"#1990ff"}
                title={t("roomInfoText")}
              >
                <HiOutlineInformationCircle color="red" size={20} />
              </Tooltip>
            </div>
          </div>
          <div className="FlexInput">
            <Select
              showSearch
              disabled={lukoPayload.propertyType === "House"}
              style={{ width: '100%', borderRadius: 10 }}
              placeholder={t("Floor")}
              optionFilterProp="children"
              value={lukoPayload.floor ? lukoPayload.floor : undefined}
              onChange={onFloorChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value={"top"}>{t("Top")}</Option>
              <Option value={"middle"}>{t("Middle")}</Option>
              <Option value={"ground"}>{t("Ground")}</Option>
            </Select>
            {requiredItem.floor && lukoPayload.houseType !== "House" ? (
              <p style={{ color: "red" }}>Please select one</p>
            ) : (
              ""
            )}
            <div style={{ width: '100%' }}>
              <Input
                type="number"
                name="capitalFurniture"
                value={lukoPayload.capitalFurniture}
                onChange={onInputChange}
                placeholder={t("Capital Amount")}
              />
              {requiredItem.capitalFurniture && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
            {/* <div className="prefModelInputs">
              <p className="bottomCheckboxTitle">{t("Already Insured")} ?</p>

              <Radio.Group
                onChange={onChange}
                name="alreadyInsured"
                value={lukoPayload.alreadyInsured}
              >
                <Radio type="radio" value={true}>
                  {t("Yes")}
                </Radio>
                <Radio type="radio" value={false}>
                  {t("No")}
                </Radio>
              </Radio.Group>
              {requiredItem.alreadyInsured ? (
                <p style={{ color: "red" }}>Please select one</p>
              ) : (
                ""
              )}
            </div> */}
          </div>
          <div className="FlexInput">
            <div style={{ height: '50px', width: '100%' }}>
              <Input
                placeholder={t("Built Year")}
                type="number"
                value={lukoPayload.yearBuilt}
                name="yearBuilt"
                onChange={onChange}
                required
              />
              {requiredItem.yearBuilt && (
                <p style={{ color: "red" }}>{t("Please fill this field")}</p>
              )}
            </div>
            <div className="prefInput" style={{width: '100%'}}>
              <Radio.Group
                onChange={onChange}
                name="occupantType"
                value={lukoPayload.occupantType}
              >
                <Radio type="radio" value={"owner"}>
                  {t("Owner")}
                </Radio>
                <Radio type="radio" value={"tenant"}>
                  {t("Tenant")}
                </Radio>
              </Radio.Group>
              {requiredItem.owner ? (
                <p style={{ color: "red" }}>Please select one</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="FlexInput">

          </div>
          <div
            // onClick={closeModal}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Button
              style={{
                background: "#1890ff",
                color: "white",
                borderRadius: 8,
                height: 35,
                padding: "0px 10px",
              }}
              type="submit"
              onClick={onSave}
            >
              {t("Submit")}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default LukoModal;
