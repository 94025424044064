import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { notifyWarn } from '../../components/Toast';
import LoginBanner from './LoginBanner';
import { userAction } from '../../redux/actions';
import { Spin } from 'antd';
import { TiTick } from 'react-icons/ti'
import { useTranslation } from "react-i18next";


// const SECOND = 1000;
// const MINUTE = SECOND * 60;

const Authentication = () => {
  const { t } = useTranslation()
  const [otp, setOtp] = useState('');
  const [[mins, secs], setCountdown] = useState([2, 0]);


  useEffect(() => {
    let timerInterval = setInterval(() => {
      if (mins === 0 && secs === 0) {
        clearInterval(timerInterval);
      } else if (secs === 0) {
        setCountdown([mins - 1, 60]);
      } else {
        setCountdown([mins, secs - 1]);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [mins, secs]);


  const loginData = useSelector((state) => state.users.loginData);
  const dispatch = useDispatch();

  const handleChange = (otp) => setOtp(otp);

  const onResend = () => {
    if (!loginData.email) return notifyWarn("kindly go back and input email!");
    setCountdown([2, 0])
    dispatch(userAction.sendOtpMail(loginData.email));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!loginData.optEmailId) return;
    dispatch(userAction.verifyOTP(loginData.optEmailId, otp));
    setisLoading(true)
  }

  const [isLoading, setisLoading] = useState(false)
  return (
    <div className="login">
      <LoginBanner />
      <div className="verification-section enter-email">
        <div className="inner-container">
          <div className="back-to-email">
            <Link to="/">
              <FontAwesomeIcon
                className="back-icon"
                icon={faArrowLeftLong}
              ></FontAwesomeIcon>
            </Link>
            <span>OTP Verification</span>
          </div>
          <p className='authGreenTick'>
            {/* <img src={Tick} alt="" /> */}
            <TiTick />
          </p>
          <h2>{t("We sent your confirmation code to your email address")}</h2>
          <p className="resend-time">
            {t("Enter this code before it expires in")} <span>{mins} min : {secs < 10 ? `0${secs}` : secs}s</span>
          </p>
          <form onSubmit={onSubmit}>
            <div className="verify-input">
              <OtpInput
                value={otp}
                onChange={handleChange}
                isInputNum
                numInputs={6}
                className="otpInput"
                style={{ justifyContent: 'spaceBeteen' }}
              />
            </div>
            <p className="resend-time">
              {t("Didn’t receive a code?")}{' '}
              <span onClick={onResend} className="resend-btn">
                {t("Resend")}
              </span>
            </p>
            {isLoading ? <Spin />
              :
              <button type="submit" className="login-continue">
                {t("Continue")}
              </button>
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default Authentication;
