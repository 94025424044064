import React, { useState } from "react";
import style from "../../assets/scss/layouts/item.list.module.scss";
import { Icon } from "@iconify/react";
import { Collapse } from "@mui/material";

function SubscriptionListItem({ title, subtitle, children, setToOpen }) {
  const [open, setOpen] = useState(setToOpen ? true : false);
  return (
    <div className={style.index}>
      <div className={style.title}>
        <div data-style="title">
          <h3>{title}</h3>
          <i>{subtitle}</i>
        </div>

        {open ? (
          <Icon onClick={() => setOpen(false)} icon="ph:caret-double-up-bold" />
        ) : (
          <Icon
            onClick={() => setOpen(true)}
            icon="ph:caret-double-down-bold"
          />
        )}
      </div>
      <Collapse timeout="auto" in={open}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
}

export default SubscriptionListItem;
