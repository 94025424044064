import ConnectyCube from "connectycube";
import crypto from "crypto";

const generateMD5String = (id) => {
  return crypto.createHash("md5").update(id).digest("hex");
};
export const makeChanelName = (firstUserId, secondUserId) =>
  firstUserId + "-" + secondUserId;

export const formateUser = async (user) => {
  const { email, serviceId } = user;
  const hash = await generateMD5String(serviceId);
  const formatedUser = {
    login: email,
    password: hash,
    email,
  };
  return formatedUser;
};

export const InitConnectuCube = async () => {
  const credentials = {
    appId: process.env.REACT_APP_CC_APPLICATION_ID,
    authKey: process.env.REACT_APP_CC_AUTH_KEY,
    authSecret: process.env.REACT_APP_CC_SECRET_KEY,
  };

  const config = {
    debug: { mode: 1 },
  };

  await ConnectyCube.init(credentials, config);
};

export const signupInConnectyCube = async (user) => {
  return new Promise(async (resolve, reject) => {
    const userForConnectyCube = await formateUser(user);
    try {
      const user = await connectConnectTube(userForConnectyCube);
      resolve(user);
    } catch (error) {
      if (error.code && error.code == 404) {
        await ConnectyCube.createSession();
        const { user } = await ConnectyCube.users.signup(userForConnectyCube);
        resolve(user);
      } else {
        reject(error);
      }
    }
  });
};

// For Sinup Only
export const signupConnecty = (user) => {
  return new Promise(async (resolve, reject) => {
    const userForConnectyCube = await formateUser(user);
    try {
      await ConnectyCube.createSession();
      const { user } = await ConnectyCube.users.signup(userForConnectyCube);
      resolve(user);
    } catch (error) {
      reject(error);
    }
  });
};

export const connectConnectTube = (userCredentials) => {
  return new Promise(async (resolved, reject) => {
    try {
      const session = await ConnectyCube.createSession(userCredentials);
      await connect(session.id, userCredentials.password);
      resolved(session.user);
    } catch (error) {
      reject(error);
    }
  });
};

const connect = async (userId, password) => {
  try {
    await ConnectyCube.chat.connect({ userId, password });
  } catch (error) {
    throw error;
  }
};

export function getCurrentTime() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}`;
  return formattedTime;
}

export const getConnectCubeUsers = async (params) => {
  try {
    const users = await ConnectyCube.users.get(params);
    return users.user;
  } catch (error) {
    if (error.code === 404) {
      return undefined;
    } else {
      return Promise.reject();
    }
  }
};

export const createDialog = async (name, occupants_ids) => {
  try {
    const params = {
      type: 2,
      name: name,
      occupants_ids,
    };
    const dialog = await ConnectyCube.chat.dialog.create(params);
    return dialog;
  } catch (error) {
    Promise.reject();
  }
};

export const updateDialog = (dialogId, toUpdateParams) => {
  // const toUpdateParams = { occupants_ids: occupants_ids };

  ConnectyCube.chat.dialog
    .update(dialogId, toUpdateParams)
    .then((dialog) => {})
    .catch((error) => {
      Promise.reject(error);
    });
};

export const findDialog = async (params, dialogName) => {
  try {
    const filters = params;
    const { items } = await ConnectyCube.chat.dialog.list(filters);
    if (items.length) {
      const dialog = items.find((el) => el.name === dialogName);
      return dialog ? dialog : undefined;
    }
    return undefined;
  } catch (error) {
    Promise.reject();
  }
};

export const joinDialog = async (dialogId) => {
  try {
    await ConnectyCube.chat.muc.join(dialogId);
    return true;
  } catch (error) {
    Promise.reject();
  }
};

export const getMessageList = async (dialogId) => {
  try {
    const params = {
      chat_dialog_id: dialogId,
      sort_asc: "date_sent",
      limit: 100,
      skip: 0,
    };
    const { items } = await ConnectyCube.chat.message.list(params);
    return items;
  } catch (error) {
    Promise.reject();
  }
};

export const sendAttachment = (inputFile, dialog, body, onMessage, userId) => {
  const fileParams = {
    name: inputFile.name,
    file: inputFile,
    type: inputFile.type,
    size: inputFile.size,
    public: true,
  };

  const prepareMessageWithAttachmentAndSend = (file) => {
    const text = body.trim();
    const date = Math.floor(Date.now() / 1000);
    const message = {
      type: dialog.type === 3 ? "chat" : "groupchat",
      body: text,
      extension: {
        save_to_history: 1,
        dialog_id: dialog._id,
        attachments: [{ uid: file.uid, id: file.id, type: "photo" }],
        sender_id: userId,
        // date_sent: date,
      },
    };

    // send the message
    message.id = ConnectyCube.chat.send(dialog._id, message);
    ConnectyCube.chat.onMessageListener = onMessage;
  };

  ConnectyCube.storage
    .createAndUpload(fileParams)
    .then(prepareMessageWithAttachmentAndSend)
    .catch((error) => {});
};

export const sendMessage = async (msgBody, dialog, onMessage, userId) => {
  const text = msgBody.trim();
  const date = Math.floor(Date.now() / 1000);
  let msg = {
    type: dialog.type === 3 ? "chat" : "groupchat",
    body: text,
    extension: {
      save_to_history: 1,
      dialog_id: dialog._id,
      sender_id: userId,
      // date_sent: date,
    },
    // markable: 1
  };
  // const message = {
  //     type: dialog.type === 3 ? 'chat' : 'groupchat',
  //     body: msgBody,
  //     extension: {
  //         save_to_history: 1,
  //         dialog_id: dialog._id,
  //     },
  //     markable: 1,
  // };

  msg.id = ConnectyCube.chat.send(dialog._id, msg);

  ConnectyCube.chat.onMessageListener = onMessage;
};

export const getFileLink = (message) => {
  const fileUID = message.attachments[0].uid;
  const fileUrl = ConnectyCube.storage.privateUrl(fileUID);
  return fileUrl;
};
