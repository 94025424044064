import style from "./style.module.scss";
import docIcon from "../../../assets/images/svg/doc.svg";
import checkedIcon from "../../../assets/images/svg/checked.svg";
import defenceIcon from "../../../assets/images/svg/defence.svg";
import playIcon from "../../../assets/images/svg/play.svg";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";

export default function Index() {
  const isTablet = useMediaQuery({ query: "(max-width: 1450px)" });
  return (
    <div className={style.index} id="howItWorks-section">
      <h1>Comment ça marche</h1>

      <div className={style.steps}>
        <div aria-label="step">
          <img style={{ justifySelf: "flex-end" }} src={docIcon} alt="icon" />
          <Description
            content={`Inscrivez-vous sur sweesher, et 
            saisissez les informations relative
            à vos contrats`}
            num={1}
            title={`S’inscrire`}
          />
        </div>
        <div aria-label="step">
          <Description
            content={`Une fois vos informations saisies, Sweesher trouve les offres les
              plus adaptés à votre situation`}
            num={2}
            title={`Comparer`}
            right={true}
          />
          <img src={defenceIcon} alt="icon" />
        </div>

        <div aria-label="step">
          <img
            style={{ justifySelf: "flex-end" }}
            src={checkedIcon}
            alt="icon"
          />
          <Description
            content={`Souscrivez aux offres qui vous plaisent en toute simplicité, fini la paperasse, fini les formulaires interminables !`}
            num={3}
            title={`Souscrire`}
          />
        </div>
      </div>

      <div className={style.presentation}>
        <div aria-label="action">
          <div aria-label="title">
            <img src={playIcon} alt="icon" />
            <h3>Voulez vous en savoir plus ?</h3>
          </div>
          {!isTablet && (
            <Link duration={500} smooth={true} to="simulation-section">
              Faire une simulation
            </Link>
          )}
        </div>
        <iframe
          width="679"
          height="410"
          src="https://www.youtube.com/embed/W05s5IZoXFY"
          title="Sweesher le comparateur nouvelle génération !"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        {isTablet && (
          <div aria-label="center">
            <Link duration={500} smooth={true} to="simulation-section">
              Faire une simulation
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

const Description = ({ num, title, content, right }) => {
  const rightStyle = {
    marginRight: "0",
    marginLeft: "auto",
  };
  return (
    <div>
      <h3 style={{ justifyContent: right ? "flex-end" : "unset" }}>
        <span>{num}</span> {title}
      </h3>
      <p style={right ? rightStyle : { margin: "unset" }}>{content}</p>
    </div>
  );
};
