import axios from "axios";
import { BASE_URL } from "../constant";

export default class InvoieAPI {
  static jsonify = (payload) => JSON.stringify(payload);

  static sendRequest(payload) {
    let config = {
      method: 'post',
      url: `${BASE_URL}/partnership/request`,
      headers: {
        'Content-type': 'application/json',
      },
      data: this.jsonify(payload),
    };
    return axios(config);
  }
  static postAnalyticsData(payload) {
    let config = {
      method: 'post',
      url: `${BASE_URL}/analytics`,
      headers: {
        'Content-type': 'application/json',
      },
      data: this.jsonify(payload),
    };
    return axios(config);
  }
  static getOffers() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/getOffers`,
    };
    return axios(config);
  }
  static getLukoMRH(payload) {
    let config = {
      method: 'post',
      url: `${BASE_URL}/getOffer/insurance/mrh-discover`,
      headers: {
        'Content-type': 'application/json',
      },
      data: this.jsonify(payload),
    };
    return axios(config);
  }
  static getPetHomeOffers(payload) {
    let config = {
      method: 'post',
      url: `${BASE_URL}/getOffer/insurance/pet`,
      headers: {
        'Content-type': 'application/json',
      },
      data: this.jsonify(payload),
    };
    return axios(config);
  }
 
  static getMRH() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/getOffer/insurance/mrh`,
      headers: {
        'Content-type': 'application/json',
      },
    };
    return axios(config);
  }
  static getPNO() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/getOffer/insurance/pno`,
      headers: {
        'Content-type': 'application/json',
      },
    };
    return axios(config);
  }

  static getPet() {
    let config = {
      method: 'get',
      url: `${BASE_URL}/getOffer/insurance/pets/`,
      headers: {
        'Content-type': 'application/json',
      },
    };
    return axios(config);
  }
}
