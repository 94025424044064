import NewsFeedsAPI from "../../apis/newsFeeds";
import UserAPI from "../../apis/users";
import DashboardAPI from "../../apis/dashboard";
import { userAction } from "../actions";
import { fakeMRH, fakePNO,fakePET } from "../../constant/FakeData";
import { notifyError, notifySuccess } from "../../components/Toast";

export const LOAD_LOADING = "REDUX_THUNK_LOAD_LOADING";
export const LOAD_OFFERS_LOADING = "REDUX_THUNK_LOAD_OFFERS_LOADING";
export const LOAD_SIMULATOR_OFFERS_LOADING = "REDUX_THUNK_SIMULATOR_OFFERS_LOADING";
export const LOAD_PET_HOME_OFFERS_LOADING = "REDUX_THUNK_LOAD_PET_HOME_OFFERS_LOADING";
export const LOAD_MRH_OFFERS = "REDUX_THUNK_GET_MRH";
export const LOAD_PNO_OFFERS = "REDUX_THUNK_GET_PNO";
export const LOAD_PET_OFFERS = "REDUX_THUNK_LOAD_PET_OFFERS";
export const GET_NEWS_FEEDS = "REDUX_THUNK_GET_NEWS_FEEDS";
export const SET_PREFERENCE = "REDUX_THUNK_SET_PREFERENCE";
export const SET_SELECTED_OFFER = "REDUX_THUNK_SET_SELECTED_OFFER";
export const GET_OFFERS = "REDUX_THUNK_GET_OFFERS_DATA";
export const GET_MRH = "REDUX_THUNK_GET_MRH_DATA";
export const GET_PNO = "REDUX_THUNK_GET_PNO_DATA";
export const GET_PET_OFFERS = "REDUX_THUNK_GET_PET_OFFERS_DATA";
export const LOAD_ERROR = "REDUX_THUNK_LOAD_ERROR";
export const GET_OFFERS_LUKO_MRH = "REDUX_THUNK_GET_OFFERS_LUKO_MRH";
export const GET_PET_HOME_OFFERS = "REDUX_THUNK_GET_PET_HOME_OFFERS";
export const GET_SIMULATOR_PET_OFFERS = "REDUX_THUNK_GET_PET_HOME_OFFERS";
export const SET_TAB = "SET_TAB";
export const FINISH_LOAD = "FINISH_LOAD";
export const RESET_ALL = "RESET_ALL";
export const TARGET_UPDATE = "TARGET_UPDATE";

export const POST_ANALYTICS_DATA_REQUEST =
  'REDUX_THUNK_POST_ANALYTICS_DATA_REQUEST';
export const POST_ANALYTICS_DATA_SUCCESS =
  'REDUX_THUNK_POST_ANALYTICS_DATA_SUCCESS';
export const POST_ANALYTICS_DATA_FAILURE =
  'REDUX_THUNK_POST_ANALYTICS_DATA_FAILURE';


export const getNewsFeeds = () => (dispatch) => {
  dispatch({ type: LOAD_LOADING });
  NewsFeedsAPI.getNewsFeeds()
    .then((response) => {
      const { data } = response;
      dispatch({ type: GET_NEWS_FEEDS, data: data.data });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};

export const updateTarget = (target) => (dispatch) => {
  dispatch({ type: TARGET_UPDATE, data: target });
};

export const resetAll = () => (dispatch) => {
  dispatch({ type: RESET_ALL });
};

export const setPreference = (payload, navigate, isNew) => (dispatch) => {
  dispatch({ type: LOAD_LOADING });
  UserAPI.updateProfiles(payload)
    .then((response) => {
      if (!isNew) navigate("home");
      dispatch(userAction.getLoginUser());
      dispatch({ type: SET_PREFERENCE });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};
export const setTab = (tab) => (dispatch) => {
  dispatch({ type: SET_TAB, tab });
};
export const setClickCounter = (analyticsData) => (dispatch) => {
  dispatch({ type: POST_ANALYTICS_DATA_REQUEST });
  DashboardAPI.postAnalyticsData(analyticsData)
    .then(({ data }) => {
      dispatch({ type: POST_ANALYTICS_DATA_SUCCESS, data });
    })
    .catch((error) => {
      dispatch({
        type: POST_ANALYTICS_DATA_FAILURE,
        error: error.message || 'Unexpected Error!!!',
      });
    });
};
export const setSelectedOffer =
  (offer = [], selectedOffer, filterProductByThisCat) =>
  (dispatch) => {
    dispatch({ type: LOAD_LOADING });

    let newOffers = [];
    if (selectedOffer === "All") newOffers = JSON.parse(JSON.stringify(offer));
    else {
      newOffers = offer.filter(
        (el) => el.category.toLowerCase() === selectedOffer.toLowerCase()
      );
      if (filterProductByThisCat === "home")
        newOffers[0].products = newOffers[0].products.filter(
          (el) => el.name !== "car"
        );
      if (filterProductByThisCat === "car")
        newOffers[0].products = newOffers[0].products.filter(
          (el) => el.name === "car"
        );
    }

    dispatch({
      type: SET_SELECTED_OFFER,
      data: { offersCopy: newOffers, selectedOffer },
    });
  };

export const setFilteredOffer =
  (offer = [], selectedOffer) =>
  (dispatch) => {
    dispatch({ type: LOAD_LOADING });
    let newOffers = [];
    if (selectedOffer === "All") newOffers = JSON.parse(JSON.stringify(offer));
    else {
      newOffers = JSON.parse(JSON.stringify(offer)).map((elem) => {
        elem.products = elem.products.filter(
          (el) => el.name.toLowerCase() === selectedOffer.toLowerCase()
        );
        return elem;
      });
    }
    dispatch({
      type: SET_SELECTED_OFFER,
      data: { offersCopy: newOffers, selectedOffer },
    });
  };
export const sendEmailRequest = (data) => async (dispatch) => {
  try {
    const result = await DashboardAPI.sendRequest(data);
    if (result.data.status === "success") {
      notifySuccess("message envoyé avec succès !");
    }
  } catch (error) {
    notifyError(error.message);
  }
};
export const getOffers = () => (dispatch) => {
  dispatch({ type: LOAD_OFFERS_LOADING });
  DashboardAPI.getOffers()
    .then(({ data }) => {
      dispatch({ type: GET_OFFERS, data: data.data });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};
export const getMrhOffers = () => (dispatch) => {
  dispatch({ type: LOAD_MRH_OFFERS });
  DashboardAPI.getMRH()
    .then(({ data }) => {
      if (data.data[0]?.products[0])
        dispatch({
          type: GET_MRH,
          data: data.data[0]?.products[0],
        });
      else dispatch({ type: GET_MRH, data: fakeMRH });
    })
    .catch((error) => {
      dispatch({
        type: GET_MRH,
        error: error.message || "Unexpected Error!!!",
        data: fakeMRH,
      });
    });
};
export const getPnoOffers = () => (dispatch) => {
  dispatch({ type: LOAD_PNO_OFFERS });
  DashboardAPI.getPNO()
    .then(({ data }) => {
      if (data.data[0]?.products[0])
        dispatch({
          type: GET_PNO,
          data: data.data[0]?.products[0],
        });
      else dispatch({ type: GET_PNO, data: fakePNO });
    })
    .catch((error) => {
      dispatch({
        type: GET_PNO,
        error: error.message || "Unexpected Error!!!",
        data: fakePNO,
      });
    });
};
export const getPetOffers = () => (dispatch) => {
  dispatch({ type: LOAD_PET_OFFERS });
  DashboardAPI.getPet()
    .then(({ data }) => {
      if (data?.data)
        dispatch({
          type: GET_PET_OFFERS,
          data: data.data,
        });
      else dispatch({ type: GET_PET_OFFERS, data: fakePET });
    })
    .catch((error) => {
      dispatch({
        type: GET_PET_OFFERS,
        error: error.message || "Unexpected Error!!!",
        data: fakePNO,
      });
    });
};

export const finish_load = (value) => (dispatch) => {
  dispatch({ type: FINISH_LOAD, value });
};
export const getLukoMRH = (payload) => (dispatch) => {
  dispatch({ type: LOAD_OFFERS_LOADING });
  DashboardAPI.getLukoMRH(payload)
    .then(({ data }) => {
      if (data.status === "Error") notifyError(data.message);
      dispatch({ type: GET_OFFERS_LUKO_MRH, data: data.data });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};
export const getPetHomeOffers = (payload) => (dispatch) => {
  dispatch({ type: LOAD_SIMULATOR_OFFERS_LOADING });
  DashboardAPI.getPetHomeOffers(payload)
    .then(({ data }) => {
      if (data.status === "Error") notifyError(data.message);
      dispatch({ type: GET_SIMULATOR_PET_OFFERS, data: data.data.data });
    })
    .catch((error) => {
      dispatch({
        type: LOAD_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};



export const setOnLoadLukoMRH = (payload) => (dispatch) => {
  dispatch({ type: GET_OFFERS_LUKO_MRH, data: payload });
};
