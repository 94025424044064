import { combineReducers } from 'redux';

import usersReducer from './users/users.reducer';
import homeReducer from './home/home.reducer';
// import filtersReducer from './features/filters/filtersSlice';

const rootReducer = combineReducers({
  users: usersReducer,
  home: homeReducer,

  // filters: filtersReducer,
});

export default rootReducer;
