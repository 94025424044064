import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { homeAction, userAction } from "./redux/actions";
import axios from "axios";
import LoginPage from "./pages/login";
import Authentication from "./pages/login/Authentication";
// import SelectType from './pages/preference/SelectType';
import Home from "./pages/home/Home";
import Residence from "./pages/preference/Residence";
import { userRoles } from "./constant/globals";
import SubscriptionCards from "./pages/home/SubscriptionCards";
import DiscoverSubscriptionCard from "./pages/home/DiscoverSubscriptionCard";
import PricePlans from "./pages/home/PricePlans";
import NewRequest from "./pages/moving/NewRequest";
import moment from "moment";
import Discover from "./pages/home/Discover";
import ContractPage from "./pages/contract";
import Welcome from "./pages/welcome";

function App() {
  const { user, user_session } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user_session.length) return;
    dispatch(userAction.resetPetsProfiles());
    dispatch(userAction.getLoginUser());
    dispatch(homeAction.getPnoOffers());
    dispatch(homeAction.getMrhOffers());
    dispatch(userAction.getPetsProfiles())
    dispatch(homeAction.getPetOffers());
  }, [user_session, dispatch]);

  axios.interceptors.request.use(
    (req) => {
      const endTime = moment(localStorage.getItem("SWEESHER-SESSION-TIME"));
      const startTime = moment(new Date());
      const minutsDiff = endTime.diff(startTime, "minutes");

      if (minutsDiff < 2) {
        localStorage.clear();
        dispatch(userAction.removeUser());
        return;
      }
      // Add configurations here
      req.headers["authorization"] =
        localStorage.getItem("SWEESHER-SESSION") || "";
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err && err.response.status === 401) {
        localStorage.clear();
        dispatch(userAction.removeUser());
        return Promise.reject(err);
      }

      return Promise.reject(err);
    }
  );

  return (
    <div className="App">
      {user && userRoles.includes(user.role) ? (
        <PrivateRoute />
      ) : (
        <PublicRoute />
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

function PrivateRoute() {
  return (
    <Routes>
      <Route exact path="/home" element={<Home />}></Route>
      <Route exact path="/offers" element={<SubscriptionCards />}></Route>
      <Route
        exact
        path="/discover/service/:id"
        element={<DiscoverSubscriptionCard />}
      ></Route>
      <Route exact path="/plans" element={<PricePlans />}></Route>
      <Route exact path="/newrequest" element={<NewRequest />}></Route>
      {/* <Route exact path="/preference" element={<SelectType />}></Route> */}
      <Route exact path="/residence" element={<Residence />}></Route>
      <Route exact path="/discover" element={<Discover />}></Route>
      <Route
        exact
        path="/preference/:company"
        element={<ContractPage />}
      ></Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
}

function PublicRoute() {
  return (
    <Routes>
      {/* <Route exact path="/" element={<Login />}></Route> */}
      <Route path="/login" element={<LoginPage />} />
      <Route exact path="/" element={<Welcome />} />
      <Route path="/authentication" element={<Authentication />}></Route>
      <Route path="/discover" element={<Discover />}></Route>
      <Route
        path="/discover/service/:id"
        element={<DiscoverSubscriptionCard />}
      ></Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
export default App;
