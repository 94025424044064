import axios from 'axios';
import { BASE_URL } from '../constant';

export default class AccountApi {
    static jsonify = (payload) => JSON.stringify(payload);

    static getNewsFeeds() {
        let config = {
            method: 'get',
            url: `${BASE_URL}/newsFeeds/findAll`,
        };
        return axios(config);
    }
}