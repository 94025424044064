import { Icon } from "@iconify/react";
import style from "./style.module.scss";
import bigIcon from "../../../assets/images/svg/footer-big-rounded.svg";
import smallIcon from "../../../assets/images/svg/footer-small-rounded.svg";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";

export default function Index() {
  const isTablet = useMediaQuery({ query: "(max-width: 1280px)" });
  return (
    <div className={style.index}>
      <img
        style={{ pointerEvents: "none" }}
        aria-label="big-icon"
        src={bigIcon}
        alt="bigIcon"
      />
      <img
        style={{ pointerEvents: "none" }}
        aria-label="small-icon"
        src={smallIcon}
        alt="smallIcon"
      />
      <div aria-label="footer-container">
        <div aria-label="social-media">
          {!isTablet && (
            <p>Connectez-vous avec nous sur les pages de médias sociaux.</p>
          )}
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/sweeesher?_rdc=2&_rdr"
            >
              <Icon icon="basil:facebook-solid" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/sweesherfr/"
            >
              <Icon icon="ri:instagram-fill" />
            </a>
          </div>
        </div>

        <div>
          <h3>À propos</h3>
          <ul>
            <li>
              <Link smooth={true} duration={500} to="simulation-section">
                Simulation
              </Link>
            </li>
            <li>
              <Link smooth={true} duration={500} to="howItWorks-section">
                Comment ça marche
              </Link>
            </li>
            <li>
              <Link smooth={true} duration={500} to="about-section">
                Qui sommes nous
              </Link>
            </li>
            <li>
              <Link smooth={true} duration={500} to="faq-section">
                FAQ
              </Link>
            </li>
            <li>
              <Link smooth={true} duration={500} to="partner-section">
                Partenaires
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h3>Nos services</h3>
          <ul>
            <li>Assurance habitation</li>
            <li>Assurance auto</li>
            <li>Assurance animaux</li>
          </ul>
        </div>
      </div>
      <hr />
      <div aria-label="copyright">
        <div>
          <Icon icon="mdi:dot" />
          <Icon icon="mdi:dot" />
        </div>
        <small>&copy; Plateforme - Sweesher</small>
      </div>
    </div>
  );
}
