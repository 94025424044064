import style from "./style.module.scss";
import { Carousel, Spin } from "antd";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyWarn } from "../../components/Toast";
import { userAction } from "../../redux/actions";

//img
import img1 from "../../assets/images/svg/login/img1.svg";
import img2 from "../../assets/images/svg/login/img2.svg";
import logo from "../../assets/images/svg/logo.svg";
import nextImage from "../../assets/images/svg/nextButton.svg";
import backArrow from "../../assets/images/svg/backArraw.svg";
// carousel img
import car from "../../assets/images/svg/profile/car insurance 1.svg";
import electricity from "../../assets/images/svg/profile/electricity 1.svg";
import house from "../../assets/images/svg/profile/house-logo 1.svg";
import internet from "../../assets/images/svg/profile/internet 1.svg";
import mobile from "../../assets/images/svg/profile/mobile 1.svg";
import pet from "../../assets/images/svg/profile/pet insurance 1.svg";

const imgs = [car, electricity, house, internet, mobile, pet];
export default function Index() {
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.users.loginData);
  const [isLoading, setisLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [slide, setSlide] = useState(false);
  const [[mins, secs], setCountdown] = useState([2, 0]);
  const onResend = () => {
    if (!loginData.email) return notifyWarn("kindly go back and input email!");
    setCountdown([2, 0]);
    dispatch(userAction.sendOtpMail(loginData.email));
  };
  const onSubmt = () => {
    if (!email) return notifyWarn("entez votre email svp !");
    setisLoading(true);
    localStorage.removeItem("SWEESHER-SESSION");
    localStorage.removeItem("SWEESHER-USER");
    localStorage.removeItem("SWEESHER-SESSION-TIME");
    localStorage.removeItem("persist:root");
    const onCallAction = {
      onSuccess: () => {
        setisLoading(false);
        setSlide(true);
      },
      onError: () => setisLoading(false),
    };
    dispatch(userAction.sendOtpMail(email, onCallAction));
  };
  const FinalStep = () => {
    if (!loginData.optEmailId) return;
    dispatch(userAction.verifyOTP(loginData.optEmailId, otp));
    setisLoading(true);
  };
  useEffect(() => {
    if (slide) {
      let timerInterval = setInterval(() => {
        if (mins === 0 && secs === 0) {
          clearInterval(timerInterval);
        } else if (secs === 0) {
          setCountdown([mins - 1, 60]);
        } else {
          setCountdown([mins, secs - 1]);
        }
      }, 1000);
      return () => {
        clearInterval(timerInterval);
      };
    }
  }, [mins, secs, slide]);
  const handleChange = (otp) => setOtp(otp);
  const goBack = () => setSlide(false);
  return (
    <div className={style.index}>
      <img aria-label="deco" src={img1} alt="img" />
      <img aria-label="deco" src={img2} alt="img" />

      <div className={style.content}>
        {slide && (
          <span onClick={goBack} role="button" aria-label="back-button">
            <img src={backArrow} alt="" />
            Retour
          </span>
        )}
        <img aria-label="logo" src={logo} alt="logo" />
        <div className={style.slider}>
          <div
            className={style.slideWrapper}
            style={{
              transform: slide ? "translateX(-100%)" : "translateX(0%)",
            }}
          >
            <div className={style.slide}>
              <Spin
                size="large"
                className={style.spiner}
                spinning={isLoading}
              />

              <h1>Quel est votre mail</h1>
              <div aria-label="input-groupe">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="email"
                  placeholder="Votre e-mail"
                />
                <img role="button" onClick={onSubmt} src={nextImage} alt="" />
              </div>
              <p>
                Nous allons vous envoyer un code de confirmation à cet e-mail
                pour vérifier votre identité.
              </p>
            </div>

            <div className={style.slide}>
              <h3>
                Nous avons envoyé votre code de confirmation à votre adresse
                e-mail
              </h3>
              <div aria-label="input-groupe">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  isInputNum
                  numInputs={6}
                  inputStyle={style.input_style}
                  style={{ justifyContent: "spaceBeteen" }}
                  containerStyle={style.input_number}
                />
                <img role="button" onClick={FinalStep} src={nextImage} alt="" />
              </div>
              <p>
                Entrez ce code avant qu'il n'expire dans {mins} min : {secs}s{" "}
                <br />
                Vous n'avez pas reçu de code ?{" "}
                <span role="button" onClick={onResend}>
                  Renvoyer
                </span>
              </p>
            </div>
          </div>
        </div>

        <Carousel
          dots={false}
          infinite={true}
          autoplaySpeed={5000}
          speed={1000}
          slidesToShow={5}
          slidesToScroll={1}
          adaptiveHeight={true}
          autoplay={true}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              },
            },
            {
              breakpoint: 750,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: false,
              },
            },
          ]}
        >
          {imgs.map((img) => {
            return <img src={img} alt="icon" aria-label="icon-image" />;
          })}
        </Carousel>
      </div>
    </div>
  );
}
