import React, {useEffect} from 'react'
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { homeAction } from "../../../redux/actions";
import { Spin } from "antd";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import { catBreed,dogBreed } from './breed';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { notifyWarn } from "../../../components/Toast";

const validationSchema = z.object({
    species: z.string().min(3, `le type de l'animal est obligatoire !`),
    breed: z.string().min(1, `la race de l'animal est obligatoire !`),
    petName: z.string().min(1, `le nom de l'animal est obligatoires !`),
    birthYear: z.string().min(1, `l'annèe de naissance obligatoires !`),
    birthMonth: z.string().min(1, "le mois de naissance est obligatoire"),
  });

export default function AnimalSimulatorForm({loading}) {
    const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
    
  });
  const selectedSpecy = watch("species");
    const get_offers = (data) => {
        const payload = {
            species : data.species,
            breed: data.breed,
            name:data.petName,
            birthYear: parseInt(data.birthYear),
            birthMonth: parseInt(data.birthMonth)
        };
        localStorage.setItem("PET-SIMULATOR-PAYLOAD", JSON.stringify(payload));
        dispatch(homeAction.getPetHomeOffers(payload));
      };

      useEffect(() => {
        if (Object.keys(errors).length) {
          console.log(errors);
          notifyWarn(errors[Object.keys(errors)[0]].message);
        }
      }, [errors]);
    
  return (
    <form className={style.animal_simulator_form} onSubmit={handleSubmit(get_offers)}>
        <Spin size="large" className={style.spiner} spinning={loading} />
        <div className={style.grid_container}>
            <input
                {...register("petName")}
                type="text"
                autoComplete="off"
                placeholder="Nom de l'animal"
            />
            <div data-style="group-form">
              <select {...register("species")} placeholder='Chat / chien' defaultValue="Dog">
                <option value='Dog'>Chien</option>
                <option value='Cat'>Chat</option>
              </select>
            </div>
            <input
                {...register("birthYear")}
                type="text"
                autoComplete="off"
                placeholder="Annèe de naissance"
            />
            <input
                {...register("birthMonth")}
                type="text"
                autoComplete="off"
                placeholder="Mois de naissance"
            />
            <Autocomplete
              options={!selectedSpecy || selectedSpecy === "Dog" ?  dogBreed : catBreed}
              style={{ width: '100%' }}
              className={style.grid_item}
              onChange={(e, value) => setValue('breed',value)}
              sx={{
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  border: 'none',
                  '&:focus-within': {
                    boxShadow: 'none',
                  }
                },
                '& .MuiInputBase-root': {
                  border: '0px !important',
                },
                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                  border: '0px !important',
                },
                
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "1px solid rgb(179, 179, 179)",
                borderRadius: '15px',
                
              }
              }}
              renderInput={params => (
                <TextField  placeholder="Race de l'animal" {...params} />
              )}
            />
        </div>
        <button className={style.form_button} type="submit">Voir les offres</button>
    </form>
  )
}
