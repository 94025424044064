import React from "react";
import { useParams } from "react-router-dom";
import { CarContract } from "./companys/CarContract";

const ContractPage = () => {
  let { company } = useParams();

  const switchCompanyContract = (company) => {
    switch (company.toLowerCase()) {
      case "car":
        return <CarContract />;
      default:
        return <p>Invalid Contract</p>;
    }
  };

  return <div>{switchCompanyContract(company)}</div>;
};
export default ContractPage;
