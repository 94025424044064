import React from "react";
import style from "./style.module.scss";
import Insurance from "./Insurance";

function Index({setActive}) {
  return (
    <div className={style.index}>
      <Insurance setActive={setActive} />
    </div>
  );
}

export default Index;
