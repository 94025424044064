import React from "react";
import { Tabs } from "antd";
import Subscription from "../home/Subscription";
import Profile from "../profile";
import Settings from "../settings/Settings";
import SweesherLogo from "../../assets/images/sweesherLogo.png";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import style from "../../assets/scss/layouts/header.module.scss";
import { Icon } from "@iconify/react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, userAction } from "../../redux/actions";
import { useNavigate } from "react-router-dom";

function Navigation(props) {
  const { activeTab } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { active } = useSelector((state) => ({ active: state.home.tab }));
  const { user } = useSelector((state) => state.users);
  let currentActive = active
  if (!currentActive) {
    (user?.profile?.address || user?.offerProfiles?.pno?.ownerAddress) ? currentActive = "2" : currentActive= "1"
  }
  const setActive = (key) => {
    dispatch(homeAction.setTab(key));
  };

  const items = [
    {
      key: "2",
      label: t("Home"),
      children: <Subscription />,
    },
    {
      key: "1",
      label: t("Profile"),
      children: <Profile setActive={setActive} />,
    },
    {
      key: "3",
      label: t("Settings"),
      children: <Settings />,
    },
  ];

  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });


  return (
    <div className="selectType navigation">
      <div className="invoiceModelHeader">
        <div className="Header">
          <div data-style="content">
            <img className="MainLogo" src={SweesherLogo} alt="img" />
            <h3>Sweesher</h3>
          </div>
        </div>

        <div className={style.header_actions}>
          {isDesktop ? (
            <Logout />
          ) : (
            <MobileNavBurger active={active} setActive={setActive} />
          )}
        </div>
      </div>
      <Tabs
        activeKey={currentActive}
        onChange={(key) => setActive(key)}
        defaultActiveKey={activeTab ? activeTab : "1"}
        items={items}
      />
    </div>
  );
}

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clickHandler = () => {
    logout_function(dispatch, navigate);
  };

  return (
    <div role="button" onClick={clickHandler} data-style="logout">
      <Tooltip title="Déconnexion" placement="left">
        <Icon icon="ri:logout-circle-r-line" />
      </Tooltip>
    </div>
  );
};

const MobileNavBurger = ({ active, setActive }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActive = (value) => {
    setActive(value);
    handleClose();
  };

  const logoutHandler = () => {
    logout_function(dispatch, navigate);
  };
  return (
    <div>
      <IconButton
        id="nav-mobile-link"
        aria-controls={open ? "nav-mobile-link-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Icon fontSize={32} icon="icon-park:hamburger-button" />
      </IconButton>

      <Menu
        id="nav-mobile-link-menu"
        aria-labelledby="nav-mobile-link"
        className={style.nav_mobile_menu}
        sx={{
          "& .MuiList-root": {
            padding: "0",
          },
          "& .MuiButtonBase-root": {
            color: "#000000",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "22px",
            padding: "0 12px",
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          selected={active === "2"}
          onClick={() => handleActive("2")}
        >
          {t("Home")}
        </MenuItem>
        <MenuItem
          selected={active === "1"}
          onClick={() => handleActive("1")}
        >
          {t("Profile")}
        </MenuItem>
        <MenuItem
          selected={active === "3"}
          onClick={() => handleActive("3")}
        >
          {t("Settings")}
        </MenuItem>
        <MenuItem sx={{ gap: 2 }} onClick={logoutHandler}>
          {t("Logout")} <Icon fontSize={24} icon="mdi:shutdown" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Navigation;

const logout_function = (dispatch, navigate) => {
  dispatch(homeAction.finish_load(false));
  dispatch(homeAction.setTab(null));
  dispatch(userAction.resetAll());
  localStorage.removeItem("SWEESHER-SESSION");
  localStorage.removeItem("SWEESHER-USER");
  localStorage.removeItem("SWEESHER-SESSION-TIME");
  localStorage.removeItem("persist:root");
  navigate("/");
};
