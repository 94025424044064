export const fakeData = [
  {
    category: "Insurance",
    products: [
      {
        name: "pno",
        cheapest: "6.23",
        providers: [
          {
            name: "luko",
            colorCode: "#0042DA",
            iconPath: "/icons/lukoIcon.svg",
            cheapest: "6.23",
            offers: [],
          },
          {
            name: "lovys",
            colorCode: "#00D3C8",
            iconPath: "/icons/lovysIcon.png",
            cheapest: "7.01",
            offers: [],
          },
        ],
      },
      {
        name: "mrh",
        cheapest: "5.15",
        providers: [
          {
            name: "luko",
            colorCode: "#0042DA",
            iconPath: "/icons/lukoIcon.svg",
            cheapest: "5.15",
            offers: [],
          },
          {
            name: "lovys",
            colorCode: "#00D3C8",
            iconPath: "/icons/lovysIcon.png",
            cheapest: "5.51",
            offers: [],
          },
        ],
      },
    ],
    cheapest: "5.15",
    colorCode: "#167EB8",
    providerList: ["/icons/lukoIcon.svg", "/icons/lovysIcon.png"],
  },
];

export const fakePNO = {
  fake: true,
  name: "pno",
  cheapest: "6.23",
  providers: [
    {
      name: "luko",
      colorCode: "#0042DA",
      iconPath: "/icons/lukoIcon.svg",
      cheapest: "6.23",
      offers: [],
    },
    {
      name: "lovys",
      colorCode: "#00D3C8",
      iconPath: "/icons/lovysIcon.png",
      cheapest: "7.01",
      offers: [],
    },
  ],
};
export const fakePET = {
  fake: true,
  name: "pet",
  cheapest: "6.23",
  providers: [
    {
      name: "lovys",
      colorCode: "#00D3C8",
      iconPath: "/icons/lovysIcon.png",
      cheapest: "7.01",
      offers: [],
    },
  ],
};

export const fakeMRH = {
  fake: true,
  name: "mrh",
  cheapest: "5.15",
  providers: [
    {
      name: "luko",
      colorCode: "#0042DA",
      iconPath: "/icons/lukoIcon.svg",
      cheapest: "5.15",
      offers: [],
    },
    {
      name: "lovys",
      colorCode: "#00D3C8",
      iconPath: "/icons/lovysIcon.png",
      cheapest: "5.51",
      offers: [],
    },
  ],
};
