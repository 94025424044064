import { Modal } from "@mui/material";
import style from "./style.module.scss";

// icons and svg
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import {
  AdressMrh,
  LastStep,
  MobileAdressMrh,
  OccupantType,
  PropertyType,
} from "./Components";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../redux/actions";
import Loader from "../../../components/Loader";
import { useMediaQuery } from "react-responsive";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { notifyWarn } from "../../../components/Toast";

const validationSchema = z.object({
  // details
  size: z.string().min(1, "tout les champs sont obligatoire"),
  room: z.string().min(1, "tout les champs sont obligatoire"),
  capitalFurniture: z.string().min(1, "tout les champs sont obligatoire"),
  floor: z.enum(["top", "middle", "ground"]),
  // ------

  // address
  address: z.string().min(1, "tout les champs sont obligatoire"),
  postCode: z.string().min(1, "tout les champs sont obligatoire"),
  city: z.string().min(1, "tout les champs sont obligatoire"),
  country: z.string().min(1).default("France"),
  // -------

  // vous etes - votre logement
  occupantType: z.enum(["tenant", "owner"]),
  propertyType: z.enum(["House", "Apartment"]),
  // --------------------------
});

function Addmrh({ open, handleClose }) {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      country: "France",
      occupantType: "tenant",
      propertyType: "House",
    });
  }, [reset]);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 790px)" });
  const { mrhDataLoading } = useSelector((state) => state.home);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const goNext = () => {
    setStep((prev) => prev + 1);
  };
  const goBack = () => {
    setStep((prev) => prev - 1);
  };

  const occupantTypeHandler = (value) => {
    setValue("occupantType", value);
  };

  const propertyTypeHandler = (value) => {
    setValue("propertyType", value);
  };

  useEffect(() => {
    if (mrhDataLoading && open) {
      setLoading(false);
      handleClose();
    }
  }, [mrhDataLoading, handleClose, open]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      notifyWarn(errors[Object.keys(errors)[0]].message);
    }
  }, [errors]);

  const onSubmitHandler = async (value) => {
    const data = {
      ...value,
      mainHome: true,
      floor: value.propertyType !== "House" ? value.floor : null,
    };
    delete data.postCode;
    delete data.city;
    delete data.country;
    delete data.address;
    const payload = {
      profile: {
        postCode: value.postCode,
        city: value.city,
        country: value.country,
        address: value.address,
      },
    };
    dispatch(userAction.updateMrhUser(data, payload));
    setLoading(true);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={style.index}>
        <Loader loading={loading}>
          {isMobile ? (
            <MobileForm
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              onSubmitHandler={onSubmitHandler}
              propertyTypeHandler={propertyTypeHandler}
              occupantTypeHandler={occupantTypeHandler}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          ) : (
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Icon
                onClick={handleClose}
                data-style="exit"
                icon="basil:cross-outline"
              />
              <OccupantType
                step={step}
                page={1}
                goNext={goNext}
                register={register}
                occupantTypeHandler={occupantTypeHandler}
              />
              <PropertyType
                goBack={goBack}
                goNext={goNext}
                register={register}
                propertyTypeHandler={propertyTypeHandler}
                step={step}
                page={2}
              />
              <AdressMrh
                register={register}
                setValue={setValue}
                goBack={goBack}
                goNext={goNext}
                watch={watch}
                step={step}
                page={3}
              />
              <LastStep
                goBack={goBack}
                watch={watch}
                setStep={setStep}
                register={register}
                step={step}
                page={4}
              />
            </form>
          )}
        </Loader>
      </div>
    </Modal>
  );
}

const MobileForm = ({
  handleSubmit,
  onSubmitHandler,
  handleClose,
  propertyTypeHandler,
  occupantTypeHandler,
  register,
  setValue,
  watch,
}) => {
  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className={style.mobile_form}
      style={{ margin: 0 }}
    >
      <h3>
        Vous êtes :
        <Icon fontSize={26} onClick={handleClose} icon="basil:cross-outline" />
      </h3>

      <div data-style="2/2">
        <div
          onClick={() => occupantTypeHandler("owner")}
          role="button"
          data-style="checkbox"
        >
          <label>Propriétaire</label>
          <input type="radio" value="owner" {...register("occupantType")} />
          <div></div>
        </div>

        <div
          onClick={() => occupantTypeHandler("tenant")}
          role="button"
          data-style="checkbox"
        >
          <label>Locataire</label>
          <input type="radio" value="tenant" {...register("occupantType")} />
          <div></div>
        </div>
      </div>

      <h3>Votre logement :</h3>

      <div data-style="2/2">
        <div
          onClick={() => propertyTypeHandler("House")}
          role="button"
          data-style="checkbox"
        >
          <label>Maison</label>
          <input type="radio" value="House" {...register("propertyType")} />
          <div></div>
        </div>

        <div
          onClick={() => propertyTypeHandler("Apartment")}
          role="button"
          data-style="checkbox"
        >
          <label>Appartement</label>
          <input type="radio" value="Apartment" {...register("propertyType")} />
          <div></div>
        </div>
      </div>

      <MobileAdressMrh register={register} setValue={setValue} />
      <div data-style="form">
        <div data-style="1-1/4">
          <input
            type="number"
            placeholder="Nombre de pièces"
            {...register("room")}
          />
          <input type="number" placeholder="m²" {...register("size")} />
        </div>

        <input
          type="number"
          placeholder="Capital mobilier"
          {...register("capitalFurniture")}
        />

        <select
          {...register("floor")}
          style={{
            display: watch("propertyType") === "Apartment" ? "block" : "none",
          }}
        >
          <option value="top">Dernière étage</option>
          <option value="middle">Étage intermédiaire</option>
          <option value="ground">Rez de chaussée</option>
        </select>
      </div>
      <button type="submit">Valider</button>
    </form>
  );
};

export default Addmrh;
