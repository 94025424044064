import React from 'react'
import { useLocation } from 'react-router-dom';
import Navigation from '../Header/Navigation';


function Home() {
    const location = useLocation()

    return <div className="mainContainer">
        <div className="innerContainer">
            <Navigation activeTab={location.state ? location.state : "2"} />
        </div>
    </div>

}

export default Home