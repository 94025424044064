import React from 'react'
import SweesherLogo from '../../assets/images/sweesherLogo.png'
import { BsArrowLeft } from 'react-icons/bs';

function Residence() {
    return (
        <div className='selectType'>
            <div className="invoiceModelHeader mb-15">
                <img src={SweesherLogo} alt="img" />
                <h3>Sweesher</h3>
            </div>
            <div className="Residence">
                <BsArrowLeft className="back-icon" />
                
            </div>
        </div>
    )
}

export default Residence