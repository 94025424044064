export const catBreed = [
'Abyssin',
'Égéen',
'Bobtail Américain',
'American Curl',
'American Shorthair',
'American Wirehair',
'Anatoli',
'Géant Aphrodite',
'Mau arabe',
'Tiffany Américain',
'Asian',
'Australian Mist',
'Balinais',
'Bambino',
'Bengal',
'Sacré de Birmanie',
'Bombay',
'Brazilian Shorthair',
'British Longhair',
'British Shorthair',
'Burmese',
'Burmilla',
'California Spangled',
'Californian rex',
'Californian spangled',
'Ceylan',
'Chantilly-Tiffany',
'Chartreux',
'Chausie',
'Colourpoint Shorthair',
'Cornish rex',
'Croisé',
'Cymric',
'Cyprus',
'Devon Rex',
'Donskoy',
'Dragon Li',
'Dwelf',
'Mau Egyptien',
'European Shorthair',
'Exotic Shorthair',
'Foldex',
'German Rex',
'Havana Brown',
'Highland Fold',
'Highlander',
'Himalayen',
'Bobtail japonais',
'Javanais',
'Khao Manee',
'Korat',
'Korean Bobtail',
'Korn Ja',
'Bobtail des Kouriles',
'LaPerm',
'Lykoi ',
'Maine Coon',
'Mandarin Manx',
'Mekong Bobtail',
'Minskin',
'Munchkin',
'Napoleon',
'Nebelung',
'Neva Masquerade',
'Norvégien',
'Ocicat',
'Ojos Azules',
'Oregon Rex',
'Oriental Bicolor',
'Oriental Shorthair',
'Autre',
'Persan',
'Peterbald',
'Pixie bob',
'Raas',
'Ragamuffin',
'Ragdoll',
'Russe Noir',
'Russe Bleu',
'Russian Tabby',
'Russe Blanc',
'Safari',
'Sam sawet',
'Savannah',
'Scottish Fold',
'Selkirk Rex',
'Serengeti',
'Serrade Petit',
'Siamois',
'Sibérien',
'Singapura',
'Skookum',
'Snowshoe',
'Sokoké',
'Somali',
'Sphynx',
'Suphalak',
'Seychellois',
'Thaï',
'Thai Lilac',
'Tiffany',
'Tonkinois',
'Toyger',
'Angora turc',
'Turc de Van',
'Ukrainian Levkoy',
'Ural rex',
'Wichien Maat',
'Wila Krungthep',
'York chocolat'
]
export const dogBreed = [
'Afador',
'Affenpinscher',
'Lévrier Afghan',
'Airedale Terrier',
'Akbash',
'Akita',
'Alaskan Klee Kai',
"Malamute de l'Alaska",
'Altdeuscher Shaferhund',
'American bully',
'Bouledogue Américain',
'Coonhound noir et feu',
'Esquimau américain',
'Foxhound Américain',
'Léopard américain',
'American Pit Bull Terrier',
'American Pugabull',
'American Staffordshire Terrier',
"Chien d'eau américain",
"Berger d'Anatolie",
"Bouvier d'Appenzell",
'Auggie',
'Aussiedoodle',
'Aussiepom',
'Bouvier Australien',
'Kelpie Australien',
'Retriever Australien',
'Berger Australien',
'Berger Husky Australien',
'Berger Australien Lab Mix',
'Berger Australien Pit Bull Mix',
'Terrier Australien',
'Azawakh',
'Barbet',
'Basenji',
'Bassador',
'Basset fauve de Bretagne',
'Basset hound',
'Basset Retriever',
'Chien de Rouge de Bavière',
'Beabull',
'Beagle',
'Beaglier',
'Colley Barbu',
'Bedlington Terrier',
'Belgian Malinois',
'Chien de Berger Belge',
'Berger Belge Tervueren',
'Berger Américain',
'Berger Picard',
'Berger de Beauce / Beauceron',
'Bernedoodle',
'Bouvier Bernois',
'Bichon Frise',
'Biewer Terrier',
'Black Mouth Cur ',
'Terrier Noir Russe',
'Coonhound noir et feu',
'Chien de Saint-Hubert',
'Blue Lacy',
'Bluetick Coonhound',
'BoShih',
'Bocker',
'Boerboel',
'Boglen Terrier',
'Bichon Bolonais',
'Borador',
'Border Collie',
'Border Sheepdog',
'Border Terrier',
'Bordoodle',
'Barzoï',
'Bossie',
'Boston Boxer',
'Boston Terrier',
'Bouvier des Flandres',
'Boxador',
'Boxer',
'Boxerdoodle',
'Boxmatian',
'Boxweiler',
'Boykin Spaniel',
'Braque italien',
'Braque francais',
'Braque du Bourbonnais',
'Briard',
'Epagneul Breton',
'Broholmer',
'Griffon Bruxellois',
'Bugg',
'Bull Terrier',
'Bullboxer Pit',
'Bouledogue',
'Bullmastiff',
'Bullmatian',
'Cairn terrier',
'Chien de Canaan',
'Cane Corso',
'Cardigan Welsh Corgi',
'Catahoula Bouledogue',
'Chien Léopard Catahoula',
'Berger du Caucase',
'Cav-a-Jack',
'Cavachon',
'Cavador',
'Cavalier King Charles',
'Cavapoo',
'Terrier Tchèque',
'Chabrador',
'Cheagle',
'Retriever de la baie de Chesapeake',
'Chi-Poo ',
'Chi Chi',
'Chigi',
'Chihuahua',
'Chilier',
'Chien Chinois à Crête',
'Chinese Shar-Pei',
'Chinook',
'Chion',
'Chipin',
'Chiweenie',
'Chorkie',
'Chow Chow',
'Chow Shepherd',
'Chug',
'Chusky',
"Cirneco dell'Etna",
'Clumber Spaniel',
'Cockalier',
'Cockapoo',
'Cocker Spaniel',
'Colley',
'Corgi Inu',
'Corgidor',
'Corman Shepherd',
'Coton de Tulear',
'Croisé',
'Retriever à poil bouclé',
'Dachsador',
'Teckel',
'Dalmatien',
'Dandie Dinmont Terrier',
'Daniff',
"Chien d'oysel allemand",
'Doberdor',
'Doberman Pinscher',
'Dobermann',
'Docker',
'Dogue Argentin',
'Dogue de Bordeaux',
'Dorgi',
'Dorkie',
'Doxiepoo',
'Doxle',
'Epagneul de drente',
'Drever',
'Berger Hollandais',
"Cocker Anglais (Spaniel)",
'Foxhound Anglais',
'Setter Anglais',
'Springer Spaniel Anglais',
'Épagneul Toy Anglais',
"Bouvier de l'Entlebuch",
'Chien de la Serra da Estrela',
'Eurasier',
'Field Spaniel',
'Chien Finnois de Laponie',
'Spitz Finlandais ',
'Retriever à poil plat ',
'Fox Terrier',
'Bouledogue Français',
'Epagneul Français.',
'Frenchton',
'Frengle',
'Pinscher Allemand',
'Berger Allemand',
'Sheprador Allemand',
'Brachet Allemand à poil court',
'Spitz Allemand',
'Braque Allemand Drahthaar',
'Schnauzer géant',
'Terrier Irlandais Glen of Imaal (Glen of Imaal Terrier)',
'Goberian',
'Goldador',
'Golden Cocker Retriever',
'Golden Mountain',
'Golden Retriever',
'Golden Retriever Corgi',
'Golden Shepherd',
'Goldendoodle',
'Gollie',
'Setter Gordon',
'Dogue Allemand',
'Chien de Montagne des Pyrénées',
'Grand Bouvier Suisse',
'Greyhound',
'Chien courant de hamilton',
'Hanoverian Scenthound',
'Harrier',
'Bichon Havanais',
'Hokkaido',
'Horgi',
'Huskita',
'Huskydoodle',
'Ibizan Hound',
"Berger d'Islande",
'Setter irlandais rouge et blanc',
'Setter Irlandais',
'Terrier irlandais',
"Chien d'eau Irlandais",
"Lévrier Irlandais",
'Lévrier Italien',
'Jack-A-Poo',
'Jack Chi',
'Jack Russell Terrier',
"Epagneul Japonais (Tchin)",
'Spitz Japonais',
"Chien d'Ours de Carélie",
'Keeshond - Spitz-Loup',
'Terrier Kerry Blue',
'Komondor',
"Chien Hollandais de Chasse au gibier d'eau",
'Jindo Coréen Berger Hongrois Kyi-Leo',
'Lab Pointer',
'Labernese',
'Labmaraner',
'Labrabull',
'Labradane',
'Labradoodle',
'Labrador Retriever',
'Labrastaff',
'Labsky',
"Chien d'eau Romagnol",
'Lakeland Terrier',
'Lancashire Heeler',
'Chien de Leonberg',
'Lhassa Apso',
'Lhasapoo',
'Petit Chien Lion',
'Bichon Maltais',
'Shih Tzu Maltais',
'Maltipoo',
'Terrier de Manchester',
'Mastador',
'Mastiff',
'Pinscher Nain',
'Schnauzer Nain',
'Morkie',
'Mudi',
'Mutt',
'Mâtin Napolitain',
'Terre Neuve',
'Terrier de Norfolk',
'Buhund Norvégien',
"Chien d'Elan Norvégien gris",
'Chien Norvégien de Macareux',
'Terrier de norwich',
'Retriever de la Nouvelle Ecosse (Nova-Scotia)',
'Old English Bulldog',
'Bobtail',
'Autre',
'Chien a loutre',
'Epagneul Nain Continental',
'Papipoo',
'Peekapoo',
'Epagneul pekinois',
'Pembroke Welsh Corgi',
'Petit Basset Griffon Vendeen',
'Chien du Pharaon',
'Pitsky',
'Plott',
'Pocket Beagle',
"Chien d'eau américain",
'Nizinny',
'Pomapoo',
'Pomchi',
'Pomeagle Pomeranian',
'Pomsky Poochon',
'Caniche',
'Chien de Garenne Portugais (Podenco)',
"Chien d'eau Portugais",
'Carlin',
'Pugalier',
'Puggle',
'Puli',
'Berger des Pyrénées',
'Rat Terrier',
'Redbone Coonhound',
'Chien de Rhodesie a Crete Dorsale',
'Rottador',
'Rottle',
'Rottweiler',
'Saint Berdoodle',
'Saint Bernard',
'Saluki',
'Samoyède',
'Samusky',
'Schipperke',
'Schnoodle',
'Lévrier Ecossais',
'Terrier Ecossais',
'Terrier de Sealyham',
'Sheepadoodle',
'Shepsky',
'Berger des Shetland',
'Shiba Inu',
'Shichon',
'Shih-Poo',
'Shih Tzu',
'Shiranian',
'Shollie',
'Shorkie',
'Husky Sibérien',
'Silken Windhound',
'Silky Terrier',
'Skye Terrier',
'Sloughi',
'Petit Epagneul de Münster',
'Terrier irlandais a poil doux',
'Mâtin Espagnol',
'Springador',
"Chien d'arrêt Frison",
'Staffordshire Bull Terrier',
'Schnauzer moyen',
'Sussex Spaniel',
'Berger Suédois',
'Terripoo',
'Dogue du Tibet',
'Epagneul du Tibet',
'Terrier du Tibet',
'Dogue du Tibet ',
'Epagneul du Tibet ',
'Terrier du Tibet',
'Toy Fox Terrier',
'Treeing Tennessee Brindle',
' Treeing Walker Coonhound',
'Valley Bouledogue',
'Braque Hongrois',
'Braque de Weimar',
'Springer Spaniel Gallois',
'Terrier Gallois',
'West Highland White Terrier',
'Westiepoo',
'Whippet',
'Berger Blanc Suisse',
'Whoodle',
"Griffon d'arrêt à poil dur",
'Xoloitzcuintli',
'Yorkipoo',
'Yorkshire Terrier',
'griffon nivernais ','Épagneul Tibétain',
]