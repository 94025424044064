import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";
import { Collapse } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toInputDate, userAction } from "../../redux/actions";
import { notifyWarn } from "../../components/Toast";
import Loader from "../../components/Loader";
import style from "./style.module.scss";

const validationSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  // address: z.string().min(1),
  email: z.string().min(1),
  // postCode: z.string().min(1),
  // city: z.string().min(1),
  dob: z.string().optional(),
});

function UserInfo({submit,setSubmit}) {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.users);
  const {
    reset,
    register,
    getValues,
    formState: { errors,isDirty },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const [open, setOpen] = useState(true);

  const isMobile = useMediaQuery({ query: "(max-width: 1300px)" });

  const onSaveDate = (data) => {
    const payload = {
      profile: {
        email: data.email,
        dob : data.dob,
        firstName : data.firstName,
        lastName : data.lastName,
      }
    };
    dispatch(userAction.updateAccount(user?.objectId, payload));
  };

  useEffect(() => {
    if (user)
      reset({
        ...user,
        ...user.profile,
        dob: user.profile.dob
          ? toInputDate(new Date(user.profile.dob.iso))
          : "",
      });
  }, [user, reset]);

  useEffect(() => {
    if (submit) {
      if (isDirty) onSaveDate(getValues())
      setSubmit(!submit)
    }
  }, [submit,isDirty,getValues]);

  useEffect(() => {
    if (Object.keys(errors).length)
      notifyWarn("veillez remplir tout les champs !");
  }, [errors]);



  return (
    <div className={style.user_info}>
      <Loader loading={loading}>
        <div data-style="title">
          <h1>Mes information personnelles </h1>
          {open ? (
            <Icon
              onClick={() => setOpen(false)}
              icon="ph:caret-double-up-bold"
            />
          ) : (
            <Icon
              onClick={() => setOpen(true)}
              icon="ph:caret-double-down-bold"
            />
          )}
        </div>
        <Collapse in={open || !isMobile} timeout="auto" unmountOnExit>
          <form >
            <div data-style="groupe-form">
              
              <input style={{pointerEvents: 'none'}} placeholder="E-mail" {...register("email")} type="text" disabled />
            </div>

            <div data-style="groupe-form">
              <input placeholder="Nom" {...register("lastName")} type="text" />
            </div>

            <div data-style="groupe-form">
             
              <input
                placeholder="Prénom"
                {...register("firstName")}
                type="text"
              />
            </div>
            <div data-style="groupe-form">
              <div aria-label="input-wrapper">
                <Icon icon="clarity:date-solid" />
                <input {...register("dob")} placeholder="Date" type="date" />
              </div>
            </div>
          </form>
        </Collapse>
      </Loader>
    </div>
  );
}

export default UserInfo;
