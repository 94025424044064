import UsersAPI from "../../apis/users";
import { notifyError, notifySuccess } from "../../components/Toast";
import { homeAction } from "../actions";
import { getMrhOffers } from "../home/home.actions";

export const LOAD_USERS_LOADING = "REDUX_THUNK_LOAD_USERS_LOADING";
export const OTP_MAIL_SUCCESS = "REDUX_THUNK_OTP_MAIL_SUCCESS";
export const USER_LOGIN_SUCCESS = "REDUX_THUNK_LOGIN_SUCCESS";
export const USER_SESSION_SUCCESS = "REDUX_THUNK_USER_SESSION_SUCCESS";
export const LOAD_USERS_ERROR = "REDUX_THUNK_LOAD_USERS_ERROR";
export const UPDATE_ACCOUNT_SUCCESS = "REDUX_THUNK_UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_ERROR = "REDUX_THUNK_UPDATE_ACCOUNT_ERROR";
export const UPDATE_MRH = "REDUX_THUNK_UPDATE_MRH";
export const UPDATE_MRH_LOADING = "REDUX_THUNK_UPDATE_MRH_LOADING";
export const UPDATE_PNO = "REDUX_THUNK_UPDATE_PNO";
export const UPDATE_PNO_LOADING = "REDUX_THUNK_UPDATE_PNO_LOADING";
export const UPDATE_PET = "REDUX_THUNK_UPDATE_PET";
export const UPDATE_PET_LOADING = "REDUX_THUNK_UPDATE_PET_LOADING";
export const CREATE_PET = "REDUX_THUNK_CREATE_PET";
export const CREATE_PET_LOADING = "REDUX_THUNK_CREATE_PET_LOADING";
export const DELETE_PET = "REDUX_THUNK_DELETE_PET";
export const DELETE_PET_LOADING = "REDUX_THUNK_DELETE_PET_LOADING";
export const GET_PETS_PROFILES = "REDUX_THUNK_PETS_PROFILES";
export const GET_PETS_PROFILES_LOADING = "REDUX_THUNK_PETS_PROFILES_LOADING";
export const RESET_ALL = "RESET_ALL";
export const RESET_PET_PROFILES = "RESET_PET_PROFILES";

export const UPDATE_PNO_FINISH = "UPDATE_PNO_FINISH";

export const sendOtpMail =
  (email, onCallAction, navigate = false) =>
  (dispatch) => {
    dispatch({ type: LOAD_USERS_LOADING });
    UsersAPI.sendOtpMail(email)
      .then((response) => {
        const { data } = response;
        notifySuccess(data.message);
        const payload = { email, optEmailId: data.data.email_id };
        dispatch({ type: OTP_MAIL_SUCCESS, data: payload });
        if (navigate) navigate("/authentication");
        onCallAction.onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: LOAD_USERS_ERROR,
          error: error.message || "Unexpected Error!!!",
        });
        onCallAction.onError();
      });
  };

export const updateAccount = (userId, payload) => (dispatch) => {
  dispatch({ type: LOAD_USERS_LOADING });
  UsersAPI.updateUser(userId, payload)
    .then(() => {
      notifySuccess("User Update Successfully");
      dispatch(getLoginUser(true));
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_ACCOUNT_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};

var add_minutes = function (dt, minutes) {
  return new Date(dt.getTime() + minutes * 60000);
};

export const verifyOTP = (emailId, code) => (dispatch) => {
  dispatch({ type: LOAD_USERS_LOADING });
  UsersAPI.verifyOTP(emailId, code)
    .then((response) => {
      const { data } = response;
      const { session_token } = data.data;
      localStorage.setItem("SWEESHER-SESSION", session_token);
      localStorage.setItem(
        "SWEESHER-SESSION-TIME",
        add_minutes(new Date(), 60)
      );

      dispatch({ type: USER_SESSION_SUCCESS, data: session_token });
      notifySuccess(data.message);
    })
    .catch((error) => {
      dispatch({
        type: LOAD_USERS_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};

export const resetAll = () => (dispatch) => {
  dispatch({ type: RESET_ALL });
};
export const resetPetsProfiles = () => (dispatch) => {
  dispatch({ type: RESET_PET_PROFILES });
};



export const updateMrhUser = (data, payload) => async (dispatch) => {
  dispatch({ type: UPDATE_MRH_LOADING });
  try {
    if (payload) await UsersAPI.updateUser(null, payload);
    const response = await UsersAPI.updateMrh(data);
    dispatch({ type: UPDATE_MRH, data: response.data.data });
    const user = await UsersAPI.getLoginUser();
    dispatch(promiseGetLoginUser(user));
    dispatch(homeAction.getMrhOffers());
  } catch (error) {
    notifyError("Oops une erreur c'est produite pour le update du MRH!");
    dispatch({
      type: UPDATE_MRH,
      error: error.message || "Unexpected Error!!!",
      data: null,
    });
  }
};

export const updatePnoUser = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PNO_LOADING });
  try {
    await UsersAPI.updatePno(data);
    dispatch({ type: UPDATE_PNO, data: data });
    dispatch(homeAction.getPnoOffers());
  } catch (error) {
    notifyError("Oops une erreur c'est produite pour le update du pno!");
    dispatch({
      type: UPDATE_PNO,
      error: error.message || "Unexpected Error!!!",
      data: null,
    });
  }
};
export const updatePetsUser = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PET_LOADING });
  try {
    console.log("this is the profile to update", data)
    await UsersAPI.updatePets(data);
    dispatch({ type: UPDATE_PET, data: data });
    dispatch(getPetsProfiles());    
    dispatch(homeAction.getPetOffers());     
  } catch (error) {
    notifyError("Oops une erreur c'est produite pour update du profile de l'animal !");
    dispatch({
      type: UPDATE_PET,
      error: error.message || "Unexpected Error!!!",
      data: null,
    });
  }
};


export const createPetUser = (data) => async (dispatch) => {
  dispatch({ type: CREATE_PET_LOADING });
  try {
    await UsersAPI.createPet(data);
    dispatch({ type: CREATE_PET, data: data });  
    dispatch(getPetsProfiles());    
    dispatch(homeAction.getPetOffers());                          
  } catch (error) {
    notifyError("la creation du profile animale est échouée");
    dispatch({
      type: CREATE_PET,
      error: error.message || "Unexpected Error!!!",
      data: null,
    });
  }
};

export const deletePetUser = (petId) => async (dispatch) => {
  dispatch({ type: DELETE_PET_LOADING });
  try {
    await UsersAPI.deletePet(petId);
    dispatch({ type: DELETE_PET, petId: petId });  
    dispatch(getPetsProfiles());    
    dispatch(homeAction.getPetOffers());                          
  } catch (error) {
    notifyError("la supression du profile animale est échouée");
    dispatch({
      type: DELETE_PET,
      error: error.message || "Unexpected Error!!!",
      data: null,
    });
  }
};

export const getPetsProfiles = () => async (dispatch) => {
  dispatch({ type: GET_PETS_PROFILES_LOADING });
  try {
    const data = await UsersAPI.getPetsProfiles();
    dispatch({ type: GET_PETS_PROFILES, data: data });
  } catch (error) {
    dispatch({
      type: GET_PETS_PROFILES,
      error: error.message || "Unexpected Error!!!",
      data: null,
    });
  }
};

export const getLoginUser = (getMrh) => async (dispatch) => {
  dispatch({ type: LOAD_USERS_LOADING });
  UsersAPI.getLoginUser()
    .then((response) => {
      const { data } = response;
      const user = data.data;

      // Update Profile with discover payload.
      let userOfferSetting = localStorage.getItem("SET-MRH-PAYLOAD");
      let petSimulatorOffers = localStorage.getItem("PET-SIMULATOR-PAYLOAD");
      if (petSimulatorOffers) {
          dispatch({ type: CREATE_PET_LOADING });
          petSimulatorOffers = JSON.parse(petSimulatorOffers);
          UsersAPI.createPet(petSimulatorOffers).then((res) => {
            localStorage.removeItem("PET-SIMULATOR-PAYLOAD");
            dispatch({ type: CREATE_PET, data: petSimulatorOffers });   
            dispatch(getPetsProfiles());
            dispatch(homeAction.getPetOffers());
          }).catch((error) => {
            console.error("this is the error",error)
            notifyError("la creation du profile animale est échouée");
            dispatch({
              type: CREATE_PET,
              error: error.message || "Unexpected Error!!!",
              data: null,
            });
          })
      }
      if (userOfferSetting && !user?.offerProfiles?.mrh) {
        userOfferSetting = JSON.parse(userOfferSetting);
        const payload = {
          mrh: {
            size: parseInt(userOfferSetting.size),
            dependenciesSize: parseInt(userOfferSetting.dependenciesSize),
            room: parseInt(userOfferSetting.room),
            floor: userOfferSetting.floor,
            occupantType: userOfferSetting.occupantType,
            capitalFurniture: parseInt(userOfferSetting.capitalFurniture),
            yearBuilt: parseInt(userOfferSetting.yearBuilt),
            hasVeranda: userOfferSetting.hasVeranda,
            hasSwimmingPool: userOfferSetting.hasSwimmingPool,
            hasTennisCourt: userOfferSetting.hasTennisCourt,
            propertyType: userOfferSetting.propertyType,
            alreadyInsured: userOfferSetting.alreadyInsured,
            alreadyInhabited: userOfferSetting.alreadyInhabited,
            mainHome: true,
            hasChimney: userOfferSetting.hasChimney,
            hasFireAlarm: userOfferSetting.hasFireAlarm,
            hasTheftAlarm: userOfferSetting.hasTheftAlarm,
            hasSolarPanels: userOfferSetting.hasSolarPanels,
          },
          userProfile: {
            city: userOfferSetting.city,
            postCode: userOfferSetting.postCode,
            country: userOfferSetting.country,
            address: userOfferSetting.address,
            displayPicture: "",
          },
        };

        UsersAPI.updateProfiles(payload).then((res) => {
          localStorage.removeItem("SET-MRH-PAYLOAD");
          dispatch(getLoginUser(true));
        });
      } else {
        localStorage.setItem("SWEESHER-USER", JSON.stringify(user));
        dispatch({ type: USER_LOGIN_SUCCESS, data: user });
        if(getMrh) dispatch(getMrhOffers())
      }
    
      // Remove Discover Set MRH Payload.
      localStorage.removeItem("SET-MRH-PAYLOAD");
    })
    .catch((error) => {
      dispatch({
        type: LOAD_USERS_ERROR,
        error: error.message || "Unexpected Error!!!",
      });
    });
};

export const promiseGetLoginUser = (response) => (dispatch) => {
  dispatch({ type: LOAD_USERS_LOADING });

  const { data } = response;
  const user = data.data;

  // Update Profile with discover payload.
  let userOfferSetting = localStorage.getItem("SET-MRH-PAYLOAD");
  if (userOfferSetting && !user?.offerProfiles?.mrh) {
    userOfferSetting = JSON.parse(userOfferSetting);
    const payload = {
      mrh: {
        size: parseInt(userOfferSetting.size),
        dependenciesSize: parseInt(userOfferSetting.dependenciesSize),
        room: parseInt(userOfferSetting.room),
        floor: userOfferSetting.floor,
        occupantType: userOfferSetting.occupantType,
        capitalFurniture: parseInt(userOfferSetting.capitalFurniture),
        yearBuilt: parseInt(userOfferSetting.yearBuilt),
        hasVeranda: userOfferSetting.hasVeranda,
        hasSwimmingPool: userOfferSetting.hasSwimmingPool,
        hasTennisCourt: userOfferSetting.hasTennisCourt,
        propertyType: userOfferSetting.propertyType,
        alreadyInsured: userOfferSetting.alreadyInsured,
        alreadyInhabited: userOfferSetting.alreadyInhabited,
        mainHome: true,
        hasChimney: userOfferSetting.hasChimney,
        hasFireAlarm: userOfferSetting.hasFireAlarm,
        hasTheftAlarm: userOfferSetting.hasTheftAlarm,
        hasSolarPanels: userOfferSetting.hasSolarPanels,
      },
      userProfile: {
        city: userOfferSetting.city,
        postCode: userOfferSetting.postCode,
        country: userOfferSetting.country,
        address: userOfferSetting.address,
        displayPicture: "",
      },
    };

    UsersAPI.updateProfiles(payload).then((res) => {
      localStorage.removeItem("SET-MRH-PAYLOAD");
      dispatch(getLoginUser());
    });
  } else {
    localStorage.setItem("SWEESHER-USER", JSON.stringify(user));
    dispatch({ type: USER_LOGIN_SUCCESS, data: user });
  }
  // Remove Discover Set MRH Payload.
  localStorage.removeItem("SET-MRH-PAYLOAD");
};

export const removeUser = () => (dispatch) => {
  dispatch({ type: USER_LOGIN_SUCCESS, data: undefined });
  dispatch({ type: USER_SESSION_SUCCESS, data: "" });
  const payload = { email: "", optEmailId: "" };
  dispatch({ type: OTP_MAIL_SUCCESS, data: payload });
};
