import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";

// icons
import left from "../../assets/images/svg/left_arrow.svg";
import right from "../../assets/images/svg/right_arrow.svg";
import completed_svg from "../../assets/images/svg/completed.svg";

// style
import style from "./style.module.scss";
import axios from "axios";

// actions
import { userAction } from "../../redux/actions";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { notifyWarn } from "../../components/Toast";

const validationSchema = z.object({
  // logement
  active: z.boolean(),
  propertyType: z.enum(["House", "Apartment"]),
  // --------------

  // details
  ownerAddress: z.string().min(1),
  ownerPostcode: z.string().min(1),
  ownerCity: z.string().min(1),
  ownerCountry: z.string().optional(),
  room: z.string(),
  size: z.string(),
  floor: z.enum(["top", "middle", "ground"]).default('top'),
  // ------

  // equipments
  hasSwimmingPool: z.boolean(),
  hasTennisCourt: z.boolean(),
  hasVeranda: z.boolean(),
  hasFireAlarm: z.boolean(),
  hasTheftAlarm: z.boolean(),
  hasSolarPanels: z.boolean(),
  hasChimney: z.boolean(),
});

function InsurancePNO({ tab, index, pno, isMobile }) {
  const dispatch = useDispatch();
  const { updatePnoLoading, complete, pnoAfterUpdate } = useSelector(
    (state) => state.users
  );
  const { reset, setValue, watch, handleSubmit, register } = useForm({
    resolver: zodResolver(validationSchema),

  });

  const [page, setPage] = useState(0);

  const goNext = () => {
    setPage((prev) => prev + 1);
  };
  const goBack = () => {
    setPage((prev) => prev - 1);
  };

  const propertyTypeHandler = (value) => {
    setValue("propertyType", value);
  };

  const updatePno = (data) => {
    dispatch(
      userAction.updatePnoUser({
        ...data,
        complete: true,
        ownerCountry: "France",
        floor: data.propertyType === "Apartment" ? data.floor : null,
      })
    );
  };

  useEffect(() => {
    if (complete) {
      dispatch(
        userAction.updatePnoUser({ ...pnoAfterUpdate, complete: false })
      );
      setPage(0);
    }
  }, [complete, dispatch, pnoAfterUpdate]);

  useEffect(() => {
    if (pno)
      reset({
        ...pno,
        room: pno.room ? pno.room.toString() : "0",
        ownerCountry: "France",
        size: pno.size ? pno.size.toString() : "0",
        propertyType: "Apartment",
        floor: pno.floor ?? "top",
      });
  }, [pno, reset]);

  if (tab !== index) return <></>;

  if (complete)
    return (
      <div className={style.complete}>
        <div data-style="container">
          <img src={completed_svg} alt="complete" />
        </div>
        <p>Profile assurance habitation à jour !</p>
      </div>
    );

  if (isMobile)
    return (
      <Loader loading={updatePnoLoading}>
        <MobileForm
          handleSubmit={handleSubmit}
          propertyTypeHandler={propertyTypeHandler}
          register={register}
          sendData={updatePno}
          setValue={setValue}
          watch={watch}
        />
      </Loader>
    );

  return (
    <Loader loading={updatePnoLoading}>
      <form autoComplete="off" onSubmit={handleSubmit(updatePno)}>
        <VotreLogement
          goNext={goNext}
          index={0}
          page={page}
          register={register}
          propertyTypeHandler={propertyTypeHandler}
          watch={watch}
        />

        <VotreAdress
          goBack={goBack}
          goNext={goNext}
          index={1}
          page={page}
          register={register}
          watch={watch}
          setValue={setValue}
        />

        <Equipment
          goBack={goBack}
          index={2}
          page={page}
          register={register}
          watch={watch}
        />
      </form>
    </Loader>
  );
}

const VotreLogement = ({
  propertyTypeHandler,
  goNext,
  register,
  page,
  index,
  watch,
}) => {
  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <div data-style="group-checkbox" style={{ marginBottom: "90px" }}>
        <label>Avez-vous des biens en location ?</label>
        <div
          onClick={() => {
            document.getElementById("active").click();
          }}
          className={style.switch}
        >
          <input id="active" {...register("active")} type="checkbox" />
          <span></span>
        </div>
      </div>
      <div
        data-style="choice"
        style={{ display: watch("active") ? "flex" : "none" }}
      >
        <input type="radio" value="House" {...register("propertyType")} />
        <div
          role="button"
          onClick={() => propertyTypeHandler("House")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="clarity:house-solid" />
          <span>Maison</span>
        </div>
        <span>Ou</span>
        <input type="radio" value="Apartment" {...register("propertyType")} />
        <div
          role="button"
          onClick={() => propertyTypeHandler("Apartment")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="prime:building" />
          <span>Appartement</span>
        </div>
      </div>

      <div
        className={style.footer}
        style={{ display: watch("active") ? "flex" : "none" }}
      >
        <i></i>
        <button
          type="button"
          onClick={() => {
            if (!watch("propertyType"))
              return notifyWarn("veillez faire un choix !");
            goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  );
};

const VotreAdress = ({
  goBack,
  goNext,
  page,
  index,
  register,
  setValue,
  watch,
}) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };
  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (val) => {
    const { label, postcode, city } = val;
    setValue("ownerAddress", label);
    setValue("ownerPostcode", postcode);
    setValue("ownerCity", city);
    setOptions([]);
  };

  const resetFields = () => {
    setValue("ownerAddress", "");
    setValue("ownerPostcode", "");
    setValue("ownerCity", "");
    setOptions([]);
  };

  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <div data-style="form">
        <h3>Votre adresse :</h3>
        <div data-style="single-input">
          <div
            data-style="bg-hide"
            onClick={resetFields}
            style={{ display: options.length ? "block" : "none" }}
          />
          <Icon icon="carbon:location" />
          <input
            {...register("ownerAddress", {
              onChange: (e) => adressHandler(e),
            })}
            type="text"
          />
          {options?.length ? (
            <ul>
              {options.map((opt) => (
                <li
                  onClick={() => onClickHandler(opt.properties)}
                  key={`opt-${opt.properties.id}`}
                >
                  {opt.properties.label}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>

        <div data-style="d-flex" style={{ display: "none" }}>
          <div data-style="group-form">
            <label>Code postal</label>
            <input
              {...register("ownerPostcode")}
              type="text"
              style={{ pointerEvents: "none" }}
            />
          </div>

          <div data-style="group-form">
            <label>Ville</label>
            <input
              {...register("ownerCity")}
              type="text"
              style={{ pointerEvents: "none" }}
            />
          </div>
        </div>

        <div data-style="d-flex" style={{ display: "none" }}>
          <div data-style="group-form">
            <label>Pays</label>
            <input {...register("ownerCountry")} type="text" />
          </div>
        </div>

        <div data-style="d-flex">
          <div data-style="group-form">
            <label>Nombre de pièce</label>
            <input {...register("room")} type="number" />
          </div>

          <div data-style="group-form">
            <label>m²</label>
            <input {...register("size")} type="number" />
          </div>
        </div>

        <div data-style="d-flex" style={{
            display: watch("propertyType") === "Apartment" ? "block" : "none",
          }}>
          <div data-style="group-form">
            <label>Étage</label>
            <select {...register("floor")} defaultValue="top">
              <option value="top">Dernière étage</option>
              <option value="middle">Étage intermédiaire</option>
              <option value="ground">Rez de chaussée</option>
            </select>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          type="button"
          onClick={() => {
            if (
              !watch("ownerAddress") ||
              !watch("room").length ||
              !watch("size").length
            )
              return notifyWarn("tout les champs sont obligatoire !");
            else goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  );
};

const Equipment = ({ goBack, page, index, register, watch }) => {
  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <h3>Disposez vous l&rsquo;un de ces équipements ?</h3>
      <div data-style="checkbox-container">
        <div data-style="standard">
          <div data-style="check-container">
            <input {...register("hasSwimmingPool")} type="checkbox" />
            <label>Piscine</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTennisCourt")} type="checkbox" />
            <label>Court de tennis</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasVeranda")} type="checkbox" />
            <label>Véranda</label>
          </div>
        </div>

        <div
          data-style="for-home"
          style={{
            display: watch("propertyType") === "Apartment" ? "none" : "flex",
          }}
        >
          <div data-style="check-container">
            <input {...register("hasFireAlarm")} type="checkbox" />
            <label>Alarme incendie</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTheftAlarm")} type="checkbox" />
            <label>Alarme antivol</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasSolarPanels")} type="checkbox" />
            <label>Panneaux solaires</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasChimney")} type="checkbox" />
            <label>Cheminée</label>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button type="submit">Valider</button>
      </div>
    </div>
  );
};

const MobileForm = ({
  handleSubmit,
  sendData,
  register,
  propertyTypeHandler,
  setValue,
  watch,
}) => {
  return (
    <form className={style.mobile_form} onSubmit={handleSubmit(sendData)}>
      <div>
        <div data-style="group-checkbox-pno">
          <p>Avez-vous des biens en location ?</p>
          <div
            onClick={() => {
              document.getElementById("mobile-active").click();
            }}
            className={style.switch}
          >
            <input id="mobile-active" {...register("active")} type="checkbox" />
            <span></span>
          </div>
        </div>
      </div>
      <div style={{ display: watch("active") ? "block" : "none" }}>
        <MobileCheckboxes
          propertyTypeHandler={propertyTypeHandler}
          register={register}
        />
        <MobileAdress watch={watch} register={register} setValue={setValue} />
        <MobileEquipments register={register} watch={watch} />
        <button
          onClick={() => {
            if (
              !watch("room").length ||
              !watch("size").length ||
              !watch("ownerAddress").length ||
              !watch("propertyType")
            )
              return notifyWarn("tout les champs sont obligatoires");
          }}
          type="submit"
        >
          Valider
        </button>
      </div>
    </form>
  );
};

const MobileCheckboxes = ({ propertyTypeHandler, register }) => {
  return (
    <React.Fragment>
      <h3>Votre logement :</h3>
      <div
        onClick={() => propertyTypeHandler("Apartment")}
        role="button"
        data-style="checkbox"
      >
        <label>Appartement</label>
        <input type="radio" value="Apartment" {...register("propertyType")} />
        <div></div>
      </div>
      <div
        onClick={() => propertyTypeHandler("House")}
        role="button"
        data-style="checkbox"
      >
        <label>Maison</label>
        <input type="radio" value="House" {...register("propertyType")} />
        <div></div>
      </div>
    </React.Fragment>
  );
};

const MobileAdress = ({ setValue, register, watch }) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };
  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (val) => {
    const { label, postcode, city } = val;
    setValue("ownerAddress", label);
    setValue("ownerPostcode", postcode);
    setValue("ownerCity", city);
    setOptions([]);
  };

  const reset = () => {
    setValue("ownerAddress", "");
    setValue("ownerPostcode", "");
    setValue("ownerCity", "");
    setOptions([]);
  };

  return (
    <div data-style="form">
      <div data-style="list-input">
        <div
          data-style="bg-hide"
          style={{ display: options.length ? "block" : "none" }}
          onClick={reset}
        />
        <Icon icon="carbon:location" />
        <input
          {...register("ownerAddress", {
            onChange: (e) => adressHandler(e),
          })}
          type="text"
          placeholder="Votre adresse"
        />
        {options?.length ? (
          <ul>
            {options.map((opt) => (
              <li
                onClick={() => onClickHandler(opt.properties)}
                key={`opt-${opt.properties.id}`}
              >
                {opt.properties.label}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>

      <div data-style="flex" style={{ display: "none" }}>
        <input
          style={{ pointerEvents: "none" }}
          type="text"
          placeholder="Ville"
          {...register("ownerCity")}
        />
        <input
          style={{ pointerEvents: "none" }}
          type="text"
          placeholder="Code postal"
          {...register("ownerPostcode")}
        />
        <input type="text" placeholder="Pays" {...register("ownerCountry")} />
      </div>

      <div data-style="1-1/4">
        <input
          type="number"
          placeholder="Nombre de pièces"
          {...register("room")}
        />
        <input type="number" placeholder="M²" {...register("size")} />
      </div>

      <select
        {...register("floor")}
        style={{
          display: watch("propertyType") === "Apartment" ? "block" : "none",
        }}
        defaultValue={"top"}
      >
        <option value="top">Dernière étage</option>
        <option value="middle">Étage intermédiaire</option>
        <option value="ground">Rez de chaussée</option>
      </select>
    </div>
  );
};

const MobileEquipments = ({ register, watch }) => {
  return (
    <div style={{ marginTop: "15px" }}>
      <div data-style="equipment" aria-label="standard">
        <div data-style="2/2">
          <div data-style="check-container">
            <input {...register("hasSwimmingPool")} type="checkbox" />
            <label>Piscine</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTennisCourt")} type="checkbox" />
            <label>Court de tennis</label>
          </div>
        </div>

        <div data-style="check-container">
          <input {...register("hasVeranda")} type="checkbox" />
          <label>Véranda</label>
        </div>
      </div>

      <div
        data-style="equipment"
        aria-label="for-house"
        style={{
          display: watch("propertyType") === "Apartment" ? "none" : "flex",
          marginTop: "15px",
        }}
      >
        <div data-style="2/2">
          <div data-style="check-container">
            <input {...register("hasFireAlarm")} type="checkbox" />
            <label>Alarme incendie</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTheftAlarm")} type="checkbox" />
            <label>Alarme antivol</label>
          </div>
        </div>

        <div data-style="2/2">
          <div data-style="check-container">
            <input {...register("hasSolarPanels")} type="checkbox" />
            <label>Panneaux solaires</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasChimney")} type="checkbox" />
            <label>Cheminée</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsurancePNO;
