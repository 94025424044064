import React, {useState,useEffect} from 'react'
import style from "./style.module.scss";
import cat from "../../assets/images/svg/cat.svg";
import dog from "../../assets/images/svg/dog.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { notifyWarn } from "../../components/Toast";
import right from "../../assets/images/svg/right_arrow.svg";
import completed_svg from "../../assets/images/svg/completed.svg";
import { userAction } from "../../redux/actions";
import { useDispatch,useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import wrapper from "../../assets/images/wrapper.png";
import addProfileWrapper from "../../assets/images/create-wrapper.png";
import deleteIcon from "../../assets/images/svg/delete.svg";
import editIcon from "../../assets/images/svg/edit.svg";
import plusIcon from "../../assets/images/svg/plus.svg";
import { dogBreed,catBreed } from '../welcome/simulation/breed';

import Loader from '../../components/Loader';


const validationSchema = z.object({
  species: z.string().min(3, `le type de l'animal est obligatoire !`),
  breed: z.string().min(1, `la race de l'animal est obligatoire !`),
  petName: z.string().min(1, `le nom de l'animal est obligatoires !`),
  birthYear: z.string().min(1, `l'annèe de naissance obligatoires !`),
  birthMonth: z.string().min(1, "le mois de naissance est obligatoire"),
});

export default function AnimalInsurance() {
  const dispatch = useDispatch();
  const { petsProfiles,loadingDelete } = useSelector((state) => state.users);
  const [submitted, setSubmitted] = useState(false);
  const [update, setUpdate] = useState(null);
  const [create,setCreate] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });
  useEffect(() => {
    if (Object.keys(errors).length) {
      notifyWarn(errors[Object.keys(errors)[0]].message);
    }
  }, [errors]);

  const species = watch("species")
  const sendData = (data) => {
    const payload = {
      species : data.species,
      breed: data.breed,
      name:data.petName,
      birthYear: parseInt(data.birthYear),
      birthMonth: parseInt(data.birthMonth)
    };
    dispatch(userAction.createPetUser(payload));
    if(create) setCreate(!create)
  };

  const updateProfile = (data) => {
    const payload = {
      objectId: update.objectId,
      species : data.species,
      breed: data.breed,
      name:data.petName,
      birthYear: parseInt(data.birthYear),
      birthMonth: parseInt(data.birthMonth)
    };
    dispatch(userAction.updatePetsUser(payload));
    setValue("species",'Cat')
    setValue("petName",'')
    setValue("breed",'')
    setValue("birthYear",'')
    setValue("birthMonth",'')
    setUpdate(null)
  };
  const deletePetProfileHandler = (petId) => {
    dispatch(userAction.deletePetUser(petId));
    setValue("species",'Cat')
    setValue("petName",'')
    setValue("breed",'')
    setValue("birthYear",'')
    setValue("birthMonth",'')
  };
  if (!petsProfiles?.length || create ) return (
    <div className={style.animalInsurance} >
        <h3>
          Votre animal
        </h3>
        <div className={style.iconWrapper}>
          <div className={style.animalWrapper} style={species==="Cat" || !species ? {    
            border: "solid #70e084",
            borderRadius: "20px",
            padding: "20px",} : {}} >
            <img src={cat} alt="cat" onClick={()=> setValue("species",'Cat')} />
            <span style={{marginTop:15}}>
                 Chat
            </span>
          </div>
          <span>ou</span>
          <div className={style.animalWrapper} style={species==="Dog" ? {    
            border: "solid #70e084",
            borderRadius: "20px",
            padding: "20px",} : {}} >
            <img src={dog} alt="dog" onClick={()=> setValue("species",'Dog')} />
            <span>
            Chien
            </span>
          </div>
        </div>

          <form className={style.formContainer} onSubmit={handleSubmit(sendData)} >
              <div className={style.formWrapper}>
                <div data-style="groupe-form">
                  <input placeholder="Nom" {...register("petName")} type="text" />
                </div>
                <div data-style="groupe-form">
                  <Autocomplete
                    options={!species || species === "Dog" ?  dogBreed : catBreed}
                    style={{ width: '100%' }}
                    className={style.grid_item}
                    onChange={(e, value) => setValue('breed',value)}
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        border: 'none',
                        '&:focus-within': {
                          boxShadow: 'none',
                        }
                      },
                      '& .MuiInputBase-root': {
                        border: '0px !important',
                      },
                      '& .MuiInputBase-input.MuiOutlinedInput-input': {
                        border: '0px !important',
                      },
                      
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid rgb(179, 179, 179)",
                      borderRadius: '15px',
                      
                    },
                    '& .MuiOutlinedInput-root': {
                      padding: '0px !important'
                    },
                    '& MuiOutlinedInput-root': { 
                      borderRadius: '10px !important'
                    },
                    }}
                    renderInput={params => (
                      <TextField  placeholder="Race de l'animal" {...params} />
                    )}
                  />
                  
                </div>
                <div data-style="groupe-form">
                  <input placeholder="Annèe de naissaince" {...register("birthYear")} type="text" />
                </div>
                <div data-style="groupe-form">
                  <input
                    placeholder="Mois de naissance"
                    {...register("birthMonth")}
                    type="text"
                  />
                </div>
              </div>
              <button
                type="submit"
                className={style.submit}
                onClick={() => {
                    setSubmitted(!submitted)
                }}
              >
                Valider
                <img src={right} alt="right-svg" />
              </button>
          </form>
    </div>
  )
  if (submitted) return (
    <div className={style.submitted} >
      <div className={style.complete}>
        <div data-style="container">
          <img src={completed_svg} alt="complete" />
        </div>
        <p>Profil  à jour !</p>
      </div>
      <div className={style.ctawrapper} >
        <button
          type="button"
          onClick={() => {
              setSubmitted(!submitted)
          }}
          className={style.form_button}
        >
          Voir les offres
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  )
  if (update) return (
    <div className={style.animalInsurance} >
        <h3>
          Votre animal
        </h3>
        <div className={style.iconWrapper}>
          <div className={style.animalWrapper} style={species==="Cat" ? {    
            border: "solid #70e084",
            borderRadius: "20px",
            padding: "20px",} : {}} >
            <img src={cat} alt="cat" onClick={()=> setValue("species",'Cat')} />
            <span style={{marginTop:15}}>
                 Chat
            </span>
          </div>
          <span>ou</span>
          <div className={style.animalWrapper} style={species==="Dog" ? {    
            border: "solid #70e084",
            borderRadius: "20px",
            padding: "20px",} : {}} >
            <img src={dog} alt="dog" onClick={()=> setValue("species",'Dog')} />
            <span>
            Chien
            </span>
          </div>
        </div>

          <form className={style.formContainer} onSubmit={handleSubmit(updateProfile)} >
              <div className={style.formWrapper}>
                <div data-style="groupe-form">
                  <input placeholder="Nom" {...register("petName")} type="text" />
                </div>
                <div data-style="groupe-form">
                  <Autocomplete
                    options={!species || species === "Dog" ?  dogBreed : catBreed}
                    style={{ width: '100%' }}
                    className={style.grid_item}
                    defaultValue={update?.breed}
                    onChange={(e, value) => setValue('breed',value)}
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        border: 'none',
                        '&:focus-within': {
                          boxShadow: 'none',
                        }
                      },
                      '& .MuiInputBase-root': {
                        border: '0px !important',
                      },
                      '& .MuiInputBase-input.MuiOutlinedInput-input': {
                        border: '0px !important',
                      },
                      
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid rgb(179, 179, 179)",
                      borderRadius: '15px',
                      
                    },
                    '& .MuiOutlinedInput-root': {
                      padding: '0px !important'
                    },
                    '& MuiOutlinedInput-root': { 
                      borderRadius: '10px !important'
                    },
                    }}
                    renderInput={params => (
                      <TextField  placeholder="Race de l'animal" {...params} />
                    )}
                  />
                  
                </div>
                <div data-style="groupe-form">
                  <input placeholder="Annèe de naissaince" {...register("birthYear")} type="text" />
                </div>
                <div data-style="groupe-form">
                  <input
                    placeholder="Mois de naissance"
                    {...register("birthMonth")}
                    type="text"
                  />
                </div>
              </div>
              <button
                type="submit"
                className={style.submit}
              >
                Valider
                <img src={right} alt="right-svg" />
              </button>
          </form>
    </div>
  )
  return (
    <div className={style.animalProfiles} >
      {
        petsProfiles?.length && petsProfiles.map((pet,index)=>(
          <Loader loading={loadingDelete}>
          <div key={index} className={style.profileCard} >
            <img src={wrapper} className={style.imgBg} alt="wrapper" />
            <div className={style.profileCardHeader} >
              <button onClick={() => {
                setValue("petName",pet.name); 
                setValue("species",pet.species); 
                setValue("breed",pet.breed); 
                setValue("birthYear",pet.birthYear.toString()); 
                setValue("birthMonth",pet.birthMonth.toString()); 
                setUpdate(pet)
                }}>
                <img src={editIcon} alt="edit icon" />
              </button>
                <span>
                  Profile
                </span>
                <button onClick={() => deletePetProfileHandler(pet.objectId)}>
                  <img src={deleteIcon} alt="delete icon" />
                </button>
            </div>
            <div className={style.profileInfo} >
                <span>
                  {pet.name}
                </span>
                <span>
                  {pet.breed}
                </span>
                <span>
                  {pet.birthMonth}/{pet.birthYear}
                </span>
            </div>  
          </div>  
          </Loader>
        ))
      }
      <div className={style.addAnimalCard} onClick={()=> setCreate(!create) } >
        <img src={addProfileWrapper} className={style.imgBg} alt="wrapper" />
        <img src={plusIcon} className={style.plus} alt="wrapper" />
          <span>
            Ajouter un profile
          </span>
      </div>
    </div>
  )
}
