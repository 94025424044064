import React from 'react'
import { Button } from 'antd'
import { BsArrowLeft } from 'react-icons/bs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Packages(props) {
    const { goBack } = props
    return (

        <div className="Package location NewRequest">
            <div className="newReqHeader locationHeader flexCenter">
                <BsArrowLeft className="back-icon" onClick={goBack} />
                <span className='NewReqTitle'>Packages</span>
            </div>
            <div className="SelectPackage">
                <div className="package card">
                    <div className="card pos-relative packageItem">
                        <div className="packageItemTitle">
                            <span>Package Type:</span>
                            <div className="packageType">Eco</div>
                            <div className="star-section">
                                <FontAwesomeIcon
                                    className="star-icon"
                                    icon={faStar}
                                ></FontAwesomeIcon>
                            </div>
                        </div>
                        <div className="packageDescription">
                            <p className="fadeMore">
                                ( All boxes are to be prepared by the user, we only pick up boxes
                                to truck & deliver )
                            </p>
                        </div>
                    </div>
                    <div className="card pos-relative packageItem">
                        <div className="packageItemTitle">
                            <span>Package Type:</span>
                            <div className="packageType">Standard</div>
                            <div className="star-section">
                                <FontAwesomeIcon
                                    className="star-icon"
                                    icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                    className="star-icon"
                                    icon={faStar}
                                ></FontAwesomeIcon>
                            </div>
                        </div>
                        <div className="packageDescription">
                            <p className="fadeMore">( We help for packing + eco pack )</p>
                        </div>
                    </div>
                    <div className="card pos-relative packageItem ">
                        <div className="packageItemTitle">
                            <span>Package Type:</span>
                            <div className="packageType">Premiem</div>
                            <div className="star-section">
                                <FontAwesomeIcon
                                    className="star-icon"
                                    icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                    className="star-icon"
                                    icon={faStar}
                                ></FontAwesomeIcon>
                                <FontAwesomeIcon
                                    className="star-icon"
                                    icon={faStar}
                                ></FontAwesomeIcon>
                            </div>
                        </div>
                        <div className="packageDescription">
                            <p className="fadeMore">
                                ( We handle everything ) + additional service “furniture lift”.
                            </p>
                        </div>
                    </div>
                    <Button className='MovinContinueBtn'>Procced</Button>
                </div>
            </div>
        </div>
    )
}

export default Packages