import style from "./style.module.scss";
import aboutImage from "../../../assets/images/svg/about.svg";
import msgIcon from "../../../assets/images/svg/msg.svg";
import _30percentIcon from "../../../assets/images/svg/30percent.svg";
import _50percentIcon from "../../../assets/images/svg/50percent.svg";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";

export default function Index() {
  const [slide, setSlide] = useState(0);
  const [text, setText] = useState(0);
  const slider = useRef(null);
  const goNext = () => {
    setText((prev) => prev + 1);
    setSlide((prev) => prev + slider.current.offsetWidth + 44);
  };

  const goBack = () => {
    setText((prev) => prev - 1);
    setSlide((prev) => prev - slider.current.offsetWidth - 44);
  };

  const isTablette = useMediaQuery({ query: "(max-width: 850px)" });
  const isPhone = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    if (slider) {
      setText(0)
      setSlide(slider.current.offsetWidth / 2);
    }
  }, [slider, isTablette, isPhone]);
  return (
    <div className={style.index} id="about-section">
      <h1>Qui sommes nous</h1>
      <div aria-label="about-content">
        <img src={aboutImage} alt="about" />
        <div className={style.textContainer}>
          <div aria-label="message-icon">
            <img src={msgIcon} alt="icon" />
          </div>

          <div className={style.slider}>
            <div
              ref={slider}
              className={style.slide}
              style={{ transform: `translate(-${slide}px, -50%)` }}
            >
              <p>
                Sweesher est un comparateur moderne conçu pour vous aider à
                trouver facilement les offres qui conviennent le mieux à votre
                profil .
              </p>
              <p>
                Fini les formulaires ennuyeux à remplir en boucle et les
                redirections inutiles. Grâce à notre service professionnel et
                innovant, vous pouvez facilement trouver les offres les mieux
                adaptées à votre profil.
              </p>
              <p>
                Remplissez votre profil une seule fois et laissez-nous faire le
                reste !
              </p>
              <p>
                Avec Sweesher, vous êtes sûr de trouver l'offre idéale sans
                tracas ni complications. Faites confiance à notre expertise et
                profitez d'une expérience client exceptionnelle !
              </p>
            </div>
          </div>

          <div aria-label="buttons">
            <span
              role="button"
              onClick={goBack}
              aria-disabled={text === 0 ? "true" : "false"}
            >
              <Icon icon="material-symbols:chevron-left-rounded" />
            </span>

            <span
              role="button"
              onClick={goNext}
              aria-disabled={text === 3 ? "true" : "false"}
            >
              <Icon icon="material-symbols:chevron-right-rounded" />
            </span>
          </div>
        </div>
      </div>

      <h1>Quelques chiffres à savoir</h1>
      <div aria-label="stats">
        <div>
          <img src={_30percentIcon} alt="30%" />
          <p>Des français ont la phobie administrative</p>
        </div>
        <div>
          <img src={_50percentIcon} alt="50%" />
          <p>Des français estiment payer leur contrats trop cheres</p>
        </div>
      </div>

      <div className={style.actions}>
        <h3>Vous etes concernés ?</h3>
        <Link duration={500} smooth={true} to="simulation-section">
          Faire une simulation
        </Link>
      </div>
    </div>
  );
}
