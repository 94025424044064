import style from "./style.module.scss";
import SimulationDiv from "./simulationDiv";

export default function Index() {
  return (
    <div className={style.index} id="simulation-section">
      <div aria-label="left">
        <h1>Comparer vos contrats d’assurances avec Sweesher !</h1>
        <p>
          Êtes-vous sûrs de payer le bon prix ? Inscrivez-vous sur Sweesher et
          trouvez des offres personnalisées adaptées à votre situation !
        </p>
        <p>
          Fini la paperasse et les formulaires sans fin ! Avec Sweesher il n’a
          jamais été aussi facile de réduire ses dépenses !
        </p>
      </div>

      <SimulationDiv />
    </div>
  );
}
