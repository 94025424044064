import React from "react";
import { Spin } from "antd";

const Loader = ({ loading, size = "large", children }) => {
  return (
    <Spin spinning={loading} delay={100} size={size}>
      {children}
    </Spin>
  );
};

export const AbsoluteLoader = ({ loading }) => {
  return (
    <Spin
      spinning={loading}
      delay={100}
      size="large"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "50%",
        right: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};

export default Loader;
