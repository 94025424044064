import axios from "axios";
import { BASE_URL } from "../constant";

export default class Api {
  static jsonify = (payload) => JSON.stringify(payload);

  static sendOtpMail(email) {
    var config = {
      method: "post",
      url: BASE_URL + "/stytch/sendOtpMail",
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify({ email }),
    };
    return axios(config);
  }

  static verifyOTP(emailId, code) {
    var config = {
      method: "post",
      url: BASE_URL + "/stytch/verifyOtp",
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify({ emailId, code }),
    };
    return axios(config);
  }

  static getLoginUser() {
    let config = {
      method: "get",
      url: `${BASE_URL}/users/info`,
    };
    return axios(config);
  }

  static updateUser(userId, data) {
    var config = {
      method: "put",
      url: BASE_URL + "/users/update",
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify(data),
    };
    return axios(config);
  }

  static updateMrh(data) {
    let config = {
      method: "put",
      url: `${BASE_URL}/insurance/mrh/profile/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify(data),
    };

    return axios(config);
  }

  static updatePno(data) {
    let config = {
      method: "put",
      url: `${BASE_URL}/insurance/pno/profile/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify(data),
    };

    return axios(config);
  }
  static createPet(data) {
    let config = {
      method: "post",
      url: `${BASE_URL}/insurance/pets/profile/create`,
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify(data),
    };

    return axios(config);
  }

  static deletePet(petId) {
    let config = {
      method: "delete",
      url: `${BASE_URL}/insurance/pets/profile/${petId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  }

  static getPetsProfiles() {
    let config = {
      method: "get",
      url: `${BASE_URL}/insurance/pets/profile/`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return axios(config);
  }

  static updatePets(data) {
    let config = {
      method: "put",
      url: `${BASE_URL}/insurance/pets/profile/update`,
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify(data),
    };

    return axios(config);
  }
  

  static updateProfiles(data) {
    var config = {
      method: "put",
      url: BASE_URL + "/profiles/update",
      headers: {
        "Content-Type": "application/json",
      },
      data: this.jsonify(data),
    };
    return axios(config);
  }
}
