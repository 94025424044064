import React, { useEffect, useState } from "react";
import keys from "../../assets/images/svg/keys.svg";
import documents from "../../assets/images/svg/document.svg";
import left from "../../assets/images/svg/left_arrow.svg";
import right from "../../assets/images/svg/right_arrow.svg";
import style from "./style.module.scss";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { notifyWarn } from "../../components/Toast";
import axios from "axios";

// actions
import { userAction } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";

// svgs
import completed_svg from "../../assets/images/svg/completed.svg";

const validationSchema = z.object({
  // details
  size: z.string().min(1, "Field required"),
  room: z.string().min(1, "Field required"),
  yearBuilt: z.string().optional(),
  capitalFurniture: z.string().min(1, "Field required"),
  floor: z.enum(["top", "middle", "ground"]).default('top'),
  alreadyInsured: z.boolean(),
  alreadyInhabited: z.boolean(),
  // ------

  // address
  address: z.string().min(1, "Field required"),
  postCode: z.string().min(1, "Field required"),
  city: z.string().min(1, "Field required"),
  country: z.string().optional(),
  // -------

  // vous etes - votre logement
  occupantType: z.enum(["tenant", "owner"]),
  propertyType: z.enum(["House", "Apartment"]),
  // --------------------------

  // equipment
  hasSwimmingPool: z.boolean(),
  hasTennisCourt: z.boolean(),
  hasVeranda: z.boolean(),
  hasFireAlarm: z.boolean(),
  hasTheftAlarm: z.boolean(),
  hasSolarPanels: z.boolean(),
  hasChimney: z.boolean(),
});

function InsuranceMRH({ mrh, user, tab, index, isMobile,submit,setSubmit,setActive }) {
  const { updateMrhLoading, mrhAfterUpdate } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({
    resolver: zodResolver(validationSchema),
  });
  const [page, setPage] = useState(0);

  const goNext = () => {
    setPage((prev) => prev + 1);
  };
  const goBack = () => {
    setPage((prev) => prev - 1);
  };

  const occupantTypeHandler = (value) => {
    setValue("occupantType", value);
  };
  const propertyTypeHandler = (value) => {
    setValue("propertyType", value);
  };
  const sendData = (data) => {
    const payload = {
      profile: {
        postCode: data.postCode,
        city: data.city,
        address: data.address,
        country: data.country,
      },
    };
    let final_data = {
      ...data,
      complete: true,
      country: "France",
      mainHome: true,
      floor: data.propertyType === "Apartment" ? data.floor : null,
    };
    if (data.propertyType === "Apartment")
      final_data = {
        ...final_data,
        hasFireAlarm: false,
        hasTheftAlarm: false,
        hasSolarPanels: false,
        hasChimney: false,
      };

    if(!final_data.yearBuilt) delete final_data.yearBuilt
    dispatch(userAction.updateMrhUser(final_data, payload));
  };

  useEffect(() => {
    if (Object.keys(errors).length && page >= 4) {
      notifyWarn("veillez completez toutes les infomations");
    }
  }, [errors, page]);

  useEffect(() => {
    if (mrh && user)
      reset({
        ...mrh,
        size: mrh.size ? mrh.size.toString() : "",
        room: mrh.room ? mrh.room.toString() : "",
        yearBuilt: mrh.yearBuilt ? mrh.yearBuilt.toString() : "",
        country: "France",
        floor: mrh.floor ?? "top",
        capitalFurniture: mrh.capitalFurniture
          ? mrh.capitalFurniture.toString()
          : "",
        ...user,
      });
  }, [mrh, user, reset]);

  useEffect(() => {
    
    if (mrh && mrh.complete) {
      dispatch(userAction.updateMrhUser({ ...mrh, complete: false }, null));
      setPage(0);
      setActive("2")
    } else if (mrhAfterUpdate && mrhAfterUpdate.complete) {
      dispatch(
        userAction.updateMrhUser({ ...mrhAfterUpdate, complete: false }, null)
      );
      setPage(0);
      setActive("2")
    }
    
  }, [mrh, mrhAfterUpdate, dispatch]);

  if (tab !== index) return <></>;

  if (mrh?.complete || mrhAfterUpdate?.complete) {
    return (
      <div className={style.complete}>
        <div data-style="container">
          <img src={completed_svg} alt="complete" />
        </div>
        <p>Profile assurance habitation à jour !</p>
      </div>
    );
  }

  if (isMobile)
    return (
      <Loader loading={updateMrhLoading}>
        <MobileForm
          handleSubmit={handleSubmit}
          register={register}
          sendData={sendData}
          occupantTypeHandler={occupantTypeHandler}
          propertyTypeHandler={propertyTypeHandler}
          setValue={setValue}
          watch={watch}
          submit={submit}
          setSubmit={setSubmit}
        />
      </Loader>
    );

  return (
    <Loader loading={updateMrhLoading}>
      <form onSubmit={handleSubmit(sendData)}>
        <VousEtes
          goNext={goNext}
          occupantTypeHandler={occupantTypeHandler}
          watch={watch}
          page={page}
          index={0}
          register={register}
        />
        <VotreLogement
          goBack={goBack}
          goNext={goNext}
          watch={watch}
          register={register}
          page={page}
          propertyTypeHandler={propertyTypeHandler}
          index={1}
        />
        <VotreAdress
          register={register}
          goBack={goBack}
          goNext={goNext}
          index={2}
          page={page}
          setValue={setValue}
          watch={watch}
        />
        <Details
          register={register}
          goBack={goBack}
          goNext={goNext}
          watch={watch}
          index={3}
          page={page}
        />
        <Equipment
          register={register}
          watch={watch}
          goBack={goBack}
          index={4}
          page={page}
          submit={submit}
          setSubmit={setSubmit}
        />
      </form>
    </Loader>
  );
}

const Equipment = ({ goBack, page, index, register, watch,submit,setSubmit }) => {
  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <h3>Disposez vous l&rsquo;un de ces équipements ?</h3>
      <div data-style="checkbox-container">
        <div data-style="standard">
          <div data-style="check-container">
            <input {...register("hasSwimmingPool")} type="checkbox" />
            <label>Piscine</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTennisCourt")} type="checkbox" />
            <label>Court de tennis</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasVeranda")} type="checkbox" />
            <label>Véranda</label>
          </div>
        </div>

        <div
          data-style="for-home"
          style={{
            display: watch("propertyType") === "Apartment" ? "none" : "flex",
          }}
        >
          <div data-style="check-container">
            <input {...register("hasFireAlarm")} type="checkbox" />
            <label>Alarme incendie</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTheftAlarm")} type="checkbox" />
            <label>Alarme antivol</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasSolarPanels")} type="checkbox" />
            <label>Panneaux solaires</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasChimney")} type="checkbox" />
            <label>Cheminée</label>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button onClick={()=> setSubmit(!submit)} type="submit">Valider</button>
      </div>
    </div>
  );
};

const Details = ({ goBack, goNext, page, index, register, watch }) => {
  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <div data-style="inputes-form">
        <div data-style="form-group">
          <label>Nombre de pièce</label>
          <input type="number" {...register("room")} />
        </div>

        <div data-style="form-group">
          <label>m²</label>
          <input type="number" {...register("size")} />
        </div>

        <div data-style="form-group">
          <label>Année de construction</label>
          <input type="number" {...register("yearBuilt")} />
        </div>

        <div data-style="form-group">
          <label>Capital mobilier</label>
          <input type="number" {...register("capitalFurniture")} />
        </div>

        {watch("propertyType") !== "Apartment" ? <i /> : <></>}

        <div
          data-style="form-group"
          style={{
            display: watch("propertyType") === "Apartment" ? "block" : "none",
          }}
        >
          <label>Étage</label>
          <select {...register("floor")} defaultValue="top">
            <option value="top">Dernière étage</option>
            <option value="middle">Étage intermédiaire</option>
            <option value="ground">Rez de chaussée</option>
          </select>
        </div>

        <div data-style="form-group-checkbox">
          <div data-style="group-checkbox">
            <label>Déjà assuré ?</label>
            <div
              onClick={() => {
                document.getElementById("alreadyInsured").click();
              }}
              className={style.switch}
            >
              <input
                id="alreadyInsured"
                {...register("alreadyInsured")}
                type="checkbox"
              />
              <span></span>
            </div>
          </div>

          <div data-style="group-checkbox">
            <label>Déjà habité ?</label>
            <div
              onClick={() => {
                document.getElementById("alreadyInhabited").click();
              }}
              className={style.switch}
            >
              <input
                id="alreadyInhabited"
                {...register("alreadyInhabited")}
                type="checkbox"
              />
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          type="button"
          onClick={() => {
            if (
              !watch("room").length ||
              !watch("size").length ||
              !watch("capitalFurniture").length
            )
              return notifyWarn("tout les champs sont obligatoires");

            goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  );
};

const VotreAdress = ({
  goBack,
  goNext,
  page,
  index,
  register,
  setValue,
  watch,
}) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };
  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (val) => {
    const { label, postcode, city } = val;
    setValue("address", label);
    setValue("postCode", postcode);
    setValue("city", city);
    setOptions([]);
  };

  const reset = () => {
    setValue("address", "");
    setValue("postCode", "");
    setValue("city", "");
    setOptions([]);
  };

  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <div data-style="form">
        <h3>Votre adresse :</h3>
        <div data-style="single-input">
          <div
            data-style="bg-hide"
            style={{ display: options.length ? "block" : "none" }}
            onClick={reset}
          />
          <Icon icon="carbon:location" />
          <input
            autoComplete="off"
            {...register("address", {
              onChange: (e) => adressHandler(e),
            })}
            type="text"
          />
          {options?.length ? (
            <ul>
              {options.map((opt) => (
                <li
                  onClick={() => onClickHandler(opt.properties)}
                  key={`opt-${opt.properties.id}`}
                >
                  {opt.properties.label}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>

        <div data-style="d-flex" style={{ display: "none" }}>
          <div data-style="group-form">
            <label>Code postal</label>
            <input
              {...register("postCode")}
              type="text"
              style={{ pointerEvents: "none" }}
            />
          </div>

          <div data-style="group-form">
            <label>Ville</label>
            <input
              {...register("city")}
              type="text"
              style={{ pointerEvents: "none" }}
            />
          </div>
        </div>

        <div data-style="d-flex" style={{ display: "none" }}>
          <div data-style="group-form">
            <label>Pays</label>
            <input {...register("country")} type="text" />
          </div>
        </div>
      </div>

      <div className={style.footer}>
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          type="button"
          onClick={() => {
            if (!watch("address").length)
              return notifyWarn("le champ adresse est obligatoire !");
            else goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  );
};

const VotreLogement = ({
  propertyTypeHandler,
  goBack,
  goNext,
  register,
  page,
  index,
  watch,
}) => {
  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <h3>Votre logement est :</h3>
      <div data-style="choice">
        <input type="radio" value="House" {...register("propertyType")} />
        <div
          role="button"
          onClick={() => propertyTypeHandler("House")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="clarity:house-solid" />
          <span>Maison</span>
        </div>
        <span>Ou</span>
        <input type="radio" value="Apartment" {...register("propertyType")} />
        <div
          role="button"
          onClick={() => propertyTypeHandler("Apartment")}
          data-style="choice-content"
        >
          <Icon color="#A6C941" icon="prime:building" />
          <span>Appartement</span>
        </div>
      </div>

      <div className={style.footer}>
        <button type="button" onClick={goBack}>
          <img src={left} alt="left-svg" />
          Retour
        </button>
        <button
          type="button"
          onClick={() => {
            if (!watch("propertyType"))
              return notifyWarn("veillez faire un choix !");
            goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  );
};

const VousEtes = ({
  occupantTypeHandler,
  goNext,
  register,
  page,
  index,
  watch,
}) => {
  return (
    <div
      className={style.insurance_choice}
      style={{ display: page === index ? "block" : "none" }}
    >
      <h3>Vous êtes :</h3>
      <div data-style="choice">
        <input type="radio" value="owner" {...register("occupantType")} />
        <div
          role="button"
          onClick={() => occupantTypeHandler("owner")}
          data-style="choice-content"
        >
          <img src={keys} alt="keys-svg" />
          <span>Propriétaire</span>
        </div>
        <span>Ou</span>
        <input type="radio" value="tenant" {...register("occupantType")} />
        <div
          role="button"
          onClick={() => occupantTypeHandler("tenant")}
          data-style="choice-content"
        >
          <img src={documents} alt="docs-svg" />
          <span>Locataire</span>
        </div>
      </div>

      <div className={style.footer}>
        <i></i>
        <button
          type="button"
          onClick={() => {
            if (!watch("occupantType"))
              return notifyWarn("veillez faire un choix !");
            goNext();
          }}
        >
          Suivant
          <img src={right} alt="right-svg" />
        </button>
      </div>
    </div>
  );
};

const MobileForm = ({
  handleSubmit,
  sendData,
  register,
  occupantTypeHandler,
  propertyTypeHandler,
  setValue,
  watch,
  submit,
  setSubmit
}) => {
  return (
    <form className={style.mobile_form} onSubmit={handleSubmit(sendData)}>
      <MobileCheckboxes
        occupantTypeHandler={occupantTypeHandler}
        propertyTypeHandler={propertyTypeHandler}
        register={register}
      />
      <MobileAdress register={register} setValue={setValue} />
      <MobileEquipments register={register} watch={watch} />
      <button
        onClick={() => {
          if (
            !watch("room").length ||
            !watch("size").length ||
            !watch("capitalFurniture").length
          )
            return notifyWarn("tout les champs sont obligatoires");
          setSubmit(!submit)
        }}
        type="submit"
      >
        Valider
      </button>
    </form>
  );
};

const MobileCheckboxes = ({
  occupantTypeHandler,
  propertyTypeHandler,
  register,
}) => {
  return (
    <React.Fragment>
      <h3>Vous etes :</h3>
      <div
        onClick={() => occupantTypeHandler("owner")}
        role="button"
        data-style="checkbox"
      >
        <label>Propriétaire</label>
        <input type="radio" value="owner" {...register("occupantType")} />
        <div></div>
      </div>
      <div
        onClick={() => occupantTypeHandler("tenant")}
        role="button"
        data-style="checkbox"
      >
        <label>Locataire</label>
        <input type="radio" value="tenant" {...register("occupantType")} />
        <div></div>
      </div>

      <h3>Votre logement :</h3>
      <div
        onClick={() => propertyTypeHandler("Apartment")}
        role="button"
        data-style="checkbox"
      >
        <label>Appartement</label>
        <input type="radio" value="Apartment" {...register("propertyType")} />
        <div></div>
      </div>
      <div
        onClick={() => propertyTypeHandler("House")}
        role="button"
        data-style="checkbox"
      >
        <label>Maison</label>
        <input type="radio" value="House" {...register("propertyType")} />
        <div></div>
      </div>
    </React.Fragment>
  );
};

const MobileAdress = ({ setValue, register }) => {
  const [options, setOptions] = useState([]);
  const adressHandler = (e) => {
    onAddressSearch(e.target.value);
  };
  const searchThisAddressOverAPI = async (query) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_SERVER_ADDRESS}/address/search?q=${query}`
    );
    return data;
  };
  const onAddressSearch = async (searchText) => {
    setOptions(
      searchText.length < 5 ? [] : await searchThisAddressOverAPI(searchText)
    );
  };

  const onClickHandler = (val) => {
    const { label, postcode, city } = val;
    setValue("address", label);
    setValue("postCode", postcode);
    setValue("city", city);
    setOptions([]);
  };

  const reset = () => {
    setValue("address", "");
    setValue("postCode", "");
    setValue("city", "");
    setOptions([]);
  };

  return (
    <div data-style="form">
      <div data-style="list-input">
        <div
          data-style="bg-hide"
          style={{ display: options.length ? "block" : "none" }}
          onClick={reset}
        />
        <Icon icon="carbon:location" />
        <input
          {...register("address", {
            onChange: (e) => adressHandler(e),
          })}
          type="text"
          placeholder="Votre adresse"
        />
        {options?.length ? (
          <ul>
            {options.map((opt) => (
              <li
                onClick={() => onClickHandler(opt.properties)}
                key={`opt-${opt.properties.id}`}
              >
                {opt.properties.label}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
      </div>

      <div data-style="flex" style={{ display: "none" }}>
        <input
          style={{ pointerEvents: "none" }}
          type="text"
          placeholder="Ville"
          {...register("city")}
        />
        <input
          style={{ pointerEvents: "none" }}
          type="text"
          placeholder="Code postal"
          {...register("postCode")}
        />
      </div>

      <input
        style={{ display: "none" }}
        type="text"
        placeholder="Pays"
        {...register("country")}
      />

      <div data-style="1-1/4">
        <input
          type="number"
          placeholder="Nombre de pièces"
          {...register("room")}
        />
        <input type="number" placeholder="M²" {...register("size")} />
      </div>

      <input
        type="number"
        placeholder="Année de construction"
        {...register("yearBuilt")}
      />
      <input
        type="number"
        placeholder="Capital mobilier"
        {...register("capitalFurniture")}
      />

      <select {...register("floor")} defaultValue={"top"}>
        <option value="top">Dernière étage</option>
        <option value="middle">Étage intermédiaire</option>
        <option value="ground">Rez de chaussée</option>
      </select>

      <div data-style="group-checkbox">
        <label>Déjà assuré ?</label>
        <div
          onClick={() => {
            document.getElementById("alreadyInsured").click();
          }}
          className={style.switch}
        >
          <input
            id="alreadyInsured"
            {...register("alreadyInsured")}
            type="checkbox"
          />
          <span></span>
        </div>
      </div>

      <div data-style="group-checkbox">
        <label>Déjà habité ?</label>
        <div
          onClick={() => {
            document.getElementById("alreadyInhabited").click();
          }}
          className={style.switch}
        >
          <input
            id="alreadyInhabited"
            {...register("alreadyInhabited")}
            type="checkbox"
          />
          <span></span>
        </div>
      </div>
    </div>
  );
};

const MobileEquipments = ({ register, watch }) => {
  return (
    <div style={{ marginTop: "15px" }}>
      <div data-style="equipment" aria-label="standard">
        <div data-style="2/2">
          <div data-style="check-container">
            <input {...register("hasSwimmingPool")} type="checkbox" />
            <label>Piscine</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTennisCourt")} type="checkbox" />
            <label>Court de tennis</label>
          </div>
        </div>

        <div data-style="check-container">
          <input {...register("hasVeranda")} type="checkbox" />
          <label>Véranda</label>
        </div>
      </div>

      <div
        data-style="equipment"
        aria-label="for-house"
        style={{
          display: watch("propertyType") === "Apartment" ? "none" : "flex",
          marginTop: "15px",
        }}
      >
        <div data-style="2/2">
          <div data-style="check-container">
            <input {...register("hasFireAlarm")} type="checkbox" />
            <label>Alarme incendie</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasTheftAlarm")} type="checkbox" />
            <label>Alarme antivol</label>
          </div>
        </div>

        <div data-style="2/2">
          <div data-style="check-container">
            <input {...register("hasSolarPanels")} type="checkbox" />
            <label>Panneaux solaires</label>
          </div>

          <div data-style="check-container">
            <input {...register("hasChimney")} type="checkbox" />
            <label>Cheminée</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceMRH;
