import { Link } from "react-router-dom";
import logo from "../../../assets/images/svg/logo.svg";
import style from "./style.module.scss";

export default function Index() {
  return (
    <div className={style.index}>
      <img src={logo} alt="logo" />
      <Link to="/login">Mon compte</Link>
    </div>
  );
}
