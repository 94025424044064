import style from "./style.module.scss";
import emailIcon from "../../../assets/images/svg/email.svg";
import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { notifyWarn } from "../../../components/Toast";
import { useDispatch } from "react-redux";
import { homeAction } from "../../../redux/actions";

const validationSchema = z.object({
  nom: z.string().optional(),
  prenom: z.string().optional(),
  secteur: z.string().min(1, `veillez indiquez votre secteur d'activité !`),
  email: z
    .string()
    .min(1, `le champ email est obligatoire !`)
    .email("veillez entrer un vrai email !"),
  entrprise: z.string().min(1, `veuillez indiquez le champ entreprise !`),
  message: z
    .string()
    .min(10, "veuillez faire un message qui dépasse en moin 10 caracteres !"),
});
export default function Index() {
  const dispatch = useDispatch();
  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(validationSchema),
  });
  const [drop, setDrop] = useState(false);
  const setSecteur = (value) => {
    setValue("secteur", value);
    setDrop(false);
  };
  const send = (data) => {
    dispatch(homeAction.sendEmailRequest(data));
    reset();
  };

  useEffect(() => {
    if (Object.keys(errors).length)
      notifyWarn(errors[Object.keys(errors)[0]].message);
  }, [errors]);
  return (
    <div className={style.index} id="partner-section">
      <div aria-label="first-section">
        <h1>Partenaires</h1>

        <div aria-label="grid-2">
          <div className={style.message_action}>
            <form onSubmit={handleSubmit(send)}>
              <h3>Envoie-nous un message</h3>
              <div className={style.message_input}>
                <label>Nom :</label>
                <input {...register("nom")} type="text" placeholder="Nom" />
              </div>

              <div className={style.message_input}>
                <label>Prénom :</label>
                <input
                  {...register("prenom")}
                  type="text"
                  placeholder="Prénom"
                />
              </div>

              <div className={style.message_input}>
                <label>Entrprise :</label>
                <input
                  {...register("entrprise")}
                  type="text"
                  placeholder="Nom entrprise "
                />
              </div>

              <div className={style.message_input}>
                <label>Adresse E-mail :</label>
                <input
                  {...register("email")}
                  type="text"
                  placeholder="exemple@gmail.com"
                />
              </div>

              <div className={style.message_input} aria-label="with-dropdown">
                <label>Votre secteur d’activité :</label>
                <div
                  onClick={() => setDrop(true)}
                  style={{ cursor: "pointer" }}
                >
                  <input
                    {...register("secteur")}
                    type="text"
                    placeholder="Votre secteur d’activité"
                  />
                </div>
                {drop && (
                  <>
                    <div aria-label="mask" onClick={() => setDrop(false)} />
                    <ul>
                      <li onClick={() => setSecteur("Assurances")}>
                        Assurances
                      </li>
                      <li
                        onClick={() => setSecteur("Énergie (gaz, électricité)")}
                      >
                        Énergie (gaz, électricité)
                      </li>
                      <li
                        onClick={() => setSecteur("Télécom (internet, mobile)")}
                      >
                        Télécom (internet, mobile)
                      </li>
                    </ul>
                  </>
                )}
              </div>

              <div className={style.message_input}>
                <label>Message :</label>
                <textarea
                  {...register("message")}
                  rows="1"
                  placeholder="Tapez votre message ici"
                ></textarea>
              </div>

              <div aria-label="submit-container">
                <button>Envoyer le message</button>
              </div>
            </form>
          </div>

          <p>
            Vous êtes un professionnel du secteur de l'énergie, des télécoms
            (internet & mobile), des assurances et vous souhaitez devenir un
            partenaire de Sweesher ?
          </p>
        </div>
      </div>

      <div aria-label="seconde-section">
        <div aria-label="grid-2">
          <i />
          <div>
            <h3>
              <img src={emailIcon} alt="icon" />
              Comment pouvons nous aidez ?
            </h3>
            <p>
              Contactez-nous à l'adresse partner@sweesher.com ou en utilisant
              notre formulaire.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
