import style from "./style.module.scss";
import { useState } from "react";

import InsuranceMRH from "./InsuranceMRH";
import { useSelector } from "react-redux";
import InsurancePNO from "./InsurancePNO";
import { useMediaQuery } from "react-responsive";
import UserInfo from "./UserInfo"
import { Tab } from "@headlessui/react";

// img
import car from "../../assets/images/svg/profile/car insurance 1.svg";
import house from "../../assets/images/svg/profile/house-logo 1.svg";
import pet from "../../assets/images/svg/profile/pet insurance 1.svg";
import { useTranslation } from "react-i18next";
import AnimalInsurance from "./animalInsurance";

const Insurance = ({setActive}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.users);
  const [selected, setSelected] = useState(0);
  const [submit, setSubmit] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 790px)" });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    {
      insurancePanel: (
        <>
          <div data-style="select-buttons">
            <div
              data-style="button-container"
              data-active={selected === 0 ? "true" : ""}
            >
              <button onClick={() => setSelectedHandler(0)}>
                <span>{t("Principal residence")}</span>
              </button>
            </div>

            <i />

            <div
              data-style="button-container"
              data-active={selected !== 0 ? "true" : ""}
            >
              <button onClick={() => setSelectedHandler(1)}>
                <span>{t("Secondary residence")}</span>
              </button>
            </div>
          </div>

          <InsuranceMRH
            index={0}
            tab={selected}
            mrh={user?.offerProfiles?.mrh}
            user={user?.profile}
            isMobile={isMobile}
            submit={submit}
            setSubmit={setSubmit}
            setActive={setActive}
          />

          <InsurancePNO
            isMobile={isMobile}
            pno={user?.offerProfiles?.pno}
            index={1}
            tab={selected}
          />
        </>
      ),
      navTab : (
        <>
          <div >
            <img src={house} alt="house" />
          </div>
          <p>{t("Home Insurance")}</p>
        </>
      )
    },
    {
      insurancePanel: (
        <></>
      ),
      navTab : (
        <>
          <div data-disable="true">
            <img src={car} alt="car" />
          </div>
          <p>{t("Car insurance")}</p>
        </>
      )
    },
    {
      insurancePanel: (<AnimalInsurance/>),
      navTab : (
        <>
          <div>
            <img src={pet} alt="pet" />
          </div>
          <p>{t("Pet Plan")}</p>
        </>
      )
    },
    
  ];

  const setSelectedHandler = (value) => {
    setSelected(value);
  };
  return (
    <div className={style.insurance}>
       <UserInfo submit={submit} setSubmit={setSubmit} />
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <Tab.List >
          <div  className={style.insuranceTablist}>
            <div data-style="nav">
              <ul>
                {tabs.map((tab, index) => (
                  <Tab
                    as="li"
                    key={index}
                    aria-label={selectedIndex === index ? "selected" : ((index===2 || !index) ? 'activated' : '')}
                    disabled={index===1}
                  >
                    {tab.navTab}
                  </Tab>
                ))}
              </ul>
            </div>

          </div>
          </Tab.List>
          <Tab.Panels>
              {tabs.map((tab, idx) => (
                <Tab.Panel as="div" className={style.content} key={idx}>{tab.insurancePanel}</Tab.Panel>
              ))}
          </Tab.Panels>
        </Tab.Group>
    </div>
  );
};

export default Insurance;
