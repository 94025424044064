import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { homeAction } from "../../redux/actions";
import moment from "moment";
import { BASE_URL } from "../../constant";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import style from "../../assets/scss/layouts/slider.module.scss";
import { Icon } from "@iconify/react";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "antd";
import { background } from "../../constant/globals";

function HomePage() {
  const isMobile = useMediaQuery({
    query: "(min-width: 320px) and (max-width: 992px)",
  });
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const { newsFeedsList, loading } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(homeAction.getNewsFeeds());
  }, [dispatch]);

  const goNext = () => {
    const slider = document.getElementById("news-silder");
    const ticket = document.querySelector(".movingTicket").clientHeight;
    const maxIndex = Math.floor(newsFeedsList.length / 3);
    const gap = 3;
    const fixed_height = ticket * 3 + 30;
    const currentIndex = index > 0 ? index + 1 : 1;
    slider.style.transform = `translateY(-${
      fixed_height * currentIndex - gap
    }px)`;
    setIndex((prev) => {
      if (prev < maxIndex) return prev + 1;
      else return prev;
    });
  };

  const goPrev = () => {
    const slider = document.getElementById("news-silder");
    const ticket = document.querySelector(".movingTicket").clientHeight;
    const gap = 3;
    const fixed_height = ticket * 3 + 30;
    const currentIndex = index - 1;
    slider.style.transform =
      index === 1
        ? `translateY(0px)`
        : `translateY(-${fixed_height * currentIndex - gap}px)`;
    setIndex((prev) => {
      if (prev >= 1) return prev - 1;
      else return prev;
    });
  };

  const goTo = (i) => {
    const slider = document.getElementById("news-silder");
    const ticket = document.querySelector(".movingTicket").clientHeight;
    const gap = 3;
    const fixed_height = ticket * 3 + 30;
    slider.style.transform =
      i === 0 ? `translateY(0px)` : `translateY(-${fixed_height * i - gap}px)`;
    setIndex(i);
  };

  if (isMobile)
    return (
      <div className={style.mobileSliderContainer}>
        <h3>{t("Latest news")}</h3>
        <Loader loading={loading}>
          <Carousel
            dots={true}
            infinite={true}
            autoplaySpeed={5000}
            speed={1000}
            slidesToShow={3}
            slidesToScroll={3}
            dotPosition="bottom"
            adaptiveHeight={true}
            autoplay={true}
            appendDots={(dots) => (
              <div>
                <ul className="custom-dot-list-style"> {dots} </ul>
              </div>
            )}
            className=""
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {newsFeedsList.length > 0 &&
              newsFeedsList.map((newsfeed, index) => {
                const displayPeriod = newsfeed["startDate"]
                  ? moment
                      .utc(new Date(newsfeed["startDate"].iso))
                      .locale("fr")
                      .format("ll") +
                    " - " +
                    moment
                      .utc(new Date(newsfeed["endDate"].iso))
                      .locale("fr")
                      .format("ll")
                  : "";
                return (
                  <div key={`${index}-new-${newsfeed.title}`}>
                    <div
                      className={style.mobile_slider}
                      style={{ backgroundColor: background[index] }}
                      key={`${newsfeed.objectId}-${index}-news`}
                      onClick={() => window.open(newsfeed.url, "blank")}
                    >
                      <p data-style="ticketTitle">{newsfeed.title}</p>
                      {displayPeriod && (
                        <span data-style="ticketDate">{displayPeriod}</span>
                      )}
                      <div data-style="fedex-frame">
                        {newsfeed.icon && (
                          <img
                            className="img"
                            src={`${BASE_URL}${newsfeed.icon}`.replace(
                              "/api/",
                              "/"
                            )}
                            alt="loading.."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </Loader>
      </div>
    );

  return (
    <div className="movingTickets">
      <h3 style={{ fontWeight: "bolder" }}>{t("Latest news")}</h3>
      <Loader loading={loading}>
        <div className={style.slider_container}>
          <div data-style="dot-navigation">
            {newsFeedsList.length > 0 &&
              newsFeedsList.map((doc, i) => {
                const max_span =
                  newsFeedsList.length === 3
                    ? 0
                    : Math.floor(newsFeedsList.length / 3);
                if (i <= max_span)
                  return (
                    <span
                      key={`dot-navigation-${i}-${doc.objectId}`}
                      data-select={i === index ? "true" : ""}
                      onClick={() => goTo(i)}
                    ></span>
                  );
                else return null;
              })}
          </div>
          {index >= 1 ? (
            <div onClick={goPrev} data-style="prev">
              <Icon icon="ph:caret-circle-up-fill" />
            </div>
          ) : (
            <></>
          )}
          <div className={style.index}>
            <div id="news-silder" data-style="slider">
              {newsFeedsList.length > 0 &&
                newsFeedsList.map((newsfeed, index) => {
                  const displayPeriod = newsfeed["startDate"]
                    ? moment
                        .utc(new Date(newsfeed["startDate"].iso))
                        .locale("fr")
                        .format("ll") +
                      " - " +
                      moment
                        .utc(new Date(newsfeed["endDate"].iso))
                        .locale("fr")
                        .format("ll")
                    : "";
                  return (
                    <div
                      className="movingTicket"
                      style={{ backgroundColor: background[index] }}
                      key={newsfeed.objectId}
                      onClick={() => window.open(newsfeed.url, "blank")}
                    >
                      <p className="ticketTitle">{newsfeed.title}</p>
                      {displayPeriod && (
                        <span className="ticketDate">{displayPeriod}</span>
                      )}
                      <div className="fedex-frame">
                        {newsfeed.icon && (
                          <img
                            className="img"
                            src={`${BASE_URL}${newsfeed.icon}`.replace(
                              "/api/",
                              "/"
                            )}
                            alt="loading.."
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {newsFeedsList.length !== 3 &&
          index < Math.floor(newsFeedsList.length / 3) ? (
            <div onClick={goNext} data-style="next">
              <Icon icon="material-symbols:expand-circle-down-rounded" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Loader>
    </div>
  );
}

export default HomePage;
